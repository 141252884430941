import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablePagerComponent } from './pager.component';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './customers.component';
import { CustomersRoutingModule } from './customers.routing.module';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { SkuStatusModalComponent } from 'src/shared-components/sku-status-modal/sku-status-modal.component';
import { PaidServiceModalComponent } from 'src/shared-components/paid-service-modal/paid-service-modal.component';
import { RenewalSettingsModalComponent } from 'src/shared-components/renewal-settings-modal/renewal-settings-modal.component';
import { GwsGcpPopupModule } from 'src/shared-components/gws-gcp-popup/gws-gcp-popup.module';
import { LicenseUpdateModalModule } from 'src/shared-components/license-update-modal/license-update-modal.module';

@NgModule({
  declarations: [
    CustomersComponent,
    DataTablePagerComponent,
    SkuStatusModalComponent,
    PaidServiceModalComponent,
    RenewalSettingsModalComponent,
  ],
  imports: [
    CustomersRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxPaginationModule,
    NgbDropdownModule,
    NgbModule,
    NgSelectModule,
    GwsGcpPopupModule,
    LicenseUpdateModalModule,
  ],
  exports: [CustomersComponent],
})
export class CustomersModule {}
