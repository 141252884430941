import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { DealModel } from 'src/models/mongo/deal.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'lib-add-deal',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './add-deal-gcp.component.html',
  styleUrls: ['./add-deal-gcp.component.scss'],
})
export class AddDealGCPComponent implements OnInit, AfterViewInit {
  title: string;
  model: DealModel = new DealModel();
  onlyView: boolean = false;
  currentUser: UserModel;
  prices = [];
  addDeal: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private http: HttpClient
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
  }
  async ngAfterViewInit(): Promise<void> {}

  async ngOnInit(): Promise<void> {
    var params = history.state;
    var urlParams = await this.route.queryParams.pipe(take(1)).toPromise();
    this.addDeal =
      !params ||
      (Utilities.isNullOrEmpty(params['dealId']) &&
        Utilities.isNullOrEmpty(urlParams['Id']));
    if (!Utilities.isNullOrEmpty(params['id'])) {
      this.model.customer_domain = params['domain'];
      this.model.customer_organisation = params['organisation'];
      this.model.domain_rfid = params['domain_rfid'];
      this.model.customer_api_id = params['customer_api_id'];
    }
    if (this.addDeal) {
      this.model.created_by_rfid = this.currentUser._id;
      this.model.user_deal = {
        created_user_email: this.currentUser.email,
        manager_user_id: this.currentUser.manager_id?.toString(),
        manager_user_email: this.currentUser.manager_email,
      };
    }
    if (params && params['dealId']) {
      this.title = 'Deal';
      this.model.id = params['dealId'];
      this.onlyView = false;
    } else {
      this.title = 'Create a Google Cloud Deal';
    }

    //EMAIL LINK
    if (!Utilities.isNullOrEmpty(urlParams['Id'])) {
      await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.GET_DEAL_BY_ID + urlParams['Id'])
        .toPromise()
        .then((resp: any) => {
          if (resp) {
            this.model = resp;
          }
        });
    }
  }

  cancel() {
    this.router.navigate(['/deals']);
  }
}
