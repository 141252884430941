import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/models/mongo/user.model';
import { Unicode, Endpoint, PlanValues } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'lib-order-renewal-list',
  templateUrl: './order-renewal-list.component.html',
  styleUrls: ['./order-renewal-list.component.scss'],
})
export class OrderRenewalListComponent implements OnInit {
  static renewalOrders = [];
  static currentUser: UserModel;
  static stopNormalProcess: boolean = true;
  static _http: HttpClient;
  static pageToken;
  static currentUserCustomers = [];

  constructor(
    private http: HttpClient,
  ) {
    OrderRenewalListComponent._http = http;
    OrderRenewalListComponent.currentUser = JSON.parse(
      localStorage.getLoggedinUser()
    );
  }

  async ngOnInit(): Promise<void> {
    OrderRenewalListComponent.renewalOrders = [];
    await OrderRenewalListComponent.getRenewalOrdersAPIStatic(
      OrderRenewalListComponent._http
    );
  }

  static async getRenewalOrdersAPIStatic(http, toExport: boolean = false) {
    var customerSearch = (<HTMLInputElement>(
      document.getElementById('txt-order-global-search')
    ))?.value;
    var fromDate: any = (<HTMLInputElement>(
      document.getElementById('order-date-from')
    ))?.value;
    var toDate: any = (<HTMLInputElement>(
      document.getElementById('order-date-to')
    ))?.value;
    if (Utilities.isNullOrEmpty(fromDate) || Utilities.isNullOrEmpty(toDate)) {
      var current = new Date();
      var beforeOneMonth = current.setMonth(current.getMonth() - 1);
      fromDate = new Date(beforeOneMonth).toISOString().slice(0, 10);
      toDate = new Date().toISOString().slice(0, 10);
    }
    if (!OrderRenewalListComponent.stopNormalProcess) return;
    var prs = new HttpParams().set(Unicode.WITHOUT_LOADER, '1');
    if (!Utilities.isNullOrEmpty(customerSearch))
      prs = prs.set('customerNamePrefix', customerSearch);
    if (!Utilities.isNullOrEmpty(OrderRenewalListComponent.pageToken))
      prs = prs.set('pageToken', OrderRenewalListComponent.pageToken);
    var fromTime = new Date(fromDate).getTime();
    var toTime = new Date(toDate).getTime();
    prs = prs
      .set(Unicode.FROM_DATE, fromTime)
      .set(Unicode.TO_DATE, toTime)
      .set(
        Unicode.VIEW_ALL,
        OrderRenewalListComponent.currentUser.canViewAll ? '1' : '0'
      );
    if (toExport) {
      await OrderRenewalListComponent._http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.EXPORT_RENEWAL_ORDERS_LIST, {
          params: prs,
        })
        .toPromise()
        .then(async (result: any) => {
          if (result && result.spreadsheetUrl) {
            window.open(result.spreadsheetUrl);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await OrderRenewalListComponent._http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.RENEWAL_ORDERS_LIST, {
          params: prs,
        })
        .toPromise()
        .then(async (result: any) => {
          if (result && result.success && result.subscriptions) {
            OrderRenewalListComponent.renewalOrders.push(
              ...result.subscriptions
            );
            OrderRenewalListComponent.renewalOrders.sort((a, b) =>
              a.plan.commitmentInterval?.endTime.localeCompare(
                b.plan.commitmentInterval?.endTime
              )
            );
            if (result.nextPageToken) {
              OrderRenewalListComponent.pageToken = result.nextPageToken;
              this.getRenewalOrdersAPIStatic(http);
            }
          }
        });
    }
  }

  async exportRenewalList() {
    alert(
      'You will receive an email with the sheet link once completed'
    );
    OrderRenewalListComponent.pageToken = null;
    OrderRenewalListComponent.getRenewalOrdersAPIStatic(this.http, true);
  }

  get GetRenewal() {
    return OrderRenewalListComponent.renewalOrders;
  }
}
