export class Endpoint {
  public static CLIENT_ID =
    '404204591697-r7v5qd7c23vtmhn7k1bb9e5sis7de50u.apps.googleusercontent.com';
  public static ORGANISATION_NAME = 'isolutions-sa.com';
  public static GLOBAL_URL = 'https://resellerclient.ismena.com/api/';
  public static APP_URL = 'https://resellerclient.ismena.com/';
  // public static GLOBAL_URL = 'http://127.0.0.1:8000/api/';
  // public static APP_URL = 'http://localhost:4200/';
  public static OPPORTUNITY_DETAILS_URL =
    Endpoint.APP_URL + 'opportunities/opportunity-details?Id=';
  public static CUSTOMER_REQUIREMENT_DETAILS_URL =
    Endpoint.APP_URL + 'customer-requirements/details?Id=';
  public static ENGINEER_RECOMMENDATIONS_DETAILS_URL =
    Endpoint.APP_URL + 'engineer-recommendations/details?Id=';
  public static DEAL_DETAILS_URL = Endpoint.APP_URL + 'deals/add-deal-gcp?Id=';
  public static SOW_DETAILS_URL = Endpoint.APP_URL + 'sow/sow-details?Id=';
  public static POC_DETAILS_URL = Endpoint.APP_URL + 'poc/poc-details?Id=';
  public static PROPOSAL_DETAILS_URL =
    Endpoint.APP_URL + 'proposal/proposal-details?Id=';
  public static PROJECT_LIST_URL = Endpoint.APP_URL + 'gcp/project-list';
  public static BILLING_LIST_URL =
    Endpoint.APP_URL + 'gcp/billing-account/list';
  public static ORDER_LIST_URL =
    Endpoint.APP_URL + 'orders/order-view?fromEmail=1&Id=';

  public static API_URL = Endpoint.GLOBAL_URL;

  /** BACKEND/PHP **/
  // Settings end point
  // Currency settings
  public static ADD_CURRENCY = 'add-currency';
  public static EDIT_CURRENCY = 'edit-currency';
  public static GET_CURRENCIES = 'get-currencies';
  public static GET_CURRENCY = 'get-currency';
  public static DELETE_CURRENCY = 'delete-currency';

  // Exchange settings
  public static ADD_EXCHANGE = 'add-exchange-rate';
  public static EDIT_EXCHANGE = 'edit-exchange-rate';
  public static GET_EXCHANGES = 'get-exchange-rates';
  public static GET_EXCHANGE = 'get-exchange-rate';
  public static DELETE_EXCHANGE = 'delete-exchange-rate';

  // Permission settings
  public static ADD_PERMITION = 'add-permission';
  public static EDIT_PERMITION = 'edit-permission';
  public static GET_PERMISSIONS = 'get-permissions';
  public static GET_PERMISSION = 'get-permission';
  public static DELETE_PERMISSION = 'delete-permission';

  //Api Access Token Settings
  public static ADD_API_ACCESS_TOKEN = 'generate-access-token-api';
  public static GET_API_ACCESS_TOKENS = 'get-api-tokens';
  public static DELETE_API_ACCESS_TOKEN = 'delete-access-token-api';

  //Integraton Settings
  public static GET_INTEGRATIONS = 'get-integrations';
  public static UPDATE_INTEGRATION = 'update-integration';
  public static GET_INTEGRATION_BY_ID = 'get-integration';
  public static GET_INTEGRATION_BY_CODE = 'get-integration-by-code';

  // Group settings
  public static ADD_GROUP = 'add-group';
  public static EDIT_GROUP = 'edit-group';
  public static GET_GROUP = 'get-group';
  public static DELETE_GROUP = 'delete-group';

  // SKUs settings
  public static ADD_SKU = 'create-sku';
  public static EDIT_SKU = 'update-sku';
  public static GET_SKUS = 'get-skus';
  public static GET_SKU = 'get-sku';
  public static DELETE_SKU = 'delete-sku';

  public static ORDERS_OF_CUSTOMER = 'orders-of-customer';
  public static DEALS_OF_CUSTOMER = 'customer-deals';

  //NEW
  public static LOGIN = 'login';
  public static GET_CUSTOMER_BY_ID = 'customer';
  public static UP_CUST = 'update-customer';
  public static ADD_CUST = 'add-customer';
  public static DELETE_CUSTOMER = 'delete-customer';
  public static CUSTOMER_LIST = 'all-customers';
  public static GET_DOMAIN_BY_ID = 'domain';
  public static GLOBAL_DATA_LIST = 'global-data';
  public static PLAN_LIST = 'plans';
  public static SALES_USERS_LIST = 'get-sales';
  public static GET_USERS_BY_GROUP = 'users-by-group';
  public static GOOGLE_PRODUCTS_LIST = 'google-products';
  public static ADD_ORDER = 'add-order';
  public static UPDATE_ORDER = 'update-order';
  public static REJECT_ORDER = 'reject-order';
  public static GET_ORDER_BY_ID = 'order/';
  public static LOGIN_USERS_INFO = 'get-login-info';
  public static GET_CUSTOMER_BY_ID_API = 'get-customer-api';
  public static GET_SUBSCRIPTIONS_BY_CUSTOMER_ID_API =
    'get-customer-subscriptions-api';
  public static GET_LIST_OF_SUBSCRIPTIONS = 'subscriptions-list';
  public static CHECK_CUSTOMER_EXISTENCE_API_DB = 'check-customer-existence';

  public static CHECK_ORDER = 'checkOrder';
  public static VERIFY_ORDER = 'verifyOrder';

  public static SEARCH_FOR_CUSTOMERS_LIST = 'search-for-customers';
  public static SKU_PRICE_LIST = 'sku-price-list';
  public static ORDER_LIST = 'all-orders';
  public static DEAL_LIST = 'all-deals';
  public static SEND_DEAL_FOR_APPROVAL = 'send-deal-for-approval';
  public static GET_DEAL_BY_ID = 'deal/';
  public static ADD_DEAL = 'add-deal';
  public static UPDATE_DEAL = 'update-deal';
  public static ASSIGN_USER_CUSTOMER = 'assign-users';
  public static TEST_H_C = 'test-hc';

  public static customers_by_user = 'user-customers';
  public static DASHBOARD_CUSTOMERS_FILE_NAME = 'dashboard-customers';
  public static DASHBOARD_ORDERS_STATUS_FILE_NAME = 'dashboard-orders-status';
  public static DASHBOARD_ORDERS_COUNTRIES_FILE_NAME =
    'dashboard-customers-country';
  public static DASHBOARD_ORDERS_DATE_FILE_NAME = 'dashboard-orders-date';
  public static IMPORT_CUSTOMERS_FILE_NAME = 'import-customers';
  public static READ_SALE_FILE_NAME = 'all-users';
  public static ACTIVITY_LOGS_FILE_NAME = 'activity_logs';
  public static ADD_SALE_FILE_NAME = 'add-user';
  public static UPDATE_SALE_FILE_NAME = 'update-user';
  public static DELETE_SALE_FILE_NAME = 'delete-user';
  public static DASHBOARD_SALES_FILE_NAME = 'dashboard-users';
  public static GLOBAL_DATA_FILE_NAME = 'global-data';
  public static DASHBOARD_ORDERS_FILE_NAME = 'dashboard-orders';

  public static READ_ORDERS_FILE_NAME = 'order/OrderReadController';
  public static UPDATE_EXPORTED_ORDERS_FILE_NAME =
    'order/UpdateExportedOrderController';
  public static UPDATE_CHECKED_ORDERS_FILE_NAME =
    'order/UpdateCheckedOrderController';
  public static EXECUTE_ORDERS_FILE_NAME = 'order/ExecuteOrderController';
  public static ALL_GROUPS_FILE_NAME = 'all-group';
  public static PERMS_FILE_NAME = 'permissions';
  public static GROUPS_PERMISSIONS_FILE_NAME = 'core/GroupPermissions';
  public static SALES_FILE_NAME = 'all-users';

  public static REPORTS_ORDERS_FILE_NAME = 'reports-order';
  public static REPORTS_ORDERS_EXPORT = 'reports-order-export';

  public static USR_GRP_FILE_NAME = 'settings/UserGroupController';
  public static RENEWAL_DATA = 'renewal-data';
  public static FLEX_DATA = 'flex-data';
  public static UPDATE_FLEX_DATA = 'customers-update';
  public static RENEWAL_ORDERS_LIST = 'renewal-orders-list';
  public static EXPORT_RENEWAL_ORDERS_LIST = 'export-renewal-orders-list';
  public static FLEX_CUSTOMERS_LIST = 'flex-customers-list';
  public static EXPORT_FLEX_CUSTOMERS_LIST = 'export-flex-customers-list';
  public static EXPORT_ALL_CUSTOMERS = 'export-all-customers';
  public static CREATE_CUSTOMER_BILLING_ACCOUNT =
    'create-customer-billing-account';
  public static TEST_CUSTOMER = 'test-customer';
  public static CREATE_Entitlement_BILLING_ACCOUNT =
    'create-entitlement-billing-account';
  public static CREATE_FULL_GCP_SERVICE = 'create-full-gcp-service';
  public static CREATE_PROJECT = 'create-project';
  public static LIST_OF_BILLING_ACCOUNT = 'billing-account-list';
  public static LIST_OF_BILLING_ACCOUNT_DB = 'v2/billing-account-list';
  public static LIST_OF_BUDGETS_FOR_BILLING = 'billing-budgets';
  public static BILLING_INFO = 'billing-info';
  public static LINK_PROJECT_USING_RFID = 'link-project-rfid';
  public static LINK_PROJECT = 'link-project';
  public static LIST_OF_PROJECTS = 'project-list';
  public static LIST_PROJECTS_LINKED_TO_BILLING = 'billing-projects';
  public static UNLINK_PROJECT = 'unlink-project';
  public static UPDTAE_PROJECT = 'edit-project';
  public static DELETE_PROJECT = 'delete-project';
  public static GET_OPERATION_BY_NAME = 'get-operation';
  public static GET_OFFERS = 'get-offers';
  public static GET_FOLDERS = 'get-folders';
  public static ADD_OPPORTUNITY = 'add-opportunity';
  public static GET_OPPORTUNITY_BY_ID = 'get-opportunity';
  public static EDIT_OPPORTUNITY_MSP = 'edit-opportunity-msp';
  public static ADD_CUSTOMER_REQUIREMENTS = 'add-customer-requirements';
  public static GET_CUSTOMER_REQUIREMENT_BY_ID = 'customer-requirements/';
  public static UPDATE_CUSTOMER_REQUIREMENTS = 'update-customer-requirements/';
  public static ADD_ENGINEER_RECOMMENDATIONS = 'add-engineer-recommendations';
  public static GET_ENGINEER_RECOMMENDATION_BY_ID = 'engineer-recommendations/';
  public static UPDATE_ENGINEER_RECOMMENDATIONS =
    'update-engineer-recommendations/';
  public static TEST_UPLOAD = 'test-upload';
  public static CREATE_CUSTOMER_REQUIREMENTS_GOOGLE_DOC =
    'create-customer-requirements-doc';
  public static CREATE_ENGINEER_RECOMMENDATION_GOOGLE_DOC =
    'create-engineer-recommendations-doc';
  public static SEND_CUSTOMER_REQUIREMENTS_FOR_APPROVAl =
    'send-customer-requirements-for-approval/';
  public static SEND_ENGINEER_RECOMMENDATIONS_FOR_APPROVAL =
    'send-engineer-recommendations-for-approval/';
  public static SEND_CUST_REQ_TO_CUSTOMER =
    'send-customer-requirements-to-customer/';
  public static SEND_ENG_RECO_TO_CUSTOMER =
    'send-engineer-recommendations-to-customer/';
  public static PICK_USERS = 'pick-opportunity-users';
  public static CREATE_SOW_GOOGLE_DOC = 'create-sow-doc';
  public static ADD_SOW = 'add-sow';
  public static UPDATE_SOW = 'update-sow/';
  public static EDIT_DAF_PSF_INFO = 'edit-daf-psf-info/';
  public static SEND_SOW_TO_CUSTOMER = 'send-sow-to-customer';
  public static SEND_SOW_FOR_APPROVAL = 'send-sow-for-approval';
  public static SEND_DAF_REQUEST = 'add-daf-request/';
  public static DAF_REQUEST_DECISION = 'take-daf-decision/';
  public static COMPLETE_DAF_REQUEST = 'complete-daf/';
  public static CREATE_POC_GOOGLE_DOC = 'create-poc-doc';
  public static ADD_POC = 'add-poc';
  public static UPDATE_POC = 'update-poc/';
  public static SEND_POC_FOR_APPROVAL = 'send-poc-for-approval';
  public static SEND_POC_TO_CUSTOMER = 'send-poc-to-customer/';
  public static CREATE_POC_PROJECT = 'create-poc-project';
  public static CREATE_ORDER_PROJECT = 'create-order-project';
  public static CREATE_PROPOSAL_GOOGLE_DOC = 'create-proposal-doc';
  public static ADD_PROPOSAL = 'add-proposal';
  public static UPDATE_PROPOSAL = 'update-proposal/';
  public static SEND_PROPOSAL_FOR_APPROVAL = 'send-proposal-for-approval';
  public static SEND_PROPOSAL_TO_CUSTOMER = 'send-proposal-to-customer/';
  public static SELECT_CONSOLE_PROJECT = 'select-project';
  public static CREATE_BUDGET = 'create-budget';
  public static GET_ALL_OPPORTUNITIES = 'all-opportunities';
  public static GET_ALL_SOWS = 'all-sows';
  public static GET_SOW_BY_ID = 'sow/';
  public static GET_ALL_POCS = 'all-pocs';
  public static GET_POC_BY_ID = 'poc/';
  public static GET_ALL_PROPOSALS = 'all-proposals';
  public static GET_PROPOSAL_BY_ID = 'proposal/';
  public static DELETE_DEAL = 'delete-deal/';
  public static DELETE_CUSTOMER_REQUIREMENTS = 'delete-customer-requirements/';
  public static DELETE_ENGINEER_RECOMMENDATIONS =
    'delete-engineer-recommendations/';
  public static DELETE_SOW = 'delete-sow/';
  public static DELETE_POC = 'delete-poc/';
  public static DELETE_PROPOSAL = 'delete-proposal/';
  public static DELETE_OPPORTUNITY = 'delete-opportunity/';
  public static DELETE_ORDER = 'delete-order/';
  public static GET_ALL_CUSTOMER_REQUIREMENTS = 'all-customer-requirements';
  public static GET_ALL_ENGINEER_RECOMMENDATIONS =
    'all-engineer-recommendations';
  public static GET_ALL_NOTIFICATIONS = 'all-notifications';
  public static MARK_NOTIFICATIONS_AS_READ = 'mark-notifications-as-read';
  public static MARK_ALL_NOTIFICATIONS_AS_READ =
    'mark-all-notifications-as-read';
  public static COUNT_UNREAD_NOTIFICATIONS = 'count-unread-notifications';
  public static SKIP_OPP_STEP = 'skip-opportunity-step';
  public static CHECK_ENG_ENGAGEMENT = 'check_engineer_engagement_not_required';
  public static GET_CUSTOMER_ENTITLEMENTS = 'customer-entitlements';
  public static ACTIVATE_SUSPEND_ENTITLEMENT = 'activate-entitlement';
  public static CANCEL_ENTITLEMENT = 'cancel-entitlement';
  public static SEND_APPROVAL_REQUEST = 'send-approval-request';
  public static BILLING_CONSUMPTION_DATA = 'billing-consumption-data';
  public static SERVICES_DELIVERED_CHECK = 'services-delivered-check/';


  public static GET_ENGINEERS = 'get-engineers';
  public static RENAME_DOCUMENT = 'rename-document';

  public static KJUR_P_HEDAER = { alg: 'RS256', typ: 'JWT' };

  public static AES_KEY = 'S123Rz343I';
}
export class Unicode {
  public static ITEMS_PER_PAGE = 20;
  public static COST_MARGIN = 0.8;
  public static CURRENT_USR = 'Logged in user';
  public static EMAIL = 'email';
  public static ID = 'id';
  public static ROLE_ID = 'roleId';
  public static GROUP_ID = 'groupId';
  public static CUSTOMER_ID = 'customerId';
  public static CUSTOMER_API_ID = 'customer_api_id';
  public static OLD_CUSTOMER_ID = 'oldcustomerId';
  public static NEW_CUSTOMER_ID = 'newcustomerId';
  public static USER_ID = 'userId';
  public static ORDER_ID = 'orderId';
  public static ORDER_IDS = 'orderIds';
  public static USR_GRP_ID = 'userGroupId';
  public static PERMISSION_ID = 'permissionId';
  public static ORDER_STATUS = 'orderStatus';
  public static PRICES = 'prices';
  public static WITH_SALES = 'withSales';
  public static MENUS = 'menus';
  public static PAGE = 'offset';
  public static NEXT_TOKEN = 'next_token';
  public static SEARCH = 'search';
  public static REFRESH_TIME = 'refresh_time';
  public static TRANSFER_TOKEN = 'transfer_token';

  public static PAGE_SIZE = '20';
  public static CUSTOMER_DELETE_REASON = 'reason';
  public static LIMIT = 'limit';
  public static TO_DATE = 'to';
  public static FROM_DATE = 'from';
  public static ONLINE = 'ONLINE';
  public static ONLY_NOT_CHECKED_ORDERS = 'not_Checked';
  public static CUSTOMER_FILTER = 'customerFilter';
  public static DOMAIN_FILTER_VALUE = 'domainFilter';
  public static SALES_FILTER = 'salesFilter';
  public static IS_ADD_OPERATION = 'isAdd';
  public static IS_ASSIGN_OPERATION = 'assign';
  public static SALE_ROLE_CODE = 'SALE';
  public static CUSTOMER_TYPE = 'type';
  public static OFFLINE_BILLING_METHOD = 'offline';
  public static TRANSFER_CUSTOMER_TYPE = 'transfer';
  public static ACTION_TYPE = 'actionType';
  public static COUNTRIES = 'countries';
  public static CURRENCIES = 'currencies';
  public static GOOGLE_PRODUCTS = 'google products';
  public static PLANS = 'plans';
  public static GROUP_PERMISSIONS = 'groups permissions';
  public static PERMISSIONS = 'permissions';
  public static GPM_EMAILS = 'gpm emails';
  public static NEW_SUBSC = 'new subscription object'; //NEW OR CURRENT/EDIT
  public static CURRENT_CUSTOMER = 'current customer';
  public static CURRENT_GRP = 'current group';
  public static FLEXIBLE_PLAN = 'FLEXIBLE';
  public static TRIAL_PLAN = 'TRIAL';
  public static PENDING_TOS_ACCEPTANCE = 'PENDING_TOS_ACCEPTANCE';
  public static RENEWAL_WITH_TYPE_CANCEL = 'RENEWAL_WITH_TYPE_CANCEL';
  public static RESELLER_INITIATED = 'RESELLER_INITIATED'; //A manual suspension invoked by a Reseller.
  public static TRIAL_ENDED = 'TRIAL_ENDED';
  public static OTHER = 'OTHER';
  public static DOMAINS = 'domains';
  public static DOMAIN = 'domain';
  public static FROM_SUBS_PAGE = 'from edit subscriptin page';
  public static IS_DOWNGRADE = 'is downgrade action';
  public static ORDER = 'order object';
  public static ACTION = 'action';
  public static GET_ALL = 'getAll';
  public static ADD = 'add';
  public static EDIT = 'edit';
  public static GET = 'get';
  public static POLICIES = 'policies';
  public static ADD_GRP = 'addGrp';
  public static ADD_USR_GRP = 'addUsrGrp';
  public static GOOGLE_INCENTIVES_POLICY = 'google incentives policy';
  public static ORDER_POLICIES = 'order_policies';
  public static CHANGE_STATUS_ACTION = 'changeStatus';
  public static OPPORTUNITY_STATUS = 'opportunityStatus';
  public static OPPORTUNITY_DEPENDENCY = 'opportunityDependency';
  public static OPPORTUNITY_TYPE = 'opportunity_type';
  public static SOW_STATUS = 'sowStatus';
  public static SOW_TYPE = 'sow_type';
  public static POC_STATUS = 'pocStatus';
  public static POC_TYPE = 'poc_type';
  public static PROPOSAL_STATUS = 'proposalStatus';
  public static PROPOSAL_TYPE = 'proposal_type';
  public static CUSTOMER_REQUIREMENTS_STATUS = 'customer_requirement_status';
  public static CUSTOMER_REQUIREMENTS_TYPE = 'customer_requirement_type';
  public static ENGINEER_RECOMMENDATIONS_STATUS =
    'engineer_recommendation_status';
  public static ENGINEER_RECOMMENDATIONS_TYPE = 'engineer_recommendation_type';
  public static NOTIFICATION_TYPE = 'notification_type';
  public static SALES_MANAGER = 'salesUsers';
  public static DELETE_ORDER_ACTION = 'delete';
  public static WITHDRAW_ORDER_ACTION = 'withdraw';
  public static UPDATE_ORDER_ACTION = 'update';
  public static STATUS_PARAM = 'status';
  public static TRIAL_EXPIRY_DATE = 'trialExpDate';
  public static COMMITMENT_EXPIRY_DATE = 'commitmentExpDate';
  public static APPROVAL1_NAME = 'approval1Name';
  public static APPROVAL2_NAME = 'approval2Name';
  public static APPROVAL1_EMAIL = 'approval1Email';
  public static APPROVAL2_EMAIL = 'approval2Email';
  public static GPM_APPROVAL_EMAIL = 'gpmEmail';
  public static LAST_APPROVAL_EMAIL = 'lastEmail';
  public static STARTDATE = 'startDate';
  public static ENDDATE = 'endDate';
  public static WITH_GOOGLE_OFFER = 'withGoogleOffer';
  public static GOOGLE_OFFER_PERCENTAFE = 'googleOfferPercentage';
  public static WITH_CUSTOMER_OFFER = 'withCustomerOffer';
  public static CUSTOMER_OFFER_PERCENTAFE = 'customerOfferPercentage';
  public static NOTES = 'notes';
  public static DESCRIPTION = 'description';
  public static iS_NORMAL_EXECUTION_TO_RETURN_FILE_CONTENT =
    'isNormalExecution';
  public static HAS_INVOICE_FILE = 'hasInvoiceFile';
  public static iS_RENEWAL_EXECUTION = 'isRenewalExecution';
  public static REJECT_REASON = 'rejectReason';
  public static REJECTER_EMAIL = 'rejecterEmail';
  public static DELETE_FILE = 'deleteFile';
  public static FILE_ID = 'file_id';
  public static NEW_FILE_NAME = 'new_file_name';
  public static GROUPS = 'groups';
  public static GROUP = 'group';
  public static MAIN_GROUPS = 'main groups';
  public static GROUP_MEMBERS = 'groupMembers';
  public static SALE_MANAGERS = 'sale manageres';
  public static MANAGERS = 'managers';
  public static SALES = 'sales';
  public static ORDER_CHANGE_PLAN = 'change plan';
  public static GOOGLE_DRIVE_SKU_ID = 'Google-Drive';
  public static Google_Vault_SKU_ID = 'Google-Vault';
  public static CLOUD_IDENTITY_SKU_ID = 'Cloud Identity';
  public static DEFAULT_CURRENCY_ID = 'default currency ID';
  public static DEFAULT_SKU_ID = '1010020027'; //Google Workspace Business Starter
  public static DEFAULT_LICNECES = 20;
  public static IS_TRANSFER_ACTION = 'is transfer action';
  public static MUST_TRANSFER_ALL = 'must tranfser all sub/batch';
  public static TRANSFERABLE_SUBSCRIPTIONS = 'transferable subcs';
  public static DEFAULT_RENEWEL_TYPE = 'SWITCH_TO_PAY_AS_YOU_GO';
  public static CUSTOMER_PAGE_ID = 'cust_content';
  public static USER_PAGE_ID = 'usr_content';
  public static ORDER_PAGE_ID = 'order_content';
  public static ORSERS_REPORT_PAGE_ID = 'orders_report_content';
  public static CUSTOMERS_REPORT_PAGE_ID = 'customers_report_content';
  public static USERS = 'all users';
  public static CUSTOMER_REQUIREMENTS = 'all customer requirements';
  public static CURRENT_CUSTOMER_SUBSCS = 'current customer subscriptions';
  public static ONLY_REWEWAL = 'renewal';
  public static WITH_SALES_CUSTOMERS = 'withSales';
  public static SPECIFIC_DAY = 'day';
  public static ORDERS = 'orders';
  public static WITHOUT_LOADER = 'withoutLoader';
  public static TRANSFER_RELATION_KEY = 'transferRelationKey';
  public static WITH_SKU_SUMMARY = 'withSkuSummary';
  public static WITH_CUSTOMER_SUMMARY = 'withCustSummary';
  public static GLOBAL_DATA_LIST = 'GlobalDataList';

  public static GLOBAL_KEY_COUNTRY = 'countries';
  public static GLOBAL_KEY_SECTORS = 'customer sectors';
  public static GLOBAL_KEY_INDISTRY = 'industries';
  public static GLOBAL_KEY_DEAL_TYPE = 'deal type';
  public static GLOBAL_KEY_DESCISION = 'decision Phase';
  public static GLOBAL_KEY_BUDGET = 'Budget';
  public static GLOBAL_KEY_AUTHORITY = 'Authority';
  public static GLOBAL_KEY_NEED = 'Need';
  public static GLOBAL_KEY_TIMELINE = 'Timeline';
  public static GLOBAL_KEY_OPPORTUNITY_STAGE = 'Opportunity Stage';
  public static GLOBAL_KEY__OPPORTUNITY_STATUS = 'Opportunity Status';
  public static GLOBAL_KEY_ENGAGEMENT = 'Engagement';
  public static GLOBAL_KEY_SOURCE = 'Source';
  public static GLOBAL_KEY_REJECTION_REASON = 'Rejection Reason';
  public static GLOBAL_KEY_CUSTOMER_DELETION_REASONS =
    'Customer Deletion Reason';
  public static GLOBAL_KEY_COMPANY_SECTIONS = 'Company Sections';
  public static GLOBAL_KEY_COMPANY_SECTORS = 'Company Sectors';
  public static GRPS_USERS_DATA = 'grps_users_data';
  public static GROUP_IDS = 'group_ids';
  public static USERS_INFO = 'users_info';
  public static ORDERS_ALL_PAGE_ID = 'orders-all';
  public static ORDERS_RENEWAL_PAGE_ID = 'orders-renewal';
  public static ORDERS_FLEX_PAGE_ID = 'orders-flex';
  public static VIEW_ALL = 'view_all';
  public static GCP = 'gcp';
  public static ORDER_TYPE = 'order_type';
  public static TYPE = 'type';
  public static SUB_ACTION = 'subAction';
  public static SUB_ACTION_UPDATE_PLAN = 'updatePlan';
  public static SUB_ACTION_UPGRADE = 'upgrade';
  public static SUB_ACTION_DOWNGRADE = 'downgrade';
  public static SUB_ACTION_RENEW = 'renew';
  public static TO_EXECUTE = 'to_execute';
  public static EMAIL_RECEIVERS = 'receivers';
  public static WITHOUT_SENDING_EMAIL = 'without_email';
  public static NUMBER = 'number';
  public static NAME = 'name';
  public static WITHOUT_LOG = 'without_log';
  public static GCP_OFFERS = 'gcp_offers';
  public static MAIN_BILLING_ACCOUNTS = 'main_billing_accounts';
  public static ONLY_PARENT = 'only_parent';
  public static MAIN_BILLING_ACCOUNT_LIST = 'main_billing_account_list';
  public static SALES_USERS_LIST = 'user_sales_list';
  public static INTERNAL_SERVER_ERROR = 'Internal Server Error';
  public static SOW = 'sow';
  public static IS_ORG = 'is_org';
  public static DEAL_ACTION = 'deal_action';
  public static CUSTOMER_NAME = 'customer_name';
  public static IS_GCP = 'is_gcp';
  public static CURRENT_BUDGET = 'current budget';
  public static BILLING_LIST = 'BAL';
  public static DEPENDENCY_ON = 'dependency_on';
  public static ONLY_MAIN = 'only_main';
  public static ONLY_MANAGER = 'only_manager';
  public static PROJECT_NAME = 'project_name';
  public static ACTIVATE = 'activate';
  public static SUSPEND = 'suspend';
  public static CANCEL = 'cancel';
  public static IS_PSF = 'is_psf';
  public static PROJECT_IDS = 'project_ids';
  public static VALUE = 'value';
  public static FOLDERS = 'org_folders';
  public static GWS_ONLY = 'gws_only';
  public static WITH_DETAILS = 'with_details';
  public static MSP_ATTACHED_FILE = 'msp_file';
  public static OPPORTUNITY_RFID = 'opportunity_rfid';
  public static IS_MSP = 'is_msp';
  public static SERVICES_DELIVERY_DATE = 'delivery_date';



}
export enum Group { //NOT RELATED TO DB
  Sale = 'sales',
  Sale_Manager = 'sales manager',
  Engineer_Manager = 'engineer manager',
  Admin = 'admin',
  Finance = 'finance',
  GPM = 'gpm', //GOOGLE PRODUCT MANAGER
  ENGINEER = 'engineer',
  OPERATIONS = 'operations',
  CUSTOMER = 'customer',
}
export enum Permissions {
  FINAL_EXECUTION = 'EXC',
  can_view_all = 'VIEW_ALL',
  can_assign = 'ASSIGN',
}

export enum Status {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Canceled = 'CANCELED',
}
export enum OrderStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Deleted = 'DELETED',
  Approved = 'APPROVED',
  Approved_by_GPM = 'GPM APPROVED', // LEVEL2
  Executed = 'EXECUTED', // DAWAM/FINANCE MANG CONFIRM IT
  NOT_Executed = 'NOT EXECUTED', // EXUTED WITH ORDER (RENEWAL AUTOMATICALY EXECUTION CASE)
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Withdraw = 'WITHDRAW',
  CONFIRMED = 'CONFIRMED', //RENEWAL ORDER CONFIRMED BY EXECUTER
  TECHNICALLY_APPROVED = 'Technically approved',
  FINANCE_APPROVED = 'APPROVED BY FINANCE',
  REGISTERED = 'Registered',
  APPROVED_BY_GOOGLE = 'Approved by google',
}
export enum DeletionType {
  DELETION_TYPE_UNDEFINED = 'DELETION_TYPE_UNDEFINED',
  CANCEL = 'CANCEL',
  TRANSFER_TO_DIRECT = 'TRANSFER_TO_DIRECT',
}
export enum PlanValues {
  ANNUAL = 'ANNUAL',
  ANNUAL_MONTHLY_PAY = 'ANNUAL_MONTHLY_PAY',
  ANNUAL_YEARLY_PAY = 'ANNUAL_YEARLY_PAY',
  FLEXIBLE = 'FLEXIBLE',
  TRIAL = 'TRIAL',
  FREE = 'FREE',
}

export enum OrderAction {
  UPDATE_LICENSE = 'update_license',
  ACTIVATE = 'activate_sku',
  SUSPEND = 'suspend_sku',
  NEW_CUSTOMER = 'new_customer',
  NEW_SERVICES = 'new_services',
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
  UPDATE_PLAN = 'update_plan',
  RENEW = 'renew',
  STRAT_PAID_SERVICE = 'start_paid_service',
  UPDATE_RNEWAL_SETTNGS = 'UPDATE_RENEWAL_SETTINGS',
  TRANSFER = 'transfer',
  CANCEL = 'cancel',
}

export enum DealType {
  New = 'New',
  New_SERVICE = 'New Service',
  UPGRADE = 'Upgrade',
  TRANSFER = 'Transfer',
  TRANSFER_UPGRADE = 'Transfer wih upgrade',
}

export enum IntegrationSystemCode {
  ODOO = 'Odoo',
}
export enum OpportunityStatus {
  IN_PROGRESS = 'In Progress',
}
