<div class="container-fluid">
  <div class="bg-white box-shadow mobile-section row rounded-5 p-0 m-0">
    <div class="row">
      <h1 class="h4 p-2 ps-3 d-flex align-items-center mb-0 col">
        <a
          ><img
            src="./assets/img/back-icon.svg"
            class="back-icon cursor-pointer"
            (click)="cancelOrder()"
            alt="back icon" /></a
        >Create an Order
      </h1>
      <div class="d-flex flex-row ms-3">
        <h2 class="h5 col-10 p-0">Order details</h2>
      </div>
    </div>

    <form
      *ngIf="orderForm"
      [formGroup]="orderForm"
      role="form"
      #formDir="ngForm"
      id="add-order-form"
      novalidate
      class="max-h-70 w-100 fluid-section d-flex flex-column flex-xl-row justify-content-xl-between ng-untouched ng-pristine ng-invalid ms-3 gap-3"
    >
      <div class="col-12 col-xl-6">
        <div
          class="mb-2 mt-1 d-flex align-items-center justify-content-between"
        >
          <label
            for="domain"
            class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
            >Domain</label
          >
          <div class="col-10">
            <input
              type="text"
              [ngClass]="{
                'is-invalid': formDir.submitted && customer_domain.invalid
              }"
              class="form-control rounded-pill my_disabled_input"
              id="domain"
              formControlName="customer_domain"
              placeholder="Domain"
            />
          </div>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="order-description"
            class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline border-raduis-16"
            >Order Description</label
          >
          <div class="col-10">
            <textarea
              class="form-control rounded-4 border-raduis-xl-1rem"
              formControlName="description"
              placeholder="Order Description"
              [ngClass]="{
                'is-invalid': formDir.submitted && description.invalid
              }"
            ></textarea>
          </div>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="customer-po"
            class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
            >Customer P.O. #</label
          >
          <div class="col-10">
            <input
              type="text"
              formControlName="po_id"
              [ngClass]="{ 'is-invalid': formDir.submitted && po_id.invalid }"
              class="form-control rounded-pill"
              placeholder="Customer P.O.#"
            />
          </div>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="sale-invoice"
            class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
            >Sale Invoice #</label
          >
          <div class="col-10">
            <input
              type="text"
              formControlName="order_sale_invoice_nbr"
              [ngClass]="{
                'is-invalid':
                  formDir.submitted && order_sale_invoice_nbr.invalid
              }"
              class="form-control rounded-pill"
              placeholder="Sale Invoice #"
            />
          </div>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="source"
            class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
            >Source</label
          >
          <ng-select
            bindLabel="source"
            appendTo="body"
            [searchable]="true"
            class="col-10 p-0"
            [clearable]="true"
            [ngClass]="{
              'is-invalid': formDir.submitted && opportunity_source.invalid
            }"
            formControlName="opportunity_source"
            placeholder="Source"
          >
            <ng-option
              [value]="source"
              *ngFor="let source of opportunitySources"
            >
              {{ source }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="opportunity"
            class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
            >Opportunity #</label
          >
          <div class="col-10">
            <input
              type="text"
              formControlName="opportunity_nbr"
              [ngClass]="{
                'is-invalid': formDir.submitted && opportunity_nbr.invalid
              }"
              class="form-control rounded-pill"
              placeholder="Opportunity #"
            />
          </div>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="opportunity-status"
            class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
            >Opportunity Status</label
          >
          <ng-select
            bindLabel="status"
            appendTo="body"
            [searchable]="true"
            class="col-10 p-0"
            [clearable]="true"
            [ngClass]="{
              'is-invalid': formDir.submitted && opportunity_status.invalid
            }"
            formControlName="opportunity_status"
            placeholder="Opportunity Status"
          >
            <ng-option
              [value]="status"
              *ngFor="let status of opportunityStatus"
            >
              {{ status }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="google-rep"
            class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
            >Google Rep</label
          >
          <div class="col-10">
            <input
              type="text"
              class="form-control rounded-pill"
              formControlName="google_rep"
              placeholder="Google Rep"
            />
          </div>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="company_section"
            class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
            >Company Section</label
          >
          <ng-select
            id="company_section"
            bindLabel="section"
            appendTo="body"
            [searchable]="true"
            class="col-12 col-lg-10 p-0 flex-fill form-select rounded-pill"
            [clearable]="true"
            [ngClass]="{
              'is-invalid': formDir.submitted && company_section.invalid
            }"
            formControlName="company_section"
            placeholder="Company Section"
          >
            <ng-option
              [value]="section"
              *ngFor="let section of companySections"
            >
              {{ section }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="company_section"
            class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
            >Team</label
          >
          <ng-select
            id="order_team"
            bindLabel="name"
            appendTo="body"
            [searchable]="true"
            class="col-12 col-lg-10 p-0 flex-fill form-select rounded-pill"
            [clearable]="true"
            [ngClass]="{
              'is-invalid': formDir.submitted && team.invalid
            }"
            formControlName="team"
            (change)="selectTeam()"
            placeholder="Team"
          >
            <ng-option [value]="team" *ngFor="let team of teams">
              {{ team.name }}
            </ng-option>
          </ng-select>
        </div>
        <!-- <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="customer-email"
            class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
            >Customer Email to notify on execution</label
          >
          <input
            type="email"
            [ngClass]="{
              'is-invalid':
                formDir.submitted && customer_email_notification.invalid
            }"
            class="form-control rounded-pill col-10"
            formControlName="customer_email_notification"
            placeholder="Customer Email to notify on execution"
          />
        </div> -->
      </div>
      <div class="col-12 col-xl-6">
        <ng-conatiner formArrayName="sku">
          <div
            class="accordion accordion-flush pr-4 col-11"
            id="sku-container"
            *ngFor="let skuModel of sku.controls; let index = index"
          >
            <div class="accordion-item border-0 mb-3">
              <div class="accordion-header">
                <div
                  class="real-accordion-button d-flex flex-column border border-primary rounded-5 px-3 py-2"
                >
                  <div class="row g-1 mb-2">
                    <div
                      [formGroupName]="index"
                      class="align-items-center col-10 d-flex flex-row justify-content-between ng-invalid ng-pristine ng-untouched"
                    >
                      <label
                        class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
                        >SKU</label
                      >
                      <ng-select
                        bindLabel="product.sku_name"
                        appendTo="body"
                        [searchable]="true"
                        [clearable]="false"
                        class="col-10 p-0"
                        (change)="calculatePrices(skuModel)"
                        formControlName="sku_id"
                        placeholder="SKU"
                      >
                        <ng-option
                          [value]="product.sku_id"
                          *ngFor="let product of googleProducts"
                        >
                          {{ product.sku_name }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <img
                      (click)="deleteSKU(skuModel.value.sku_id)"
                      class="px-1 px-md-2 cursor-pointer btn_sku_delete"
                      src="./assets/img/delete-icon.svg"
                      alt="delete icon"
                    />
                    <!-- <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-label="expand icon"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    class="accordion-button cursor-pointer p-1"
                  ></button> -->
                    <button
                      type="button"
                      class="btn btn-toggle btn_sku_toggle"
                      [class.active]="skuModel.value.expanded"
                      (click)="expandSku(skuModel.value.sku_id)"
                    >
                      <img
                        [src]="
                          skuModel.value.expanded
                            ? './assets/img/expanded-icon.svg'
                            : './assets/img/expand-icon.svg'
                        "
                        alt="Toggle Image"
                        class="img-fluid"
                      />
                    </button>
                  </div>
                  <div *ngIf="skuModel.value.expanded">
                    <div
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="sku-container"
                    >
                      <div class="accordion-body p-0 mt-2">
                        <div [formGroupName]="index" class="row g-1 mb-2">
                          <label
                            for="licenses-number"
                            class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
                            >Number of Licenses</label
                          >
                          <div class="col-9">
                            <input
                              type="number"
                              min="1"
                              (change)="calculatePrices(skuModel)"
                              class="rounded-pill form-control"
                              formControlName="license"
                            />
                          </div>
                        </div>
                        <div class="row g-1 mb-2">
                          <div class="col-2">Google Offer</div>
                          <div class="col-2">
                            <div class="form-check" [formGroupName]="index">
                              <input
                                formControlName="with_google_offer"
                                class="form-check-input"
                                type="radio"
                                [value]="true"
                                (change)="calculatePrices(skuModel)"
                                name="with_google_offer"
                                id="google-offer-1"
                              />
                              <label
                                class="form-check-label"
                                for="google-offer-1"
                              >
                                Yes
                              </label>
                            </div>
                            <div class="form-check" [formGroupName]="index">
                              <input
                                formControlName="with_google_offer"
                                class="form-check-input"
                                type="radio"
                                (change)="calculatePrices(skuModel)"
                                [value]="false"
                                name="with_google_offer"
                                id="google-offer-2"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="google-offer-2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          <div
                            [formGroupName]="index"
                            class="mb-2 d-flex align-items-center justify-content-between col"
                          >
                            <label
                              for="discount-percentage"
                              class="form-label text-nowrap col-3 me-2 mb-0 d-none d-md-inline"
                              >Discount %</label
                            >
                            <input
                              formControlName="google_offer"
                              type="number"
                              min="0"
                              (change)="calculatePrices(skuModel)"
                              class="form-control rounded-pill"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <div class="row g-1 mb-2">
                          <div class="col-2">Customer Offer</div>
                          <div class="col-2">
                            <div class="form-check" [formGroupName]="index">
                              <input
                                class="form-check-input"
                                type="radio"
                                formControlName="with_customer_offer"
                                name="with_customer_offer"
                                [value]="true"
                                (change)="calculatePrices(skuModel)"
                                id="google-offer-21"
                              />
                              <label
                                class="form-check-label"
                                for="google-offer-21"
                              >
                                Yes
                              </label>
                            </div>
                            <div class="form-check" [formGroupName]="index">
                              <input
                                formControlName="with_customer_offer"
                                class="form-check-input"
                                type="radio"
                                (change)="calculatePrices(skuModel)"
                                name="with_customer_offer"
                                id="google-offer-22"
                                [value]="false"
                                checked
                              />
                              <label
                                class="form-check-label"
                                for="google-offer-22"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          <div
                            [formGroupName]="index"
                            class="mb-2 d-flex align-items-center justify-content-between col"
                          >
                            <label
                              for="discount-percentage"
                              class="form-label text-nowrap col-3 me-2 mb-0 d-none d-md-inline"
                              >Discount %</label
                            >
                            <input
                              type="number"
                              min="0"
                              (change)="calculatePrices(skuModel)"
                              formControlName="customer_offer"
                              class="form-control rounded-pill"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <div class="row g-1 mb-2" [formGroupName]="index">
                          <label
                            for="plan_order"
                            class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                            >Plan</label
                          >
                          <ng-select
                            bindLabel="plan.name"
                            appendTo="body"
                            [searchable]="true"
                            [clearable]="false"
                            id="plan_order"
                            (change)="calculatePrices(skuModel)"
                            class="col-10 p-0"
                            formControlName="plan_id"
                            placeholder="Plan"
                          >
                            <ng-option
                              [value]="plan._id"
                              *ngFor="let plan of plans"
                            >
                              {{ plan.name }}
                            </ng-option>
                          </ng-select>
                          <!-- <div
                          *ngFor="let plan of plans"
                          class="form-check form-check-inline col"
                          [formGroupName]="index"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="test"
                            formControlName="plan_id"
                          />
                          <label class="form-check-label" for="annual">{{
                            plan.code
                          }}</label>
                        </div> -->
                        </div>
                        <div
                          [formGroupName]="index"
                          class="mb-3 d-flex align-items-center justify-content-between"
                        >
                          <label
                            for="start-date"
                            class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                            >Start Date</label
                          >
                          <input
                            type="date"
                            (ngModelChange)="calculatePrices(skuModel)"
                            formControlName="start_date"
                            class="form-control rounded-pill"
                          />
                        </div>
                        <div
                          [formGroupName]="index"
                          class="mb-2 d-flex align-items-center justify-content-between"
                        >
                          <label
                            for="end-date"
                            class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                            >End Date</label
                          >
                          <input
                            type="date"
                            (ngModelChange)="calculatePrices(skuModel)"
                            formControlName="end_date"
                            class="form-control rounded-pill"
                          />
                        </div>
                        <div
                          [formGroupName]="index"
                          class="d-flex py-3 input_price"
                        >
                          <div class="col px-0">Selling Price</div>
                          <div class="col-1">
                            <input
                              type="text"
                              formControlName="selling_price"
                              class="form-control text-center product-sans-bold text-primary px-0"
                            />
                          </div>
                        </div>
                        <div
                          class="d-flex py-3 input_price"
                          [formGroupName]="index"
                        >
                          <div class="col px-0">Cost Price</div>
                          <div class="col-1">
                            <input
                              type="text"
                              formControlName="cost_price"
                              class="form-control text-center product-sans-bold text-primary px-0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-conatiner>
        <button
          type="button"
          class="btn btn-outline-primary rounded-pill"
          (click)="addAnotherSKU()"
        >
          <img src="./assets/img/plus-icon.svg" alt="plus-icon" class="pe-2" />
          <span>Add another SKU</span>
        </button>

        <div
          class="d-flex me-2 align-items-start justify-content-center justify-content-xl-end mt-xl-2 pt-xl-4 pb-2 product-sans-light"
        >
          <input
            class="btn btn-primary small-text btn-color rounded-pill col-5 col-xl-2 p-1 p-xl-2 fs-6 align-self-end"
            value="{{ saveText }}"
            (click)="saveOrder()"
            type="submit"
          />
          <input
            class="btn btn-primary small-text btn-color rounded-pill col-5 col-xl-2 p-1 p-xl-2 mx-1 mx-xl-4 fs-6 align-self-end"
            value="Cancel"
            (click)="cancelOrder()"
          />
        </div>
      </div>
    </form>
  </div>
</div>
