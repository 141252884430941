import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint, Unicode } from 'src/shared/Endpoint';

@Injectable({
  providedIn: 'root',
})
export class GcpService {
  constructor(private http: HttpClient) {}

  async getListOfBillingAccount(
    onlyParent: boolean,
    withoutLoader: string,
    value: string,
    token: number,
    limit: number
  ) {
    var params = new HttpParams()
      .set(Unicode.ONLY_PARENT, onlyParent)
      .set(Unicode.SEARCH, value ?? null)
      .set(Unicode.LIMIT, limit)
      .set(Unicode.NEXT_TOKEN, token)
      .set(Unicode.WITHOUT_LOADER, withoutLoader);
    return await this.http
      .get(Endpoint.GLOBAL_URL + '' + Endpoint.LIST_OF_BILLING_ACCOUNT, {
        params: params,
      })
      .toPromise();
  }

  async getListOfBillingAccount_DB(
    offset: number,
    limit: number,
    value = null
  ) {
    var params = new HttpParams()
      .set(Unicode.LIMIT, limit)
      .set(Unicode.PAGE, offset)
      .set(Unicode.SEARCH, value);
    return await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.LIST_OF_BILLING_ACCOUNT_DB, {
        params: params,
      })
      .toPromise();
  }

  async listOfprojectsLinkedToBilling(billingId: string) {
    var params = new HttpParams().set(Unicode.ID, billingId);
    return await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.LIST_PROJECTS_LINKED_TO_BILLING, {
        params: params,
      })
      .toPromise();
  }

  async listOfBudgetsByBillingId(billingId: string) {
    var params = new HttpParams().set(Unicode.ID, billingId);
    return await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.LIST_OF_BUDGETS_FOR_BILLING, {
        params: params,
      })
      .toPromise();
  }

  async BillingInfo(billingId: string) {
    var params = new HttpParams().set(Unicode.ID, billingId);
    return await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.BILLING_INFO, {
        params: params,
      })
      .toPromise();
  }

  async updateBillingInfo(body) {
    return await this.http
      .put(Endpoint.GLOBAL_URL + Endpoint.BILLING_INFO, body)
      .toPromise();
  }

  async unlinkProject(body) {
    return await this.http
      .put(Endpoint.GLOBAL_URL + Endpoint.UNLINK_PROJECT, body)
      .toPromise();
  }

  async billingConsumptionData(projectIds: string[]) {
    var params = new HttpParams()
      .set(Unicode.WITHOUT_LOADER, '1')
      .set(Unicode.PROJECT_IDS, JSON.stringify(projectIds));
    return await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.BILLING_CONSUMPTION_DATA, {
        params: params,
      })
      .toPromise();
  }

  async editProject(number: string, newName: string) {
    return await this.http
      .put(Endpoint.GLOBAL_URL + Endpoint.UPDTAE_PROJECT, {
        number: number,
        name: newName,
      })
      .toPromise();
  }

  async deleteProject(number: string) {
    var params = new HttpParams().set(Unicode.NUMBER, number);
    return await this.http
      .delete(Endpoint.GLOBAL_URL + Endpoint.DELETE_PROJECT, {
        params: params,
      })
      .toPromise();
  }
}
