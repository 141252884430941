<form
  *ngIf="orderForm"
  [formGroup]="orderForm"
  role="form"
  #formDir="ngForm"
  id="add-order-form"
  novalidate
  class="max-h-70 w-100 fluid-section row justify-content-xl-between ng-untouched ng-pristine ng-invalid ms-1 mb-2 pt-1"
>
  <div class="col-12 col-xl-6 pl-0">
    <div
      *ngIf="fromCustomer"
      class="mb-2 mt-1 d-flex align-items-center justify-content-between"
    >
      <label
        for="domain"
        class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
        >Domain</label
      >
      <div class="col-10">
        <input
          type="text"
          [ngClass]="{
            'is-invalid': formDir.submitted && customer_domain.invalid
          }"
          class="form-control rounded-pill my_disabled_input flex-fill"
          id="domain"
          formControlName="customer_domain"
          placeholder="Domain"
        />
      </div>
    </div>
    <div
      *ngIf="!fromCustomer"
      class="mb-2 mt-1 d-flex align-items-center justify-content-between"
    >
      <label
        for="domain"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Domain</label
      >
      <ng-select
        bindLabel="customer.domain"
        appendTo="body"
        (search)="onSearchDomainEnter($event)"
        (change)="selectDomain()"
        [searchable]="true"
        class="col-9 col-lg-9 p-0 flex-fill form-select rounded-pill"
        [ngClass]="{
          'is-invalid': formDir.submitted && customer_domain.invalid
        }"
        [clearable]="true"
        formControlName="customer_domain"
        placeholder="Customers"
      >
        <ng-option [value]="domain" *ngFor="let domain of searchResultDomains">
          {{ domain.domain }}
        </ng-option>
      </ng-select>
      <div class="col-1 col-lg-1 text-end">
        <img
          data-toggle="tooltip"
          title="add new customer"
          (click)="createNewCustomer()"
          src="./assets/img/plus-icon.svg"
          alt="plus icon"
          class="pe-2"
        />
      </div>
    </div>

    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="admin-name"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Organization</label
      >
      <div class="col-10" *ngIf="fromCustomer">
        <input
          type="text"
          [ngClass]="{
            'is-invalid': formDir.submitted && customer_organisation.invalid
          }"
          class="form-control rounded-pill my_disabled_input flex-fill"
          id="c_organisation"
          formControlName="customer_organisation"
          placeholder="Organisation"
        />
      </div>
      <ng-select
        *ngIf="!fromCustomer"
        bindLabel="customer.organisation"
        appendTo="body"
        id="org"
        (search)="onSearchDomainEnter($event)"
        (change)="selectDomain()"
        [searchable]="true"
        autofocus
        [ngClass]="{
          'is-invalid': formDir.submitted && customer_organisation.invalid
        }"
        class="col-9 col-lg-9 p-0 flex-fill form-select rounded-pill"
        [clearable]="true"
        formControlName="customer_organisation"
        placeholder="Organization"
      >
        <ng-option [value]="domain" *ngFor="let domain of searchResultDomains">
          {{ domain.organisation_name }}
        </ng-option>
      </ng-select>
    </div>
    <div
      *ngIf="!isGWS"
      class="mb-2 d-flex align-items-center justify-content-between"
    >
      <label
        for="source"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Engagement</label
      >
      <ng-select
        bindLabel="eng"
        appendTo="body"
        [searchable]="true"
        class="col-12 col-lg-10 p-0 flex-fill form-select rounded-pill"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && engagement.invalid
        }"
        formControlName="engagement"
        placeholder="Engagement"
      >
        <ng-option [value]="eng" *ngFor="let eng of engagements">
          {{ eng }}
        </ng-option>
      </ng-select>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="order-description"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline border-raduis-16"
        >Order Description</label
      >
      <div class="col-12 col-lg-10">
        <textarea
          class="form-control rounded-4 border-raduis-xl-1rem flex-fill"
          formControlName="description"
          placeholder="Order Description"
          [ngClass]="{
            'is-invalid': formDir.submitted && description.invalid
          }"
        ></textarea>
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="customer-po"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Customer P.O. #</label
      >
      <div class="col-12 col-lg-10">
        <input
          type="text"
          formControlName="po_id"
          [ngClass]="{ 'is-invalid': formDir.submitted && po_id.invalid }"
          class="form-control rounded-pill flex-fill"
          placeholder="Customer P.O.#"
        />
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="sale-invoice"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Sale Invoice #</label
      >
      <div class="col-12 col-lg-10">
        <input
          type="text"
          formControlName="order_sale_invoice_nbr"
          [ngClass]="{
            'is-invalid': formDir.submitted && order_sale_invoice_nbr.invalid
          }"
          class="form-control rounded-pill flex-fill"
          placeholder="Sale Invoice #"
        />
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="source"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Opportunity Source</label
      >
      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-12 col-lg-10 p-0 flex-fill form-select rounded-pill"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && opportunity_source.invalid
        }"
        formControlName="opportunity_source"
        placeholder="Source"
      >
        <ng-option [value]="source" *ngFor="let source of opportunitySources">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="opportunity"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Opportunity #</label
      >
      <div class="col-12 col-lg-10">
        <input
          type="text"
          formControlName="opportunity_nbr"
          [ngClass]="{
            'is-invalid': formDir.submitted && opportunity_nbr.invalid
          }"
          class="form-control rounded-pill flex-fill"
          placeholder="Opportunity #"
        />
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="opportunity-status"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Opportunity Status</label
      >
      <ng-select
        bindLabel="status"
        appendTo="body"
        [searchable]="true"
        class="col-12 col-lg-10 p-0 flex-fill form-select rounded-pill"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && opportunity_status.invalid
        }"
        formControlName="opportunity_status"
        placeholder="Opportunity Status"
      >
        <ng-option [value]="status" *ngFor="let status of opportunityStatus">
          {{ status }}
        </ng-option>
      </ng-select>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="google-rep"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Google Rep</label
      >
      <div class="col-12 col-lg-10">
        <input
          type="text"
          class="form-control rounded-pill flex-fill"
          formControlName="google_rep"
          placeholder="Google Rep"
        />
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="company_section"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Company Section</label
      >
      <ng-select
        id="company_section"
        bindLabel="section"
        appendTo="body"
        [searchable]="true"
        class="col-12 col-lg-10 p-0 flex-fill form-select rounded-pill"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && company_section.invalid
        }"
        formControlName="company_section"
        placeholder="Company Section"
      >
        <ng-option [value]="section" *ngFor="let section of companySections">
          {{ section }}
        </ng-option>
      </ng-select>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="company_section"
        class="form-label text-wrap col-lg-2 me-2 mb-0 d-none d-md-inline"
        >Team</label
      >
      <ng-select
        id="order_team"
        bindLabel="name"
        appendTo="body"
        [searchable]="true"
        class="col-12 col-lg-10 p-0 flex-fill form-select rounded-pill"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && team.invalid
        }"
        formControlName="team"
        (change)="selectOrderTeam()"
        placeholder="Team"
      >
        <ng-option [value]="team" *ngFor="let team of teams">
          {{ team.name }}
        </ng-option>
      </ng-select>
    </div>
    <!-- <div class="mb-2 d-flex align-items-center justify-content-between">
    <label
      for="customer-email"
      class="form-label text-wrap col-2 me-2 mb-0 d-none d-md-inline"
      >Customer Email to notify on execution</label
    >
    <input
      type="email"
      [ngClass]="{
        'is-invalid':
          formDir.submitted && customer_email_notification.invalid
      }"
      class="form-control rounded-pill "
      formControlName="customer_email_notification"
      placeholder="Customer Email to notify on execution"
    />
  </div> -->
  </div>
  <div class="col-12 col-xl-6 ps-l-5 ps-xl-5">
    <div class="mb-2 col-12 col-lg-12" *ngIf="!isGWS">
      <label
        for="customer-email"
        class="form-label text-wrap col-lg-2 me-0 mb-0 d-none d-md-inline"
        >Project Number</label
      >
      <div class="col-12 col-lg-11">
        <input
          type="text"
          class="form-control rounded-4 flex-fill"
          formControlName="project_number"
          [ngClass]="{
            'is-invalid': formDir.submitted && project_number.invalid
          }"
          placeholder="Project Number"
        />
      </div>
    </div>
    <div class="g-1 mb-2 col-12" *ngIf="!isGWS">
      <label
        for="customer-email"
        class="form-label text-wrap col-lg-2 me-0 mb-0 d-none d-md-inline"
        >Project Name</label
      >
      <div class="col-12 col-lg-11">
        <input
          type="text"
          class="form-control rounded-4 flex-fill"
          formControlName="project_name"
          [ngClass]="{
            'is-invalid': formDir.submitted && project_name.invalid
          }"
          placeholder="Project Name"
        />
      </div>
    </div>
    <div class="g-1 mb-2 col-12" *ngIf="!isGWS">
      <label
        for="customer-email"
        class="form-label text-wrap col-lg-2 me-0 mb-0 d-none d-md-inline"
        >Billing Name</label
      >
      <div class="col-12 col-lg-11">
        <input
          type="text"
          class="form-control rounded-4 flex-fill"
          formControlName="billing_name"
          [ngClass]="{
            'is-invalid': formDir.submitted && billing_name.invalid
          }"
          placeholder="Billing ID"
        />
      </div>
    </div>
    <!---SUPPORT--->
    <div
      class="col-11 row border border-primary rounded-5 px-3 py-3 mb-3 mx-1"
      formGroupName="support"
    >
      <div class="col-4 d-flex">
        <label class="me-2">Support</label>
        <div class="form-check me-4">
          <div class="col-10">
            <input
              formControlName="available"
              class="form-check-input flex-fill"
              type="radio"
              [value]="true"
              name="available"
              id="support-available-y"
            />
          </div>
          <label class="form-check-label" for="support-available-y">
            Yes
          </label>
        </div>
        <div class="form-check">
          <div class="col-10">
            <input
              formControlName="available"
              class="form-check-input flex-fill"
              type="radio"
              [value]="false"
              name="available"
              id="support-available-n"
              checked
            />
          </div>
          <label class="form-check-label" for="support-available-n"> No </label>
        </div>
      </div>

      <div
        class="col-5 ms-4 d-flex"
        *ngIf="support.controls.available.value == true"
      >
        <label class="me-3">Price</label>
        <input
          type="number"
          min="0"
          placeholder="Price"
          [ngClass]="{
            'is-invalid':
              formDir.submitted &&
              support.controls.available.value == true &&
              support.controls.price.value == null
          }"
          class="rounded-pill form-control flex-fill"
          formControlName="price"
        />
      </div>
      <div
        class="col-12 mt-3 d-flex"
        *ngIf="support.controls.available.value == true"
      >
        <label class="form-label me-3">Start Date</label>
        <div>
          <input
            type="date"
            [ngClass]="{
              'is-invalid':
                formDir.submitted &&
                support.controls.available.value == true &&
                !support.controls.start_date.value
            }"
            formControlName="start_date"
            class="form-control rounded-pill flex-fill"
          />
        </div>
        <label class="form-label me-3 ms-5">End Date</label>
        <div>
          <input
            type="date"
            [ngClass]="{
              'is-invalid':
                formDir.submitted &&
                support.controls.available.value == true &&
                !support.controls.end_date.value
            }"
            formControlName="end_date"
            class="form-control rounded-pill flex-fill"
          />
        </div>
      </div>
    </div>
    <!---SKUS--->
    <ng-conatiner *ngIf="isGWS" formArrayName="sku">
      <div
        class="accordion accordion-flush pr-4 col-11 mx-1"
        id="sku-container"
        *ngFor="let skuModel of sku.controls; let index = index"
      >
        <div class="accordion-item border-0 mb-3">
          <div class="accordion-header">
            <div
              class="real-accordion-button d-flex flex-column border border-primary rounded-5 px-3 py-3"
            >
              <div class="row mb-2">
                <div
                  [formGroupName]="index"
                  class="align-items-center col-10 d-flex flex-column flex-md-row justify-content-between ng-invalid ng-pristine ng-untouched"
                >
                  <label
                    class="form-label text-wrap col-12 col-md-2 me-2 mb-2 mb-md-0 d-none d-md-inline"
                    >SKU</label
                  >
                  <ng-select
                    bindLabel="product.sku_name"
                    appendTo="body"
                    [searchable]="true"
                    [clearable]="false"
                    class="col-12 col-md-10 p-0 flex-fill form-select rounded-pill"
                    [ngClass]="{
                      my_disabled_input:
                        actions?.isActionOrder == true &&
                        actions?.toUpdatePlan == true,
                      'is-invalid':
                        formDir.submitted &&
                        sku.controls[index].get('sku_id').invalid
                    }"
                    (change)="calculatePrices(skuModel)"
                    formControlName="sku_id"
                    placeholder="SKU"
                  >
                    <ng-option
                      [value]="product.sku_id"
                      *ngFor="let product of googleProducts"
                    >
                      {{ product.sku_name }}
                    </ng-option>
                  </ng-select>
                </div>
                <img
                  (click)="deleteSKU(skuModel.value.sku_id)"
                  class="px-1 px-md-2 cursor-pointer btn_sku_delete"
                  src="./assets/img/delete-icon.svg"
                  alt="delete icon"
                />
                <button
                  type="button"
                  class="btn btn-toggle btn_sku_toggle"
                  [class.active]="skuModel.value.expanded"
                  (click)="expandSku(skuModel.value.sku_id)"
                >
                  <img
                    [src]="
                      skuModel.value.expanded
                        ? './assets/img/expanded-icon.svg'
                        : './assets/img/expand-icon.svg'
                    "
                    alt="Toggle Image"
                    class="img-fluid"
                  />
                </button>
              </div>
              <div *ngIf="skuModel.value.expanded">
                <div
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="sku-container"
                >
                  <div class="accordion-body p-0 mt-2">
                    <div [formGroupName]="index" class="row g-1 mb-2">
                      <label
                        for="licenses-number"
                        class="form-label text-wrap col-4 col-md-2 me-0 mb-2 mb-md-0 d-md-inline"
                        >Number of Licenses</label
                      >
                      <div class="col-8 col-md-10">
                        <input
                          type="number"
                          min="1"
                          [ngClass]="{
                            'is-invalid':
                              formDir.submitted &&
                              sku.controls[index].get('license').invalid
                          }"
                          (change)="calculatePrices(skuModel)"
                          class="rounded-pill form-control flex-fill"
                          formControlName="license"
                        />
                      </div>
                    </div>
                    <div class="row g-1 mb-2">
                      <div class="col-12 col-md-2">Google Offer</div>
                      <div class="col-12 col-md-4">
                        <div class="form-check" [formGroupName]="index">
                          <div class="col-10">
                            <input
                              formControlName="with_google_offer"
                              class="form-check-input flex-fill"
                              type="radio"
                              [value]="true"
                              (change)="calculatePrices(skuModel)"
                              name="with_google_offer"
                              id="google-offer-1"
                            />
                          </div>
                          <label class="form-check-label" for="google-offer-1">
                            Yes
                          </label>
                        </div>
                        <div class="form-check" [formGroupName]="index">
                          <div class="col-10">
                            <input
                              formControlName="with_google_offer"
                              class="form-check-input flex-fill"
                              type="radio"
                              (change)="calculatePrices(skuModel)"
                              [value]="false"
                              name="with_google_offer"
                              id="google-offer-2"
                              checked
                            />
                          </div>
                          <label class="form-check-label" for="google-offer-2">
                            No
                          </label>
                        </div>
                      </div>
                      <div
                        [formGroupName]="index"
                        class="mb-2 d-flex align-items-center justify-content-between col-12 col-md-6"
                      >
                        <label
                          for="discount-percentage"
                          class="form-label text-nowrap col-4 col-md-3 me-2 mb-0 d-md-inline"
                          >Discount %</label
                        >
                        <div class="col-8 col-md-9 col-lg-9">
                          <input
                            formControlName="google_offer"
                            type="number"
                            min="0"
                            (change)="calculatePrices(skuModel)"
                            class="form-control rounded-pill flex-fill"
                            placeholder="0"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row g-1 mb-2">
                      <div class="col-12 col-md-2">Customer Offer</div>
                      <div class="col-12 col-md-4">
                        <div class="form-check" [formGroupName]="index">
                          <div class="col-10">
                            <input
                              class="form-check-input flex-fill"
                              type="radio"
                              formControlName="with_customer_offer"
                              name="with_customer_offer"
                              [value]="true"
                              (change)="calculatePrices(skuModel)"
                              id="google-offer-21"
                            />
                          </div>
                          <label class="form-check-label" for="google-offer-21">
                            Yes
                          </label>
                        </div>
                        <div class="form-check" [formGroupName]="index">
                          <div class="col-10">
                            <input
                              formControlName="with_customer_offer"
                              class="form-check-input flex-fill"
                              type="radio"
                              (change)="calculatePrices(skuModel)"
                              name="with_customer_offer"
                              id="google-offer-22"
                              [value]="false"
                              checked
                            />
                          </div>
                          <label class="form-check-label" for="google-offer-22">
                            No
                          </label>
                        </div>
                      </div>
                      <div
                        [formGroupName]="index"
                        class="mb-2 d-flex align-items-center justify-content-between col-12 col-md-6"
                      >
                        <label
                          for="discount-percentage"
                          class="form-label text-nowrap col-4 col-md-3 col-lg-3 me-2 mb-0 d-md-inline"
                          >Discount %</label
                        >
                        <div class="col-8 col-md-9 col-lg-9">
                          <input
                            type="number"
                            min="0"
                            (change)="calculatePrices(skuModel)"
                            formControlName="customer_offer"
                            class="form-control rounded-pill flex-fill"
                            placeholder="0"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row g-1 mb-2" [formGroupName]="index">
                      <label
                        for="plan_order"
                        class="form-label text-nowrap col-12 col-md-2 me-0 mb-0 d-none d-md-inline"
                        >Plan</label
                      >
                      <ng-select
                        bindLabel="plan.name"
                        appendTo="body"
                        [searchable]="true"
                        [clearable]="false"
                        id="plan_order"
                        [ngClass]="{
                          'is-invalid':
                            formDir.submitted &&
                            sku.controls[index].get('plan_id').invalid
                        }"
                        (change)="calculatePrices(skuModel)"
                        class="col-12 col-md-9 p-0 flex-fill"
                        formControlName="plan_id"
                        placeholder="Plan"
                      >
                        <ng-option
                          [value]="plan._id"
                          *ngFor="let plan of plans"
                        >
                          {{ plan.name }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <div
                      [formGroupName]="index"
                      class="mb-3 d-flex align-items-center row"
                    >
                      <label
                        for="start-date"
                        class="form-label text-nowrap col-12 col-md-2 me-0 mb-0 d-none d-md-inline"
                        >Start Date</label
                      >
                      <div class="col-12 col-md-6">
                        <input
                          type="date"
                          [ngClass]="{
                            'is-invalid':
                              formDir.submitted &&
                              sku.controls[index].get('start_date').invalid
                          }"
                          (ngModelChange)="calculatePrices(skuModel)"
                          formControlName="start_date"
                          class="form-control rounded-pill flex-fill"
                        />
                      </div>
                      <div class="col-12 col-md-4">
                        <input
                          type="number"
                          step="1"
                          (ngModelChange)="calculatePrices(skuModel)"
                          formControlName="annual_months"
                          class="form-control rounded-pill flex-fill"
                          placeholder="Total Months"
                        />
                      </div>
                    </div>
                    <div
                      [formGroupName]="index"
                      class="mb-2 d-flex align-items-center justify-content-between row"
                    >
                      <label
                        for="end-date"
                        class="form-label text-nowrap col-12 col-md-2 me-0 mb-0 d-none d-md-inline"
                        >End Date</label
                      >
                      <div class="col-12 col-md-10">
                        <input
                          type="date"
                          (ngModelChange)="calculatePrices(skuModel)"
                          formControlName="end_date"
                          [ngClass]="{
                            'is-invalid':
                              formDir.submitted &&
                              sku.controls[index].get('end_date').invalid
                          }"
                          class="form-control rounded-pill flex-fill my_disabled_input"
                        />
                      </div>
                    </div>
                    <div
                      [formGroupName]="index"
                      class="d-flex py-3 input_price row"
                    >
                      <label
                        for="end-date"
                        class="form-label text-nowrap col-6 col-md-2 col-lg-2 me-0 mb-0 d-md-inline"
                        >Selling Price</label
                      >
                      <div class="col-6 col-md-2 col-lg-2">
                        <input
                          type="text"
                          formControlName="selling_price"
                          class="form-control col-1 text-center product-sans-bold text-primary px-0 flex-fill"
                        />
                      </div>
                    </div>
                    <div
                      class="d-flex py-3 input_price row"
                      [formGroupName]="index"
                    >
                      <label
                        for="end-date"
                        class="form-label text-nowrap col-6 col-md-2 col-lg-2 me-0 mb-0 d-md-inline"
                        >Cost Price</label
                      >
                      <div class="col-6 col-md-2 col-lg-2">
                        <input
                          type="text"
                          formControlName="cost_price"
                          class="form-control col-1 text-center product-sans-bold text-primary px-0 flex-fill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-end mx-0">
        <p class="col-2 mb-2 px-0 text-end">Total Users</p>
        <p class="text-primary col-2 product-sans-bold mb-2">
          {{ totalNbrOfUsers }}
        </p>
      </div>
    </ng-conatiner>
    <button
      *ngIf="isGWS && !actions?.isActionOrder"
      type="button"
      class="btn btn-outline-primary rounded-pill"
      (click)="addAnotherSKU()"
    >
      <img src="./assets/img/plus-icon.svg" alt="plus-icon" class="pe-2" />
      <span>Add another SKU</span>
    </button>

    <ng-conatiner *ngIf="!isGWS" formArrayName="sku">
      <div class="container-fluid">
        <div
          class="accordion accordion-flush pr-4 col-11 mx-1"
          id="sku-container"
          *ngFor="let skuModel of sku.controls; let index = index"
        >
          <div class="accordion-item border-0 mb-3">
            <div class="accordion-header">
              <div
                class="real-accordion-button d-flex flex-column border border-primary rounded-5 px-3 py-3"
              >
                <div class="row mb-2">
                  <div
                    [formGroupName]="index"
                    class="align-items-center col-10 d-flex flex-column flex-md-row justify-content-between ng-invalid ng-pristine ng-untouched"
                  >
                    <label
                      class="form-label text-wrap col-12 col-md-2 me-2 mb-2 mb-md-0 d-none d-md-inline"
                      >SKU</label
                    >
                    <ng-select
                      bindLabel="product.sku_name"
                      appendTo="body"
                      [searchable]="true"
                      [clearable]="false"
                      class="col-9 col-md-10 p-0 flex-fill form-select rounded-pill"
                      [ngClass]="{
                        my_disabled_input:
                          actions?.isActionOrder == true &&
                          actions?.toUpdatePlan == true,
                        'is-invalid':
                          formDir.submitted &&
                          sku.controls[index].get('sku_id').invalid
                      }"
                      (change)="calculatePrices(skuModel)"
                      formControlName="sku_id"
                      placeholder="SKU"
                    >
                      <ng-option
                        [value]="product.sku_id"
                        *ngFor="let product of googleProducts"
                      >
                        {{ product.sku_name }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div
                    class="col-12 col-md-2 d-flex justify-content-end align-items-center mt-2 mt-md-0"
                  >
                    <img
                      (click)="deleteSKU(skuModel.value.sku_id)"
                      class="px-1 px-md-2 cursor-pointer btn_sku_delete"
                      src="./assets/img/delete-icon.svg"
                      alt="delete icon"
                    />
                    <button
                      type="button"
                      class="btn btn-toggle btn_sku_toggle ms-2"
                      [class.active]="skuModel.value.expanded"
                      (click)="expandSku(skuModel.value.sku_id)"
                    >
                      <img
                        [src]="
                          skuModel.value.expanded
                            ? './assets/img/expanded-icon.svg'
                            : './assets/img/expand-icon.svg'
                        "
                        alt="Toggle Image"
                        class="img-fluid"
                      />
                    </button>
                  </div>
                </div>
                <div *ngIf="skuModel.value.expanded">
                  <div
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="sku-container"
                  >
                    <div class="accordion-body p-0 mt-2">
                      <div [formGroupName]="index" class="d-flex py-3">
                        <label
                          class="form-label text-wrap col-3 col-md-2 mb-0 d-md-inline"
                        >
                          Amount
                        </label>
                        <input
                          type="number"
                          min="1"
                          [ngClass]="{
                            'is-invalid':
                              formDir.submitted &&
                              sku.controls[index].get('amount').invalid
                          }"
                          (change)="calculatePrices(skuModel)"
                          class="rounded-pill form-control col-9 col-md-10 flex-fill"
                          formControlName="amount"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-conatiner>

    <div
      class="d-flex flex-column flex-sm-row align-items-start justify-content-center mt-xl-2 pt-xl-4 pb-2 product-sans-light pt-2"
    >
      <input
        class="btn btn-primary small-text btn-color rounded-pill col-8 col-sm-5 col-xl-4 mb-2 mb-sm-0 p-1 p-xl-2 mx-1 mx-xl-4 fs-6"
        value="{{ saveText }}"
        (click)="saveOrder(false)"
        type="submit"
      />
      <input
        *ngIf="!currentUser.isExecutor"
        class="btn btn-primary small-text btn-color rounded-pill col-8 col-sm-5 col-xl-2 mb-2 mb-sm-0 p-1 p-xl-2 mx-1 mx-xl-4 fs-6"
        value="Save as draft"
        (click)="saveOrder(true)"
        type="submit"
      />
      <input
        class="btn btn-primary small-text btn-color rounded-pill col-8 col-sm-5 col-xl-2 mb-2 mb-sm-0 p-1 p-xl-2 mx-1 mx-xl-4 fs-6"
        value="Cancel"
        (click)="cancelOrder()"
      />
      <!--    <input
  class="btn btn-primary small-text btn-color rounded-pill col-2 col-xl-2 me-1 me-xl-4 p-1 p-xl-2 fs-6 align-self-end"
  value="Reject"
  type="button"
  data-bs-toggle="modal"
  data-bs-target="#rejectionModal"
/>
<div class="btn-group col-3 align-self-end">
  <button
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    class="btn btn-primary dropdown-toggle btn-color rounded-pill ps-4 text-start"
  >
    Place it as
  </button>
  <ul class="dropdown-menu">
    <li>
      <a class="dropdown-item text-white" href="#">Button</a>
    </li>
    <li>
      <a class="dropdown-item text-white" href="#">Button</a>
    </li>
  </ul>
</div> -->
    </div>
  </div>
</form>
