import { DatePipe } from '@angular/common';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode, PlanValues } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'lib-flex-customers',
  templateUrl: './flex-customers.component.html',
  styleUrls: ['./flex-customers.component.scss'],
})
export class FlexCustomersComponent implements OnInit {
  static flexPageToken: string;
  static _http: HttpClient;
  static _datePipe: DatePipe;
  static currentUser: UserModel;
  static _httpRequestService;
  static flexCustomers = [];
  static tempFlexCustomers = [];
  static flexCustomersUpdated = { flex_notes: [], dates: [] };
  plans = [];

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private datePipe: DatePipe,
    private httpRequestService: HttpRequestService
  ) {
    FlexCustomersComponent._http = http;
    FlexCustomersComponent._datePipe = datePipe;
    FlexCustomersComponent._httpRequestService = httpRequestService;
    FlexCustomersComponent.currentUser = localStorage.getLoggedinUserModel();
    FlexCustomersComponent.flexCustomers = [];
    FlexCustomersComponent.tempFlexCustomers = [];
  }

  async ngOnInit(): Promise<void> {
    FlexCustomersComponent.getFlexCUstomers();
    this.plans = await Utilities.planList(this.http, this.localStorage);
  }

  static async getFlexCUstomers() {
    FlexCustomersComponent.flexPageToken = '';
    FlexCustomersComponent.getFlexCUstomersSub();
  }

  static async getFlexCUstomersSub(toexport: boolean = false) {
    var prs = new HttpParams()
      .set(Unicode.WITHOUT_LOADER, '1')
      .set(Unicode.VIEW_ALL, FlexCustomersComponent.currentUser.canViewAll);
    var customerFilter = (<HTMLInputElement>(
      document.getElementById('txt-order-global-search')
    ))?.value;
    if (!Utilities.isNullOrEmpty(customerFilter))
      prs = prs.set('customerNamePrefix', customerFilter);
    if (!Utilities.isNullOrEmpty(FlexCustomersComponent.flexPageToken))
      prs = prs.set('pageToken', FlexCustomersComponent.flexPageToken);
    if (toexport) {
      await FlexCustomersComponent._http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.EXPORT_FLEX_CUSTOMERS_LIST, {
          params: prs,
        })
        .toPromise()
        .then(async (result: any) => {
          if (result && result.spreadsheetUrl) {
            window.open(result.spreadsheetUrl);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await FlexCustomersComponent._http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.FLEX_CUSTOMERS_LIST, {
          params: prs,
        })
        .toPromise()
        .then(async (result: any) => {
          if (result && result.success) {
            if (result.subscriptions) {
              FlexCustomersComponent.tempFlexCustomers.push(
                ...result.subscriptions
              );
              FlexCustomersComponent.tempFlexCustomers.forEach((element) => {
                if (element.child) {
                  element.child.forEach((subelt) => {
                    if (subelt.expiryDate)
                      subelt.expiryDate = new Date(subelt.expiryDate)
                        .toISOString()
                        .slice(0, 10);
                  });
                }
              });
              FlexCustomersComponent.flexCustomers =
                FlexCustomersComponent.tempFlexCustomers.filter(
                  (x) =>
                    x.child &&
                    x.child.find(
                      (y) =>
                        y.plan?.planName
                          .toUpperCase()
                          .trim()
                          .indexOf(PlanValues.FLEXIBLE) >= 0 &&
                        'active' === y.status.toLowerCase() &&
                        y.skuId
                          .toLowerCase()
                          .trim()
                          .indexOf(
                            Unicode.GOOGLE_DRIVE_SKU_ID.toLowerCase().trim()
                          ) < 0
                    )
                );
            }
            if (result.nextPageToken) {
              FlexCustomersComponent.flexPageToken = result.nextPageToken;
              this.getFlexCUstomersSub();
            }
          } else {
            console.log(result.message);
          }
        });
    }
  }

  selectExpiryDate(
    event,
    planCode: string,
    skuId: string,
    customerDomain: string
  ) {
    if (!Utilities.isNullOrEmpty(event)) {
      var index = FlexCustomersComponent.flexCustomersUpdated.dates.findIndex(
        (x) => x.domain == customerDomain && x.sku_id == skuId
      );
      if (index >= 0)
        FlexCustomersComponent.flexCustomersUpdated.dates.splice(index, 1);
      FlexCustomersComponent.flexCustomersUpdated.dates.push({
        domain: customerDomain,
        plan_id: this.plans.find(
          (x) => x.code.toLowerCase() == planCode.toLowerCase()
        )?._id,
        sku_id: skuId,
        expiry_date: event,
      });
    }
  }

  valuechange(i: number, domain: string, event) {
    if (!Utilities.isNullOrEmpty(event.target.value)) {
      var index =
        FlexCustomersComponent.flexCustomersUpdated.flex_notes.findIndex(
          (x) => x.domain == domain
        );
      if (index >= 0)
        FlexCustomersComponent.flexCustomersUpdated.flex_notes.splice(index, 1);
      FlexCustomersComponent.flexCustomersUpdated.flex_notes.push({
        domain: domain,
        note: event.target.value,
      });
    }
  }

  async saveFlexChanges() {
    var that = this;
    var params = new HttpParams();
    await this.http
      .post(
        Endpoint.GLOBAL_URL + '' + Endpoint.UPDATE_FLEX_DATA,
        FlexCustomersComponent.flexCustomersUpdated,
        { params: params }
      )
      .toPromise()
      .then((resp: any) => {
        console.log(resp);
        if (resp) {
          FlexCustomersComponent.flexCustomersUpdated = {
            flex_notes: [],
            dates: [],
          };
          alert('Saved');
        }
      });
  }

  expandInter(index) {
    var id = 'sku-collapse-' + index;
    var elt = document.getElementById(id);
    if (elt.classList.contains('show')) elt.classList.remove('show');
    else elt.classList.add('show');
  }

  async exportFlexList() {
    alert('You will receive an email with the sheet link once completed');
    FlexCustomersComponent.flexPageToken = null;
    FlexCustomersComponent.getFlexCUstomersSub(true);
  }
  get GetFlex() {
    return FlexCustomersComponent.flexCustomers;
  }
  get GetCurrentUser() {
    return FlexCustomersComponent.currentUser;
  }
}
