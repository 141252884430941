import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  effect,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CustomerRequirementsModel } from 'src/models/mongo/customer_requirements.model';
import { DomainModel } from 'src/models/mongo/domain.model';
import { EngineerRecommendationsModel } from 'src/models/mongo/engineer_recommendations.model';
import { SOWModel } from 'src/models/mongo/scope_of_work.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { CustomerInfoPopupComponent } from 'src/shared-components/customer-info-popup/customer-info-popup.component';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { OpportunityService } from '../../opportunity.service';
import { CustomerRequirementsService } from 'projects/customer-requirements/src/public-api';
import { EngineerRecommendationsService } from 'projects/engineer-recommendations/src/public-api';
import { SowService } from 'projects/sow/src/public-api';

@Component({
  selector: 'opportunity-solution-building-phase',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './opportunity-solution-building-phase.component.html',
  styleUrls: ['./opportunity-solution-building-phase.component.scss'],
})
export class OpportunitySolutionBuildingPhaseComponent implements OnInit {
  @HostBinding('class') class = 'h-100';
  @Input() solutionPhaseData: {
    customer_requirements: CustomerRequirementsModel;
    engineering_recommendations: EngineerRecommendationsModel;
    sow: SOWModel;
    is_psf: true;
    solution_building_time: '';
  };
  currentUser: UserModel;
  @Input() isGWS: boolean = true;
  @Input() opporunityID: string;
  @Input() skip: boolean;
  @Input() disabled: boolean;
  isAddedCustReqCollapsed: boolean = true;
  isManagerCustReqApprovalCollapsed: boolean = true;
  isSendingCustReqCollapsed: boolean = true;
  isAddedEngRecCollapsed: boolean = true;
  isManagerEngRecApprovalCollapsed: boolean = true;
  isSendingCustEngRecCollapsed: boolean = true;
  isCustApprovedCustReqCollapsed: boolean = true;
  isCustApprovedEngRecCollapsed: boolean = true;
  isCreatedSOWCollapsed: boolean = true;
  isManagerApprovedSOWCollapsed: boolean = true;
  isGPMApprovedSOWCollapsed: boolean = true;
  isSaleSOWPartCollapsed: boolean = true;
  isSendingCustSOWCollapsed: boolean = true;
  isCustApprovedSOWCollapsed: boolean = true;
  add: boolean = false;
  approve: boolean = false;
  send: boolean = false;
  withoutAction: boolean = false;
  @ViewChild('customerInfoPopup')
  customerInfoPopup: CustomerInfoPopupComponent;
  currentDomain: DomainModel = new DomainModel();
  @ViewChild('googlesowDoc') googlesowDocModal: ModalDirective;
  showSendToApprove: boolean = true;
  loginUsersData: any;
  @ViewChild('googleDocCustReq') googleDocCustReqModal: ModalDirective;
  @ViewChild('googleDocEngRecomdModal') googleDocEngRecomdModal: ModalDirective;
  googleDocData: any;
  @Output() skipEmitter = new EventEmitter<boolean>();
  sendToCust = null;
  oppService = inject(OpportunityService);
  customerInfoAdded = null;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private customerRequirementsService: CustomerRequirementsService,
    private engineerRecommendationsService: EngineerRecommendationsService,
    private sowService: SowService
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    var cstjson = this.localStorage.get('current customer');
    if (!Utilities.isNullOrEmpty(cstjson))
      this.currentDomain = JSON.parse(cstjson);
    if (this.currentDomain?.contact_person_first_name)
      this.currentDomain.contact_person_name = `${
        this.currentDomain.contact_person_first_name ?? ''
      } ${this.currentDomain.contact_person_last_name ?? ''}`;
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);

    effect(() => {
      if (Utilities.isNullOrEmpty(this.currentDomain?.domain)) {
        this.currentDomain = this.oppService.opportunity().domain;
        localStorage.save(
          'current customer',
          JSON.stringify(this.currentDomain)
        );
      }
      this.customerInfoAdded =
        this.oppService.opportunity().customer_info_added;
    });
  }
  ngOnInit(): void {
    console.log(this.solutionPhaseData);
  }
  // CUSTOMER REQ
  addCustomerRequirements() {
    var startDate = new Date();
    this.add = true;
    this.approve = false;
    var params = new HttpParams()
      .set(Unicode.CUSTOMER_NAME, this.currentDomain.organisation_name)
      .set(Unicode.IS_GCP, !this.isGWS);
    this.http
      .post(
        Endpoint.GLOBAL_URL + Endpoint.CREATE_CUSTOMER_REQUIREMENTS_GOOGLE_DOC,
        null,
        {
          params: params,
        }
      )
      .toPromise()
      .then((resp: any) => {
        if (resp?.doc_id) {
          this.solutionPhaseData.customer_requirements =
            new CustomerRequirementsModel();
          this.solutionPhaseData.customer_requirements.is_gcp = !this.isGWS;
          this.solutionPhaseData.customer_requirements.google_doc_id =
            resp.doc_id;
          this.solutionPhaseData.customer_requirements.googleDocUrl =
            'https://docs.google.com/document/d/' +
            this.solutionPhaseData.customer_requirements.google_doc_id +
            '/edit?usp=sharing&embed=true';
          this.solutionPhaseData.customer_requirements.googleDocUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.solutionPhaseData.customer_requirements.googleDocUrl
            );
          this.add = true;
          this.googleDocData = {
            module: 'customer requirements',
            customer_domain: this.currentDomain.domain,
            customer_org: this.currentDomain.organisation_name,
            domain_rfid: this.currentDomain._id,
            googleDocUrl:
              this.solutionPhaseData.customer_requirements.googleDocUrl,
            startDate: startDate,
          };
          this.googleDocCustReqModal.show();
          this.googleDocCustReqModal.onHide.subscribe((res) => {
            this.googleDocData = null;
          });
        } else {
          alert(resp.message);
        }
      });
  }

  createCustReq(model: CustomerRequirementsModel) {
    this.solutionPhaseData.customer_requirements = model;
    this.googleDocCustReqModal.hide();
  }

  approveCustReq(model: CustomerRequirementsModel) {
    this.solutionPhaseData.customer_requirements = model;
    this.googleDocCustReqModal.hide();
  }

  reviewCustReq() {
    // this.add = false;
    // if (
    //   !Utilities.isNullOrEmpty(
    //     this.solutionPhaseData?.customer_requirements?.google_doc_id
    //   )
    // ) {
    //   if (
    //     this.currentUser._id ==
    //       this.solutionPhaseData.customer_requirements.created_by_rfid &&
    //     this.solutionPhaseData.customer_requirements.draft == true
    //   ) {
    //     this.showSendToApprove = true;
    //   } else this.showSendToApprove = false;
    // }

    this.solutionPhaseData.customer_requirements.googleDocUrl =
      'https://docs.google.com/document/d/' +
      this.solutionPhaseData.customer_requirements.google_doc_id +
      '/edit?usp=sharing&embed=true';
    this.solutionPhaseData.customer_requirements.googleDocUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.solutionPhaseData.customer_requirements.googleDocUrl
      );
    //
    this.googleDocData = {
      module: 'customer requirements',
      customer_domain: this.currentDomain.domain,
      customer_org: this.currentDomain.organisation_name,
      domain_rfid: this.currentDomain._id,
      googleDocUrl: this.solutionPhaseData.customer_requirements.googleDocUrl,
      createdUser: {
        name: this.solutionPhaseData.customer_requirements.user
          .created_user_name,
        role: this.solutionPhaseData.customer_requirements.user
          .created_user_role,
        date: this.solutionPhaseData.customer_requirements.created_at,
        text: 'Added Customer Requirements',
        img: this.solutionPhaseData.customer_requirements.user.created_user_img,
      },
      approval_phases:
        this.solutionPhaseData.customer_requirements.approval_phases,
    };
    if (this.solutionPhaseData.customer_requirements.approval_phases) {
      this.googleDocData.managerApprovalPart =
        this.solutionPhaseData.customer_requirements.approval_phases
          .slice()
          .reverse()
          .find(
            (x) =>
              x.role &&
              x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
          );
      this.googleDocData.sendingToCustomerPart =
        this.solutionPhaseData.customer_requirements.approval_phases
          .slice()
          .reverse()
          .find(
            (x) =>
              x.role && x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
          );
    }
    if (
      this.googleDocData.sendingToCustomerPart &&
      !this.googleDocData.sendingToCustomerPart.date
    )
      this.googleDocData.sendingToCustomerPart.current = true;
    if (
      this.googleDocData.managerApprovalPart &&
      !this.googleDocData.managerApprovalPart.date
    )
      this.googleDocData.managerApprovalPart.current = true;
    this.googleDocCustReqModal.show();
    this.googleDocCustReqModal.onHide.subscribe((res) => {
      this.googleDocData = null;
    });
  }

  sendDraftCustReqForApproval(model: CustomerRequirementsModel) {
    this.solutionPhaseData.customer_requirements = model;
    this.googleDocCustReqModal.hide();
  }

  sendCustReqToCustPopup() {
    console.log('solutionPhaseData:' + this.solutionPhaseData);
    this.sendToCust = 'CR';
    this.customerInfoPopup.show();
  }
  // ENGINEER RECOM
  addEngRecommendations() {
    this.add = true;
    var startDate = new Date();
    this.approve = false;
    var params = new HttpParams()
      .set(Unicode.CUSTOMER_NAME, this.currentDomain.organisation_name)
      .set(Unicode.IS_GCP, !this.isGWS);
    this.http
      .post(
        Endpoint.GLOBAL_URL +
          Endpoint.CREATE_ENGINEER_RECOMMENDATION_GOOGLE_DOC,
        null,
        {
          params: params,
        }
      )
      .toPromise()
      .then((resp: any) => {
        if (resp?.doc_id) {
          this.solutionPhaseData.engineering_recommendations =
            new EngineerRecommendationsModel();
          this.solutionPhaseData.engineering_recommendations.is_gcp =
            !this.isGWS;
          this.solutionPhaseData.engineering_recommendations.google_doc_id =
            resp.doc_id;
          this.solutionPhaseData.engineering_recommendations.googleDocUrl =
            'https://docs.google.com/document/d/' +
            this.solutionPhaseData.engineering_recommendations.google_doc_id +
            '/edit?usp=sharing&embed=true';
          this.solutionPhaseData.engineering_recommendations.googleDocUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.solutionPhaseData.engineering_recommendations.googleDocUrl
            );
          this.add = true;
          this.googleDocData = {
            module: 'engineering recommendations',
            customer_domain: this.currentDomain.domain,
            domain_rfid: this.currentDomain._id,
            customer_org: this.currentDomain.organisation_name,
            googleDocUrl:
              this.solutionPhaseData.engineering_recommendations.googleDocUrl,
            startDate: startDate,
          };
          this.googleDocEngRecomdModal.show();
          this.googleDocEngRecomdModal.onHide.subscribe((res) => {
            this.googleDocData = null;
          });
        } else {
          alert(resp.message);
        }
      });
  }

  createEngRec(model: EngineerRecommendationsModel) {
    this.solutionPhaseData.engineering_recommendations = model;
    this.googleDocEngRecomdModal.hide();
  }

  approveEngRec(model: EngineerRecommendationsModel) {
    this.solutionPhaseData.engineering_recommendations = model;
    this.googleDocEngRecomdModal.hide();
  }

  reviewEngRecom() {
    // if (
    //   this.currentUser._id ==
    //     this.solutionPhaseData.engineering_recommendations.created_by_id &&
    //   this.solutionPhaseData.engineering_recommendations.draft == true
    // ) {
    //   this.add = false;
    //   this.showSendToApprove = true;
    // } else if (
    //   this.currentUser._id ==
    //   this.solutionPhaseData.engineering_recommendations.created_by_id
    // ) {
    //   this.add = false;
    //   this.showSendToApprove = false;
    //   this.approve = false;
    // } else if (
    //   this.currentUser._id ==
    //     this.solutionPhaseData.engineering_recommendations.approval_phases[0]
    //       .id &&
    //   !this.solutionPhaseData.engineering_recommendations.draft
    // ) {
    //   this.add = false;
    //   this.showSendToApprove = false;
    //   this.approve = true;
    // } else {
    //   this.add = false;
    //   this.showSendToApprove = false;
    //   this.approve = false;
    // }
    this.solutionPhaseData.engineering_recommendations.googleDocUrl =
      'https://docs.google.com/document/d/' +
      this.solutionPhaseData.engineering_recommendations.google_doc_id +
      '/edit?usp=sharing&embed=true';
    this.solutionPhaseData.engineering_recommendations.googleDocUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.solutionPhaseData.engineering_recommendations.googleDocUrl
      );
    this.googleDocData = {
      module: 'engineering recommendations',
      customer_domain: this.currentDomain.domain,
      domain_rfid: this.currentDomain._id,
      customer_org: this.currentDomain.organisation_name,
      googleDocUrl:
        this.solutionPhaseData.engineering_recommendations.googleDocUrl,
      createdUser: {
        name: this.solutionPhaseData.engineering_recommendations
          .created_by_name,
        role: 'PreSales',
        date: this.solutionPhaseData.engineering_recommendations.created_at,
        text: 'Added customer requirements',
        img: this.solutionPhaseData.engineering_recommendations.created_by_img,
      },
      approval_phases:
        this.solutionPhaseData.engineering_recommendations.approval_phases,
    };
    this.googleDocEngRecomdModal.show();
    this.googleDocEngRecomdModal.onHide.subscribe((res) => {
      this.googleDocData = null;
    });
  }

  sendDraftEngRecomForApproval(model: EngineerRecommendationsModel) {
    this.solutionPhaseData.engineering_recommendations = model;
    this.googleDocEngRecomdModal.hide();
  }

  sendEngRecToCustPopup() {
    this.sendToCust = 'ER';
    this.customerInfoPopup.show();
  }

  //SOW
  createSOWGoogleDoc() {
    var startDate = new Date();
    var params = new HttpParams()
      .set(Unicode.CUSTOMER_NAME, this.currentDomain.organisation_name)
      .set(Unicode.IS_GCP, !this.isGWS)
      .set(Unicode.IS_PSF, this.solutionPhaseData?.is_psf);
    this.http
      .post(Endpoint.GLOBAL_URL + Endpoint.CREATE_SOW_GOOGLE_DOC, null, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp?.doc_id) {
          this.solutionPhaseData.sow = new SOWModel();
          this.solutionPhaseData.sow.google_doc_id = resp.doc_id;
          this.solutionPhaseData.sow.googleDocUrl =
            'https://docs.google.com/document/d/' +
            this.solutionPhaseData.sow.google_doc_id +
            '/edit?usp=sharing&embed=true';
          this.solutionPhaseData.sow.googleDocUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.solutionPhaseData.sow.googleDocUrl
            );
          this.add = true;
          this.googleDocData = {
            module: 'sow',
            customer_domain: this.currentDomain.domain,
            customer_org: this.currentDomain.organisation_name,
            domain_rfid: this.currentDomain._id,
            googleDocUrl: this.solutionPhaseData.sow.googleDocUrl,
            startDate: startDate,
          };
          this.googlesowDocModal.show();
          this.googlesowDocModal.onHide.subscribe((res) => {
            this.googleDocData = null;
          });
        } else {
          alert(resp.message);
        }
      });
  }

  createSOW(sow: SOWModel) {
    this.solutionPhaseData.sow = sow;
    this.googlesowDocModal.hide();
  }

  approveSOWPopup() {
    this.showSendToApprove = false;
    this.add = false;
    this.approve = true;
    this.solutionPhaseData.sow.googleDocUrl =
      'https://docs.google.com/document/d/' +
      this.solutionPhaseData.sow.google_doc_id +
      '/edit?usp=sharing&embed=true';
    this.solutionPhaseData.sow.googleDocUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.solutionPhaseData.sow.googleDocUrl
      );
    //
    this.googleDocData = {
      module: 'sow',
      customer_domain: this.currentDomain.domain,
      customer_org: this.currentDomain.organisation_name,
      domain_rfid: this.currentDomain._id,
      googleDocUrl: this.solutionPhaseData.sow.googleDocUrl,
      createdUser: {
        name: this.solutionPhaseData.sow.user_sow.created_user_name,
        role: this.solutionPhaseData.sow.user_sow.created_user_role,
        date: this.solutionPhaseData.sow.created_at,
        text: 'Created SOW',
        img: this.solutionPhaseData.sow.user_sow.created_user_img,
      },
      approval_phases: this.solutionPhaseData?.sow?.approval_phases,
    };
    this.setSowPhasesData();
    this.googlesowDocModal.show();
    this.googlesowDocModal.onHide.subscribe((res) => {
      this.googleDocData = null;
    });
  }
  approveSOW(sow: SOWModel) {
    this.solutionPhaseData.sow = sow;
    this.googlesowDocModal.hide();
  }
  async reviewSOW() {
    this.solutionPhaseData.sow.googleDocUrl =
      'https://docs.google.com/document/d/' +
      this.solutionPhaseData.sow.google_doc_id +
      '/edit?usp=sharing&embed=true';
    this.solutionPhaseData.sow.googleDocUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.solutionPhaseData.sow.googleDocUrl
      );
    this.googleDocData = {
      module: 'sow',
      customer_domain: this.currentDomain.domain,
      customer_org: this.currentDomain.organisation_name,
      domain_rfid: this.currentDomain._id,
      googleDocUrl: this.solutionPhaseData.sow.googleDocUrl,
      createdUser: {
        name: this.solutionPhaseData.sow.user_sow.created_user_name,
        role: this.solutionPhaseData.sow.user_sow.created_user_role,
        date: this.solutionPhaseData.sow.created_at,
        text: 'Created SOW',
        img: this.solutionPhaseData.sow.user_sow.created_user_img,
      },
      approval_phases: this.solutionPhaseData?.sow?.approval_phases,
    };
    this.setSowPhasesData();
    this.googlesowDocModal.show();
    this.googlesowDocModal.onHide.subscribe((res) => {
      this.googleDocData = null;
    });
  }

  sendDraftSOWForApproval(sow: SOWModel) {
    this.solutionPhaseData.sow = sow;
    this.googlesowDocModal.hide();
  }

  sendSOWToCustPopup() {
    this.sendToCust = 'SOW';
    this.customerInfoPopup.show();
  }
  sendToCust_back(custInfo) {
    if (this.sendToCust == 'SOW') {
      this.sendSOWToCust(custInfo);
    } else if (this.sendToCust == 'ER') {
      this.sendEngRecoToCust(custInfo);
    } else if (this.sendToCust == 'CR') {
      this.sendCustReqToCust(custInfo);
    }
  }
  async sendSOWToCust(custInfo) {
    var that = this;
    await this.sowService
      .sendSOWToCustpmer(
        this.solutionPhaseData?.sow,
        custInfo,
        this.currentUser,
        this.opporunityID
      )
      .then((result) => {
        if (result) {
          this.solutionPhaseData.sow = result;
          this.googlesowDocModal.hide();
          this.customerInfoPopup.hide();
        }
      });
  }

  async sendCustReqToCust(custInfo) {
    var that = this;
    await this.customerRequirementsService
      .sendCustomerRequirementsToCust(
        this.solutionPhaseData?.customer_requirements,
        custInfo,
        this.currentUser,
        this.opporunityID
      )
      .then((result) => {
        if (result) {
          this.solutionPhaseData.customer_requirements = result;
          this.googleDocCustReqModal.hide();
          this.customerInfoPopup.hide();
        }
      });
  }

  async sendEngRecoToCust(custInfo) {
    var that = this;
    await this.engineerRecommendationsService
      .sendEngineerRecommendationsToCustpmer(
        this.solutionPhaseData?.engineering_recommendations,
        custInfo,
        this.currentUser,
        this.opporunityID
      )
      .then((result) => {
        if (result) {
          this.solutionPhaseData.engineering_recommendations = result;
          this.googleDocEngRecomdModal.hide();
          this.customerInfoPopup.hide();
        }
      });
  }

  setSowPhasesData() {
    if (this.solutionPhaseData?.sow?.approval_phases) {
      if (!this.googleDocData) {
        this.googleDocData = {
          module: 'sow',
          customer_domain: this.currentDomain.domain,
          customer_org: this.currentDomain.organisation_name,
          domain_rfid: this.currentDomain._id,
          googleDocUrl: this.solutionPhaseData.sow.googleDocUrl,
          createdUser: {
            name: this.solutionPhaseData.sow.user_sow.created_user_name,
            role: this.solutionPhaseData.sow.user_sow.created_user_role,
            date: this.solutionPhaseData.sow.created_at,
            text: 'Created SOW',
            img: this.solutionPhaseData.sow.user_sow.created_user_img,
          },
          approval_phases: this.solutionPhaseData?.sow?.approval_phases,
        };
      }
      this.googleDocData.managerApprovalPart =
        this.solutionPhaseData.sow.approval_phases
          .slice()
          .reverse()
          .find(
            (x) =>
              x.role &&
              x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
          );
      // this.googleDocData.sendingToCustomerPart =
      //   this.solutionPhaseData.sow.approval_phases
      //     .slice()
      //     .reverse()
      //     .find(
      //       (x) =>
      //         x.role && x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
      //     );
      // if (
      //   this.googleDocData.sendingToCustomerPart &&
      //   !this.googleDocData.sendingToCustomerPart.date
      // )
      //   this.googleDocData.sendingToCustomerPart.current = true;
      if (
        this.googleDocData.managerApprovalPart &&
        !this.googleDocData.managerApprovalPart.date
      )
        this.googleDocData.managerApprovalPart.current = true;
      this.solutionPhaseData.sow.managerApprovalPart =
        this.googleDocData.managerApprovalPart;
    }
  }

  skipStep(e) {
    var skipped = e.target.checked;
    if (!Utilities.isNullOrEmpty(this.opporunityID)) {
      this.http
        .put(
          Endpoint.GLOBAL_URL +
            Endpoint.SKIP_OPP_STEP +
            '/' +
            this.opporunityID,
          {
            skipped: skipped,
            module: 'SB',
          }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp?.success == true) {
            this.skipEmitter.emit(skipped);
          } else {
            alert(Unicode.INTERNAL_SERVER_ERROR);
          }
        });
    } else this.skipEmitter.emit(skipped);
  }
}
