import { Utilities } from 'src/shared/utilities';
import { BaseModel } from '../mongo/base.model';

export class DomainModel extends BaseModel {
  _id: string;
  customer_rfid: string;
  parent_domain_rfid: string;
  type: string;
  contact_person_first_name: string;
  contact_person_last_name: string;
  contact_person_email: string = null;
  domain: string = null;
  organisation_name: string = null;
  alternate_email: string;
  phone: any = null;
  city: string = null;
  street: string = null;
  state: string = null;
  country: any;
  postal_code: string = null;
  deleted: boolean;
  auth_token: string;
  is_offline: boolean;
  channel_name: string;
  channel_name_gcp: string;
  employees_nbr: number;
  industry: string;
  customer_sector: string;
  flex_notes: string;
  user_ids = [];
  address: string = null;
  is_inherited: string;
  created_by_rfid: string;
  is_gws: boolean;
  //
  customer_api_id: string;
  customer_local_id: string;
  customer_created_date: string;
  contact_person_name: string;
  country_name: string;
  creation_date_string: string;
  address_line1: string;
  total_users: number;
  account_manager: string;
  account_manager_image_url: string;
  skus = [];
  subDomains: [];
  // manager_user_email: string;

  constructor(json?: any) {
    super();
    if (json) {
      this._id = json._id;
      this.customer_rfid = json.customer_rfid;
      this.parent_domain_rfid = json.parent_domain_rfid;
      this.type = json.type;
      this.contact_person_first_name = json.contact_person_first_name;
      this.contact_person_last_name = json.contact_person_last_name;
      this.contact_person_email = json.contact_person_email;
      this.contact_person_name = json.contact_person_name;
      this.domain = json.domain;
      this.organisation_name = json.organisation_name;
      this.alternate_email = json.alternate_email;
      this.phone = json.phone;
      this.city = json.city;
      this.street = json.street;
      this.state = json.state;
      this.country = json.country;
      this.postal_code = json.postal_code;
      this.deleted = json.deleted;
      this.auth_token = json.auth_token;
      this.is_offline = json.is_offline;
      this.channel_name = json.channel_name;
      this.channel_name_gcp = json.channel_name_gcp;
      this.employees_nbr = json.employees_nbr;
      this.industry = json.industry;
      this.customer_sector = json.customer_sector;
      this.flex_notes = json.flex_notes;
      this.user_ids = json.user_ids;
      this.address = json.address;
      this.is_inherited = json.is_inherited;
      this.customer_api_id = json.customer_api_id;
      this.customer_local_id = json.customer_local_id;
      this.customer_created_date = json.customer_created_date;
      this.creation_date_string = json.creation_date_string;
      this.contact_person_email = json.contact_person_email;
      this.address_line1 = json.address_line1;
      this.total_users = json.total_users;
      this.account_manager = json.account_manager;
      this.account_manager_image_url = json.account_manager_image_url;
      this.skus = json.skus;
      this.subDomains = json.subDomains;
      this.is_gws = json.is_gws;
    }
  }

  getCountryName() {
    return this.country?.name ?? '';
  }

  getContactPersonName() {
    return this.contact_person_first_name + ' ' + this.contact_person_last_name;
  }

  isGWSDomainValid() {
    return (
      !Utilities.isNullOrEmpty(this.domain) &&
      !Utilities.isNullOrEmpty(this.organisation_name) &&
      !Utilities.isNullOrEmpty(this.country) &&
      !Utilities.isNullOrEmpty(this.organisation_name) &&
      !Utilities.isNullOrEmpty(this.alternate_email) &&
      (!Utilities.isNullOrEmpty(this.auth_token) ||
        !this.alternate_email.includes('@') ||
        this.alternate_email.split('@')[1] !== this.domain) &&
      !Utilities.isNullOrEmpty(this.state) &&
      !Utilities.isNullOrEmpty(this.city) &&
      !Utilities.isNullOrEmpty(this.street) &&
      !Utilities.isNullOrEmpty(this.customer_sector) &&
      !Utilities.isNullOrEmpty(this.industry) &&
      ((!Utilities.isNullOrEmpty(this.contact_person_first_name) &&
        !Utilities.isNullOrEmpty(this.contact_person_last_name)) ||
        !Utilities.isNullOrEmpty(this.contact_person_name)) &&
      !Utilities.isNullOrEmpty(this.contact_person_email) &&
      (!Utilities.isNullOrEmpty(this.auth_token) ||
        !this.contact_person_email.includes('@') ||
        this.contact_person_email.split('@')[1] !== this.domain) &&
      !Utilities.isNullOrEmpty(this.phone) &&
      !Utilities.isNullOrEmpty(this.employees_nbr)
    );
  }
}

export class CountryModel {
  name: string;
  iso2: string;
  iso3: string;

  constructor(name?: string, code?: string) {
    this.name = name;
    this.iso2 = code;
  }
}
