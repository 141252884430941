<main
  class="container-fluid px-2 w-100 d-flex flex-column justify-content-between"
>
  <section class="box-shadow bg-white rounded-5 p-2 p-md-4 mx-0 h-95">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
        <a (click)="cancelOrder()"
          ><img
            src="./assets/img/back-icon.svg"
            class="back-icon cursor-pointer"
            alt="back icon" /></a
        >Customer: {{ domain.organisation_name }}
      </h1>
      <input
        class="btn btn-primary small-text btn-color rounded-pill col-2 p-1 p-xl-2 fs-6"
        value="Edit"
        (click)="editCustomer()"
        type="button"
        required
      />
    </div>
    <div class="mb-3 mt-2">
      <h2 class="h6 border-bottom pb-3">Customer Details</h2>
      <div class="row">
        <p class="col-6 col-lg-3">Domain:{{ domain.domain }}</p>
        <p class="col-6 col-lg-3">
          Organization:{{ domain.organisation_name }}
        </p>
        <p
          class="col-6 col-lg-3"
          *ngIf="
            domain.contact_person_name && domain.contact_person_name !== ' '
          "
        >
          Contact Person:{{ domain.contact_person_name }}
        </p>
        <p class="col-6 col-lg-3">ID:{{ domain.customer_local_id }}</p>
      </div>
    </div>
    <ul
      class="nav nav-tabs d-flex flex-row pe-3 pe-lg-0 small-text border-0 h-fit-content mb-2"
      id="customers_tabs"
    >
      <li
        [routerLinkActive]="['active-status']"
        routerLink="subscriptions"
        [routerLinkActiveOptions]="{ exact: true }"
        class="nav-item col-3 text-center order-status cursor-pointer rounded-start"
      >
        <a class="nav-link border-0 product-sans-medium">Subscriptions</a>
      </li>
      <li
        [routerLinkActive]="['active-status']"
        routerLink="orders"
        [routerLinkActiveOptions]="{ exact: true }"
        class="nav-item col-3 text-center order-status cursor-pointer"
      >
        <a class="nav-link border-0 product-sans-medium">Orders</a>
      </li>
      <li
        routerLink="deals"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="['active-status']"
        class="nav-item col-3 text-center order-status cursor-pointer"
      >
        <a class="nav-link border-0 product-sans-medium">Deals</a>
      </li>
      <li
        routerLink="entitlements"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="['active-status']"
        class="nav-item col-3 text-center order-status cursor-pointer rounded-end"
      >
        <a class="nav-link border-0 product-sans-medium">GCP Services</a>
      </li>
    </ul>
    <div>
      <router-outlet></router-outlet>
    </div>
  </section>
</main>
