<div class="">
  <div
    class="mb-2 d-flex align-items-center justify-content-between form-floating"
  >
    <input
      [(ngModel)]="createdBillingData.billingAccountName"
      type="text"
      class="form-control rounded-pill form-control-sm"
      id="billing_account_name"
      placeholder="Billing Account Name"
      required
    />
    <label
      for="billing_account_name"
      class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
    >
      Billing Account Name
    </label>
  </div>

  <h2 class="fs-6 mt-4 border-bottom">Select SKU</h2>
  <div
    class="mb-2 d-flex align-items-center justify-content-between form-floating"
  >
    <input
      [(ngModel)]="createdBillingData.searchSku"
      type="text"
      class="form-control rounded-pill form-control-sm"
      id="search_sku"
      placeholder="Search SKU"
    />
    <label
      for="search_sku"
      class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
    >
      Search SKU
    </label>
  </div>

  <h2 class="smaller-text mt-4">Available SKUs</h2>
  <div *ngFor="let sku of gcpSkus">
    <label
      class="col-12 border border-primary rounded-3 d-flex justify-content-between p-2 px-4 google-cloud-divison cursor-pointer"
    >
      <div class="d-flex gap-3">
        <img src="{{ sku.logo }}" alt="google cloud icon" />
        <div class="d-flex flex-column gap-2">
          <span class="product-sans-medium">{{ sku.name }}</span>
          <span class="smaller-text">{{ sku.name }}</span>
        </div>
      </div>
      <input
        type="checkbox"
        [(ngModel)]="sku.isSelected"
        class="border-primary"
      />
    </label>
  </div>

  <!-- <h1 class="fs-5 product-sans border-bottom mt-3">Select Payment Plan</h1>

  <ng-select
    id="floatingSelect"
    [clearable]="true"
    [(ngModel)]="createdBillingData.mainBillingAccount"
    name="paymentPlan"
    [searchable]="true"
    class="mt-2 form-select p-0 rounded-pill"
    appendTo="body"
  >
    <ng-option *ngFor="let billing of mainBilling" [value]="billing">
      {{ billing.name }}
    </ng-option>
  </ng-select> -->

  <div *ngFor="let plan of gcpPlans; let i = index">
    <label
      class="col-12 border border-primary rounded-3 d-flex justify-content-between p-2 px-4 google-cloud-divison cursor-pointer mt-3"
    >
      <div class="d-flex flex-column gap-1 col-9">
        <span class="product-sans-medium">{{ plan.name }} Plan</span>
        <span class="smaller-text">{{ plan?.description }}</span>
      </div>
      <input
        type="checkbox"
        [(ngModel)]="plan.isSelected"
        class="border-primary"
      />
    </label>
  </div>

  <h2 class="smaller-text mt-4">Credits</h2>
  <input
    [(ngModel)]="createdBillingData.amount"
    type="number"
    class="form-control rounded-pill form-control-sm"
    id="amount"
    placeholder="Amount"
  />

  <div class="row mt-4">
    <msp-popup [opportunityRfId]="opportunityID" [canEdit]="false"></msp-popup>
  </div>

  <div class="text-end">
    <button
      type="button"
      class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
      (click)="saveBillingAccount()"
    >
      Create
    </button>
  </div>
</div>
