import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomainModel } from 'src/models/mongo/domain.model';
import { GCPProjectModel } from 'src/models/mongo/gcp_project.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { LinkProjectPopupComponent } from 'src/shared-components/link-project-popup/link-project-popup.component';
import { NewProjectModalComponent } from 'src/shared-components/new-project-modal/new-project-modal.component';
import { RejectionPopupComponent } from 'src/shared-components/rejection-popup/rejection-popup.component';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'opportunity-order-phase',
  templateUrl: './opportunity-order-phase.component.html',
  styleUrls: ['./opportunity-order-phase.component.scss'],
})
export class OpportunityOrderPhaseComponent implements OnInit {
  @HostBinding('class') class = 'h-100';
  @Input() orderPhaseData: any = {
    user_order: null,
    order_time: '',
    gcp_project: null,
    existing_project: null,
    credit: null,
    billing_budget: null,
    order: null,
    link_project_billing: null,
  };
  currentUser: UserModel;
  @Input() isGWS: boolean = true;
  @Input() opporunityID: string;
  @Input() skip: boolean;
  @Input() disabled: boolean;
  @Input() dealType: string;
  @ViewChild('projCreationOptionsModal')
  projCreationOptionsModal: ModalDirective;
  @ViewChild('existingProjModal')
  existingProjModal: ModalDirective;
  @ViewChild('orderNewProjectModal')
  orderNewProjectModal: NewProjectModalComponent;
  @ViewChild('linkProjectModal')
  linkProjectModal: LinkProjectPopupComponent;
  isGcpPrjCreCollapsed: boolean = true;
  isGcpPrjLinkedCollapsed: boolean = true;
  isCreditCreCollapsed: boolean = true;
  isBudgetCreCollapsed: boolean = true;
  isOrderCreCollapsed: boolean = true;
  isManagerApprovedOrdCollapsed: boolean = true;
  isGPMApprovedOrdrCollapsed: boolean = true;
  isExecutionCollapsed: boolean = true;
  currentCustomer: DomainModel;
  loginUsersData: any;
  add: boolean = false;
  approve: boolean = false;
  showSendToApprove: boolean = true;
  @ViewChild('rejectproposalPopupModal')
  rejectproposalPopupModal: RejectionPopupComponent;
  billingAccountList = [];
  allProjects: GCPProjectModel[] = [];
  existingProjectSelected;
  @ViewChild('budgetCreModal')
  budgetCreationModal: ModalDirective;
  billingRfId: string;
  viewOrderClicked: boolean = false;
  @Output() skipEmitter = new EventEmitter<boolean>();
  projectStartDate: Date;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (!Utilities.isNullOrEmpty(cstjson))
      this.currentCustomer = JSON.parse(cstjson);
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
  }

  ngOnInit(): void {}

  async createProOptions() {
    this.projectStartDate = new Date();
    this.projCreationOptionsModal.show();
  }

  async useExistingPro() {
    await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.LIST_OF_PROJECTS)
      .toPromise()
      .then(async (resp: any) => {
        if (resp?.list) this.allProjects = resp.list ?? [];
      });
    this.allProjects = this.allProjects.sort((a, b) => {
      return <any>new Date(b.createTime) - <any>new Date(a.createTime);
    });
    this.existingProjModal.show();
  }

  selectProj() {
    var params = new HttpParams()
      .set('nb', this.existingProjectSelected.number)
      .set('opp_rfid', this.opporunityID)
      .set(
        'start_date',
        this.datePipe.transform(
          this.projectStartDate,
          'yyyy-MM-dd HH:mm',
          'UTC/GMT'
        )
      );
    this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.SELECT_CONSOLE_PROJECT, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp?.rfid) {
          this.orderPhaseData = {
            existing_project: true,
            gcp_project: {
              _id: resp.rfid,
              name: resp.name,
              number: resp.number,
              created_date_string: resp.date,
              created_user_name: this.currentUser.user_name,
              created_at: new Date(),
            },
          };
          this.existingProjModal.hide();
          this.projCreationOptionsModal.hide();
        } else {
          alert(resp.message);
        }
      });
  }
  createNewProj() {
    this.orderNewProjectModal.show();
  }

  createProject(data) {
    if (data) {
      this.orderPhaseData = {
        existing_project: false,
        gcp_project: {
          _id: data._id,
          name: data.name,
          number: data.number,
          created_date_string: data.created_date_string,
          created_user_name: data.created_user_name,
          consumed_time: data.consumed_time,
          created_at: new Date(),
        },
      };
      this.orderNewProjectModal.hide();
      this.existingProjModal.hide();
      this.projCreationOptionsModal.hide();
    }
  }
  linkOrderProjectPopup() {
    this.linkProjectModal.show();
  }

  linkingDone(data) {
    if (data) {
      this.orderPhaseData.link_project_billing = data.link;
      this.orderPhaseData.billing_budget = data.budget;
      this.localStorage.save(
        Unicode.CURRENT_BUDGET,
        JSON.stringify(this.orderPhaseData.billing_budget)
      );
      this.orderPhaseData.credit = data.credit;
    }
  }

  createBudgetPopup() {
    this.billingRfId =
      this.orderPhaseData.link_project_billing.billing_account_rfid;
    this.localStorage.save(
      Unicode.CURRENT_BUDGET,
      JSON.stringify(this.orderPhaseData.billing_budget)
    );
    this.budgetCreationModal.show();
    // this.budgetCreationModal.onHide.subscribe(() => {
    //   this.localStorage.remove(Unicode.CURRENT_BUDGET);
    // });
  }
  createBudget(created) {
    if (created) {
      if (!this.orderPhaseData.billing_budget) {
        created.created_at = new Date();
        created.created_user_name = this.currentUser.user_name;
      }
      this.orderPhaseData.billing_budget = created;
      this.localStorage.save(Unicode.CURRENT_BUDGET, JSON.stringify(created));
      this.budgetCreationModal.hide();
    }
  }

  skipStep(e) {
    var skipped = e.target.checked;
    if (!Utilities.isNullOrEmpty(this.opporunityID)) {
      this.http
        .put(
          Endpoint.GLOBAL_URL +
            Endpoint.SKIP_OPP_STEP +
            '/' +
            this.opporunityID,
          {
            skipped: skipped,
            module: 'order',
          }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp?.success == true) {
            this.skipEmitter.emit(skipped);
          } else {
            alert(Unicode.INTERNAL_SERVER_ERROR);
          }
        });
    } else this.skipEmitter.emit(skipped);
  }
}
