import { BaseModel } from '../base.model';
import { DomainModel } from './domain.model';

export class CustomerModel extends BaseModel {
  _id: string;
  user_ids = [];
  local_id: string;
  api_id: string;
  credentials: any;
  domains: DomainModel[] = [];
  main_domain: DomainModel;
  deleted: boolean;
  customer_created_date: string;

  //
  contact_person_name: string;
  total_users: number;
  account_manager: string;
  account_manager_image_url: string;
  skus = [];
  mainDomaiRfid: string;
  isExecuted: boolean;

  constructor() {
    super();
  }
}

export class CountryModel {
  name: string;
  iso2: string;
  iso3: string;

  constructor(name?: string, code?: string) {
    this.name = name;
    this.iso2 = code;
  }
}
