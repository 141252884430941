import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainModel } from 'src/models/mongo/domain.model';
import { EngineerRecommendationsModel } from 'src/models/mongo/engineer_recommendations.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { EngineerRecommendationsService } from '../../engineer-recommendations.service';

@Component({
  selector: 'engineer-recommendations-details',
  templateUrl: './engineer-recommendations-details.component.html',
  styleUrls: ['./engineer-recommendations-details.component.scss'],
})
export class EngineerRecommendationsDetailsComponent
  implements OnInit, AfterViewInit
{
  currentUser: UserModel;
  model: EngineerRecommendationsModel = new EngineerRecommendationsModel();
  title: string = 'Create Engineer Recommendations';
  googleDocData: any;
  currentDomain: DomainModel = new DomainModel();
  loginUsersData: any;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private engineerRecommendationsService: EngineerRecommendationsService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(async (routeParams) => {
      const state = history.state;
      if (state?.erModel) this.model = state.erModel;
      else if (routeParams['Id']) {
        //EMAIL LINK CASE
        await this.http
          .get(
            Endpoint.GLOBAL_URL +
              Endpoint.GET_ENGINEER_RECOMMENDATION_BY_ID +
              routeParams['Id']
          )
          .toPromise()
          .then((resp: any) => {
            if (resp) {
              this.model = resp;
            }
          });
      }
      this.model = Object.assign(
        new EngineerRecommendationsModel(),
        this.model
      );
      if (this.model?._id) {
        this.title = 'Engineer Recommendations';
        this.googleDocData = {
          module: 'Engineer Recommendations',
          customer_domain: this.model.domain.domain,
          customer_org: this.model.domain.organisation_name,
          googleDocUrl: this.model.googleDocUrl,
          createdUser: {
            name: this.model.user.created_user_name,
            role: this.model.user.created_user_role,
            date: this.model.created_at,
            text: 'Created Engineer Recommendations',
            img: this.model.user.created_user_img,
          },
          approval_phases: this.model?.approval_phases,
        };
      } else if (this.model) {
        this.googleDocData = {
          module: 'Engineer Recommendations',
          customer_domain: null,
          customer_org: null,
        };
      }
      this.googleDocData.googleDocUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://docs.google.com/document/d/' +
            this.model.google_doc_id +
            '/edit?usp=sharing&embed=true'
        );
    });
  }

  ngOnInit(): void {}

  selectCustomer(dm: DomainModel) {
    this.currentDomain = dm;
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(this.currentDomain)
    );
  }

  createEngRec(cr: EngineerRecommendationsModel) {
    this.router.navigate(['engineer-recommendations']);
  }

  approveEngRec(cr: EngineerRecommendationsModel) {
    this.router.navigate(['engineer-recommendations']);
  }

  sendDraftEngRecomForApproval(cr: EngineerRecommendationsModel) {
    this.router.navigate(['engineer-recommendations']);
  }

  async sendEngRecoToCust(custInfo) {
    var that = this;
    await this.engineerRecommendationsService
      .sendEngineerRecommendationsToCustpmer(
        this.model,
        custInfo,
        this.currentUser,
        this.model.opportunity_rfid
      )
      .then((result) => {
        if (result) {
          this.router.navigate(['engineer-recommendations']);
        }
      });
  }

  cancel() {
    this.router.navigate(['engineer-recommendations']);
  }
}
