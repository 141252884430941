import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpRequestService } from '../../../servcies/http-request-service';
import { LocalStorageService } from '../../../servcies/localstorage-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserModel } from '../../../models/mongo/user.model';
import { Endpoint, Unicode } from '../../../shared/Endpoint';
import { Utilities } from '../../../shared/utilities';
import { NotificationModel } from '../../../models/mongo/notification.model';
import { MessageService } from '../../../servcies/message.service';
@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent {
  @HostBinding('class') class = ' ';
  customerActions = [
    'customer_approve_customer_requirements',
    'customer_approve_engineer_recommendations',
    'customer_approve_sow',
    'customer_approve_poc',
    'customer_approve_proposal',
    'customer_reject_customer_requirements',
    'customer_reject_engineer_recommendations',
    'customer_reject_sow',
    'customer_reject_poc',
    'customer_reject_proposal',
  ];
  currentUser: UserModel;
  notifications: NotificationModel[] = [];
  static _httpRequestService: HttpRequestService;
  static _http: HttpClient;
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  static notifications = [];
  static pagination: number = 1;
  static total: number = 1;
  static unreadCount: number = 1;
  static gcp: boolean;
  selectedFilterType: string = 'ALL';
  notification: { read: boolean } = { read: false };
  checkedNotifications: any[] = [];

  constructor(
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService
  ) {
    //
    NotificationListComponent._httpRequestService = httpRequestService;
    NotificationListComponent._http = http;
    NotificationListComponent.gcp = false;
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    NotificationListComponent.pagination = 1;
    this.getnotifications(null);
  }

  protected onDropdownChange(value: number): void {
    this.messageService.setDropdownValue(value);
  }

  async getnotifications(pagination) {
    if (pagination == null) pagination = NotificationListComponent.pagination;
    var prs = new HttpParams()
      .set(
        Unicode.PAGE,
        (pagination - 1) * NotificationListComponent.pageSize + ''
      )
      .set(Unicode.LIMIT, NotificationListComponent.pageSize + '')
      .set(Unicode.TYPE, this.selectedFilterType);
    if (!this.currentUser.canViewAll) prs = prs.set(Unicode.VIEW_ALL, '0');
    if (!Utilities.isNullOrEmpty(this.selectedFilterType))
      prs = prs.set(Unicode.NOTIFICATION_TYPE, this.selectedFilterType);
    await NotificationListComponent._http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.GET_ALL_NOTIFICATIONS, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp) {
          NotificationListComponent.total = <number>resp['total'];
          NotificationListComponent.notifications = resp.notifications ?? [];
        }
      })
      .catch((e) => {
        if (e.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log(e);
      });
  }

  renderPage(event: number) {
    NotificationListComponent.pagination = event;
    this.getnotifications(null);
  }

  search() {
    NotificationListComponent.pagination = 1;
    this.getnotifications(null);
  }

  switchType(e) {
    e.target.classList.toggle('toggled');
    NotificationListComponent.pagination = 1;
    NotificationListComponent.gcp = !NotificationListComponent.gcp;
    this.getnotifications(null);
  }

  applyFilter(type: string): void {
    if (type != this.selectedFilterType) {
      NotificationListComponent.pagination = 1;
      this.selectedFilterType = type;
      this.getnotifications(null);
    }
  }

  get GetData() {
    return NotificationListComponent.notifications;
  }

  get GetTotalCount() {
    return NotificationListComponent.total;
  }

  get GetPagination() {
    return NotificationListComponent.pagination;
  }

  get GetPageSize() {
    return NotificationListComponent.pageSize;
  }

  goBack(): void {
    this.location.back();
  }

  clickElt(event: any, item: any) {
    if (event.target.checked) {
      this.checkedNotifications.push(item); // Add checked notification to array
    } else {
      // Remove unchecked notification from array
      this.checkedNotifications = this.checkedNotifications.filter(
        (notification) => notification !== item
      );
    }
  }

  // markAsRead() {
  //   var checkboxValues = []; // Initialize an empty array to store checkbox values
  //
  //   $('#myTable .checkbox:checked').each(function() {
  //     var checkboxValue = $(this).val();
  //     checkboxValues.push(checkboxValue); // Push each checkbox value into the array
  //   });
  //   checkboxValues.forEach(checkboxValue => {
  //     this.http.put<any>(Endpoint.GLOBAL_URL + Endpoint.MARK_NOTIFICATION_AS_READ + checkboxValue , { id: checkboxValue }).subscribe(
  //       response => {
  //         console.log('Notification with ID ' + checkboxValue + ' marked as read:', response);
  //         $('.color' + checkboxValue).hide();
  //         // Handle response if needed
  //       },
  //       error => {
  //         console.error('Error marking notification with ID ' + checkboxValue + ' as read:', error);
  //       }
  //     );
  //   });
  // }
  markAsRead() {
    // get notification IDs from checkedNotifications array
    this.http
      .put<any>(Endpoint.GLOBAL_URL + Endpoint.MARK_NOTIFICATIONS_AS_READ, {
        notificationIds: this.checkedNotifications,
      })
      .subscribe(
        (response) => {
          this.checkedNotifications.forEach((notification) => {
            $('.color' + notification).hide();
            this.notification.read = true;
          });
          this.checkedNotifications = [];
          // Extract the count from the response object
          const count = response.nb_unread_notifications;
          // Assuming you have a method to update the UI with the unread count
          this.messageService.setDropdownValue(count);
        },
        (error) => {
          console.error('Error marking notifications as read:', error);
        }
      );
  }

  markAllAsRead() {
    this.http
      .put<any>(Endpoint.GLOBAL_URL + Endpoint.MARK_ALL_NOTIFICATIONS_AS_READ, {
        read: true,
      })
      .subscribe(
        (response) => {
          $('.colors').hide();
          this.notification.read = true;
          // Extract the count from the response object
          const count = response.nb_unread_notifications;
          // Assuming you have a method to update the UI with the unread count
          this.messageService.setDropdownValue(count);
        },
        (error) => {
          console.error('Error marking notifications as read:', error);
        }
      );
  }

  getOpportunityDetailsUrl(notification: any): string {
    if (notification.opportunity_rfid) {
      return `${Endpoint.OPPORTUNITY_DETAILS_URL}${notification.opportunity_rfid}`;
    } else {
      let moduleUrl = '';
      switch (notification.phase_name) {
        case 'deal':
          moduleUrl = Endpoint.DEAL_DETAILS_URL;
          break;
        case 'customer_requirements':
          moduleUrl = Endpoint.CUSTOMER_REQUIREMENT_DETAILS_URL;
          break;
        case 'engineer_recommendations':
          moduleUrl = Endpoint.ENGINEER_RECOMMENDATIONS_DETAILS_URL;
          break;
        case 'sow':
          moduleUrl = Endpoint.SOW_DETAILS_URL;
          break;
        case 'poc':
          moduleUrl = Endpoint.POC_DETAILS_URL;
          break;
        case 'proposal':
          moduleUrl = Endpoint.PROPOSAL_DETAILS_URL;
          break;
        case 'billing':
          moduleUrl = Endpoint.BILLING_LIST_URL;
          return moduleUrl;
        case 'project':
          moduleUrl = Endpoint.PROJECT_LIST_URL;
          return moduleUrl;
        case 'order':
          moduleUrl = Endpoint.ORDER_LIST_URL;
      }
      return `${moduleUrl}${notification.phase_rfid}`;
    }
  }
  isCustomerAction(action: string): boolean {
    return this.customerActions.includes(action);
  }
}
