import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  Endpoint,
  Group,
  OrderAction,
  OrderStatus,
  PlanValues,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { UserModel } from 'src/models/mongo/user.model';
import { DatePipe } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { OrderModel } from 'src/models/mongo/order.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { DomainModel } from 'src/models/mongo/domain.model';

@Component({
  selector: 'app-transferable-subscriptions',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './transferable-subscriptions.component.html',
  styleUrls: ['./transferable-subscriptions.component.scss'],
})
export class TransferableSubscriptionsComponent implements OnInit {
  @HostBinding('class') class = '';
  orderForm: UntypedFormGroup;
  model: OrderModel = new OrderModel();
  opportunitySources = [];
  opportunityStatus = [];
  globalData = [];
  googleProducts = [];
  plans = [];
  currentUser: UserModel;
  orderPhasesInfo;
  prices = [];
  saveText = 'Send';
  routeParams;
  domain: DomainModel;
  transferableSubscriptions = [];
  usersInfo;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.orderForm = fb.group({
      customer_domain: ['', Validators.required],
      customer_email: [''],
      description: [null],
      po_id: [null, Validators.required],
      order_sale_invoice_nbr: [null, Validators.required],
      opportunity_source: [null, Validators.required],
      opportunity_nbr: [{ value: null, disabled: !this.currentUser.isGPM }],
      opportunity_status: [null, Validators.required],
      google_rep: [''],
      customer_email_notification: ['', Validators.email],
      sku: this.fb.array([]),
    });
    this.route.queryParams.subscribe((params) => {
      this.routeParams = params;
      this.domain = JSON.parse(params['customer']);
      this.orderForm.patchValue({
        customer_domain: this.domain.domain,
      });
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.currentUser.isExecutor) this.saveText = 'Execute';
    var usersInfojson = this.localStorage.get(Unicode.USERS_INFO);
    this.usersInfo = usersInfojson ? JSON.parse(usersInfojson) : {};
    if (!this.model.user) {
      this.model.created_by_rfid = this.currentUser._id;
      this.model.user = {
        created_by_rfid: this.currentUser._id,
        created_user_email: this.currentUser.email,
        created_user_img: this.currentUser.imageUrl,
        created_user_name: this.currentUser.user_name,
        manager_user_id: this.currentUser.manager_id,
        manager_user_email: this.currentUser.manager_email,
        manager_user_name: this.currentUser.manager_name,
      };
    }
    if (!this.model.domain && this.domain) {
      this.model.customer_domain = this.domain.domain;
      this.model.customer_api_id = this.domain.customer_api_id;
      this.model.customer_auth_token = this.domain.auth_token;
      this.model.customer_organisation = this.domain.organisation_name;
    }
    this.orderPhasesInfo = await Utilities.orderApprovalPhases(
      this.localStorage,
      this.model,
      this.usersInfo
    );
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    this.googleProducts = this.googleProducts.filter(
      (x) => x.gcp == undefined || x.gcp == false
    );
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.prices = await Utilities.getPrices(this.http, this.localStorage);
    this.globalData = await Utilities.globalData(this.http, this.localStorage);
    if (this.globalData && this.globalData.length > 0) {
      this.opportunitySources =
        this.globalData.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() == Unicode.GLOBAL_KEY_SOURCE.toLowerCase()
        )?.values ?? [];
      this.opportunityStatus =
        this.globalData.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() ==
              Unicode.GLOBAL_KEY__OPPORTUNITY_STATUS.toLowerCase()
        )?.values ?? [];
    }
    this.model.created_by_rfid = this.currentUser['_id'];
    await Utilities.getSubscriptionsByCustomer(
      this.http,
      this.domain.domain,
      this.domain.auth_token
    ).then((resp: any) => {
      if (resp && resp.subscriptions) {
        this.transferableSubscriptions = resp.subscriptions;
        this.transferableSubscriptions.forEach((element) => {
          var planId = this.plans.find(
            (x) => x.code == element.plan?.planName
          )._id;
          var seatsNbr;
          if (
            element.plan.planName == Unicode.FLEXIBLE_PLAN ||
            element.plan.planName == Unicode.TRIAL_PLAN
          ) {
            seatsNbr = element.seats.maximumNumberOfSeats;
          } else {
            seatsNbr = element.seats ? element.seats.numberOfSeats : 0;
          }
          this.sku.push(
            this.fb.group({
              sku_id: [element.skuId, [Validators.required]],
              sku_name: [element.skuName, [Validators.required]],
              plan_id: [planId, Validators.required],
              license: [seatsNbr, Validators.required],
              start_date: [null, Validators.required],
              end_date: [null, Validators.required],
              with_google_offer: [false],
              google_offer: [0],
              with_customer_offer: [false],
              customer_offer: [0],
              cost_price: [0],
              selling_price: [0],
              expanded: [true],
            })
          );
        });
      }
    });
  }

  async saveOrder() {
    if (this.orderForm.valid) {
      var skus = this.orderForm.value.sku;
      if (!skus || this.sku.length == 0) {
        alert('You must add at least one SKU');
        return;
      }
      if (
        skus.find(
          (x) => !x.plan_id || !x.sku_id || !x.license || x.license == 0
        ) != null
      ) {
        alert('You must select SKU,plan and add license for each service');
        return;
      }
      if (
        skus.find(
          (x) =>
            (x.with_google_offer == true &&
              (!x.google_offer || x.google_offer == 0)) ||
            (x.with_customer_offer == true &&
              (!x.customer_offer || x.customer_offer == 0))
        )
      ) {
        alert('you must add Offer percentage');
        return;
      }
      await this.save().then(async (addedToDB) => {
        if (addedToDB == true) {
          this.router.navigate(['/orders']);
        }
      });
    } else {
      alert('Add Required Fields');
    }
  }

  async save(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      var order: OrderModel = this.orderForm.value;
      // order.customer_rfid = this.model.customer_rfid;
      order.created_by_rfid = this.model.created_by_rfid;
      order.domain_rfid = this.domain._id;
      order.sku.forEach((element) => {
        Utilities.setSKUFields(element, this.plans, this.googleProducts);
      });
      order.action = OrderAction.TRANSFER;
      order.order_status = this.orderPhasesInfo.status ?? OrderStatus.Pending;
      order.is_gcp = false;
      if (!Utilities.isNullOrEmpty(this.currentUser.manager_id)) {
        order.approval_phases = [
          {
            email: this.currentUser.manager_email,
            id: this.currentUser.manager_id,
            name: this.currentUser.manager_name,
            role: Group.Sale_Manager,
          },
        ];
      } else {
        order.approval_phases = [
          {
            role: Group.GPM,
          },
        ];
      }
      if (!this.currentUser.isExecutor) this.domain.deleted = true;
      //order.customer_details = this.customer;
      var params = new HttpParams().set('to_transfer', '1');
      var receivers =
        Utilities.setReceivers(this.currentUser, this.usersInfo) ?? [];
      params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
      params = params.set(Unicode.TO_EXECUTE, this.currentUser.isExecutor);
      await this.http
        .post(Endpoint.GLOBAL_URL + '' + Endpoint.ADD_ORDER, order, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          console.log(resp);
          this.model._id = resp.id;
          if (!Utilities.isNullOrEmpty(resp.customer_rfid)) {
            this.domain._id = resp.customer_rfid;
            this.model.domain_rfid = resp.customer_rfid;
          }
          resolve(true);
        })
        .catch((err) => {
          alert(err?.error.message ?? err.statusText);
          resolve(false);
        });
    });
  }

  deleteSKU(skuID) {
    var index = this.sku.controls.findIndex(
      (x: any) => x.value.sku_id == skuID
    );
    if (index >= 0) this.sku.removeAt(index);
  }
  expandSku(skuID) {
    var sku = this.sku.controls.find((x: any) => x.value.sku_id == skuID);
    if (sku) {
      var expanded = (<UntypedFormGroup>sku).controls['expanded'].value;
      (<UntypedFormGroup>sku).controls['expanded'].setValue(!expanded);
    }
  }

  calculatePrices(sku) {
    if (
      Utilities.isNullOrEmpty(sku.value.start_date) ||
      Utilities.isNullOrEmpty(sku.value.end_date)
    ) {
      return;
    }
    //EXPANED
    if (sku) sku.controls['expanded'].setValue(true);
    //
    var from_ = new Date(sku.value.start_date);
    var to_ = new Date(sku.value.end_date);
    var months = Utilities.calculateDiffMonths(from_, to_);
    var days = months * 30;

    var skuObjectId = this.googleProducts.find(
      (x) =>
        sku.value.sku_id &&
        x.sku_id.toLowerCase() == sku.value.sku_id.toLowerCase()
    )?._id;
    var priceObj = this.prices.find(
      (x) =>
        skuObjectId &&
        sku.value.plan_id &&
        x.sku_rfid == skuObjectId &&
        x.plan_rfid == sku.value.plan_id
    );
    if (priceObj) {
      var price = priceObj.price / 30;
      var costMargin = Utilities.setOrderCostMargin(OrderAction.TRANSFER);
      var costPrice: number = price * costMargin * days;
      var sellingPrice: number = price * days;

      //OFFERS
      if (
        sku.value.with_google_offer &&
        sku.value.google_offer &&
        sku.value.google_offer != 0
      ) {
        var offer = (100 - sku.value.google_offer) / 100;
        costPrice *= offer;
      }
      if (
        sku.value.with_customer_offer &&
        sku.value.customer_offer &&
        sku.value.customer_offer != 0
      ) {
        var offer = (100 - sku.value.customer_offer) / 100;
        sellingPrice *= offer;
      }
      //LICENSES
      var licencesNbr = sku.value.license;
      if (licencesNbr > 0) {
        sellingPrice = sellingPrice * licencesNbr;
        costPrice = costPrice * licencesNbr;
        sku.controls['selling_price'].setValue(
          Number.parseFloat(sellingPrice.toFixed(2))
        );
        sku.controls['cost_price'].setValue(
          Number.parseFloat(costPrice.toFixed(2))
        );
      }
    }
  }

  addAnotherSKU() {
    this.sku.controls.forEach((cont) => {
      (<UntypedFormGroup>cont).controls['expanded'].setValue(false);
    });
    this.sku.push(
      this.fb.group({
        sku_id: [null, Validators.required],
        plan_id: [null, Validators.required],
        license: [0, Validators.required],
        start_date: [null, Validators.required],
        end_date: [null, Validators.required],
        with_google_offer: [false],
        google_offer: [0],
        with_customer_offer: [false],
        customer_offer: [0],
        cost_price: [0],
        selling_price: [0],
        expanded: [true],
      })
    );
  }

  cancelOrder() {
    this.router.navigate(['/orders']);
  }

  get customer_domain() {
    return this.orderForm.get('customer_domain');
  }
  get customer_email() {
    return this.orderForm.get('customer_email');
  }
  get description() {
    return this.orderForm.get('description');
  }
  get po_id() {
    return this.orderForm.get('po_id');
  }
  get order_sale_invoice_nbr() {
    return this.orderForm.get('order_sale_invoice_nbr');
  }
  get opportunity_source() {
    return this.orderForm.get('opportunity_source');
  }
  get opportunity_nbr() {
    return this.orderForm.get('opportunity_nbr');
  }
  get opportunity_status() {
    return this.orderForm.get('opportunity_status');
  }
  get google_rep() {
    return this.orderForm.get('google_rep');
  }
  get customer_email_notification() {
    return this.orderForm.get('customer_email_notification');
  }
  get project_number() {
    return this.orderForm.get('project_number');
  }
  get project_name() {
    return this.orderForm.get('project_name');
  }
  get billing_name() {
    return this.orderForm.get('billing_name');
  }
  get engagement() {
    return this.orderForm.get('engagement');
  }
  get sku(): UntypedFormArray {
    return this.orderForm.get('sku') as UntypedFormArray;
  }
}
