import {
  Endpoint,
  OrderAction,
  OrderStatus,
  PlanValues,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, HostBinding } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderModel } from 'src/models/mongo/order.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserModel } from 'src/models/mongo/user.model';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'lib-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss'],
})
export class AddOrderComponent implements OnInit, AfterViewInit {
  model: OrderModel = new OrderModel();
  globalData = [];
  currentUser: UserModel;
  fromCustomer: boolean = true;
  isGWS: boolean;
  dealSKUs;
  dealType: string;
  routeParams;
  isActionOrder: boolean = false; //COMING FROM CUSTOMER SUB ACTIONS
  //ATCIONS
  totalNbrOfUsers = 0;
  companySections = [];
  actions = {
    isActionOrder: false,
    toUpdatePlan: false,
    toUprade: false,
    toDowngrade: false,
    toRenew: false,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private location: Location
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.route.queryParams.subscribe((params) => {
      this.routeParams = params;
      if (Object.keys(params).length === 0) this.routeParams = history.state;
      this.isGWS = Utilities.isTrue(this.routeParams['isGWS']);
      this.isActionOrder =
        this.isGWS == true
          ? this.routeParams[Unicode.SUB_ACTION] &&
            Utilities.isTrue(this.routeParams[Unicode.SUB_ACTION])
          : false;
      if (this.isActionOrder) {
        this.model.paramsData.subscription_api_id_action =
          this.routeParams['subscription_api_id'];
        this.model.paramsData.skuId = this.routeParams['skuId'];
        this.model.paramsData.skuName = this.routeParams['skuName'];
        this.model.paramsData.planId = this.routeParams['planId'];
        this.model.paramsData.planName = this.routeParams['planName'];
        this.model.paramsData.license = this.routeParams['license'];
        this.model.paramsData.availableSKUIDs =
          this.routeParams['availableSKUIDs'];
        this.model.paramsData.endApiDate =
          this.routeParams['end_api_date'] ?? null;
        var toUpdatePlan =
          this.routeParams[Unicode.SUB_ACTION_UPDATE_PLAN] &&
          Utilities.isTrue(this.routeParams[Unicode.SUB_ACTION_UPDATE_PLAN]);
        var toUprade =
          this.routeParams[Unicode.SUB_ACTION_UPGRADE] &&
          Utilities.isTrue(this.routeParams[Unicode.SUB_ACTION_UPGRADE]);
        var toDowngrade =
          this.routeParams[Unicode.SUB_ACTION_DOWNGRADE] &&
          Utilities.isTrue(this.routeParams[Unicode.SUB_ACTION_DOWNGRADE]);
        var toRenew =
          this.routeParams[Unicode.SUB_ACTION_RENEW] &&
          Utilities.isTrue(this.routeParams[Unicode.SUB_ACTION_RENEW]);
      }
      this.actions = {
        isActionOrder: this.isActionOrder,
        toUpdatePlan: toUpdatePlan,
        toUprade: toUprade,
        toDowngrade: toDowngrade,
        toRenew: toRenew,
      };
      if (params && this.routeParams['domain_rfid']) {
        this.fromCustomer = true;
        this.model.domain_rfid = this.routeParams['domain_rfid'];
        this.model.customer_api_id = this.routeParams['customer_api_id'];
        this.model.customer_domain = this.routeParams['domain'];
        this.model.customer_organisation = this.routeParams['organisation'];
        if (!Utilities.isNullOrEmpty(this.routeParams['skus'])) {
          //MAKE ORDER FROM DEAL CASE
          var skus = JSON.parse(this.routeParams['skus']);
          if (skus && skus.length > 0) {
            this.dealType = this.routeParams['deal_type'];
            this.dealSKUs = skus;
            if (
              this.dealType?.toLowerCase() == 'new' ||
              Utilities.isNullOrEmpty(this.model.customer_api_id)
            ) {
              this.model.action = OrderAction.NEW_CUSTOMER;
              this.model.customer_details = this.routeParams['customer']
                ? JSON.parse(this.routeParams['customer'])
                : null;
            }
          }
          this.model.opportunity_source = this.routeParams['source'] ?? '';
          this.model.google_rep = this.routeParams['sougoogle_reprce'] ?? '';
          this.model.opportunity_nbr =
            this.routeParams['opportunity_nbr'] ?? '';
        }
      } else {
        this.fromCustomer = false;
      }
    });
    if (!this.fromCustomer)
      this.fromCustomer = !Utilities.isNullOrEmpty(
        localStorage.get(Unicode.CURRENT_CUSTOMER)
      );
  }

  ngAfterViewInit(): void {}

  async ngOnInit(): Promise<void> {
    if (
      !Utilities.isNullOrEmpty(this.routeParams['isNewCustomer']) &&
      Utilities.isTrue(this.routeParams['isNewCustomer']) &&
      !Utilities.isNullOrEmpty(this.routeParams['customerModel'])
    ) {
      this.model.action = OrderAction.NEW_CUSTOMER;
      this.model.customer_details = JSON.parse(
        this.routeParams['customerModel']
      );
    }
    this.model.created_by_rfid = this.currentUser['_id'];
    this.model.user = {
      created_by_rfid: this.currentUser._id,
      created_user_email: this.currentUser.email,
      created_user_img: this.currentUser.imageUrl,
      created_user_name: this.currentUser.user_name,
      manager_user_id: this.currentUser.manager_id,
      manager_user_email: this.currentUser.manager_email,
      manager_user_name: this.currentUser.manager_name,
    };
    var custjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (custjson) {
      var currentDomain = JSON.parse(custjson);
      this.model.customer_domain = currentDomain.domain;
      this.model.customer_api_id = currentDomain.customer_api_id;
      this.model.customer_auth_token = currentDomain.auth_token;
      this.model.customer_organisation = currentDomain.organisation_name;
    }
  }

  cancelOrder() {
    // this.router.navigate(['/orders']);
    this.location.back();
  }
}
