import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Endpoint, Group, Permissions, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserModel } from 'src/models/mongo/user.model';
import { SettingsService } from 'src/servcies/backend-services/settings.service';
import {CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';

@Component({
  selector: 'app-sale-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sale-details.component.html',
  styleUrls: ['./sale-details.component.scss'],
})
export class SaleDetailsComponent implements OnInit {
  @ViewChild('myCheckbox1') myCheckbox1: ElementRef<HTMLInputElement>;
  @ViewChild('myCheckbox2') myCheckbox2: ElementRef<HTMLInputElement>;

  @HostBinding('class') class =
    'container-fluid px-2 d-flex flex-column justify-content-between';

  model: UserModel = new UserModel();
  initModel: UserModel = new UserModel();
  saleForm: UntypedFormGroup;
  currentUser: UserModel;
  all_groups = [];
  managers = [];

  manager_id: any;
  all_permissions = [];
  permissions = [];
  permissions_ids = [];
  saleManagers = [];
  selectedGroups: string;
  initGroups = [];

  countries: any[] = [];
  formdisable: boolean;
  isFormSubmitted: boolean = false;
  isChecked: boolean = false;
  companySectors = [];
  isEnginner: boolean = false;
  isSales: boolean = false;
  phoneUtil = PhoneNumberUtil.getInstance();
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Lebanon, CountryISO.SaudiArabia];
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    private localStorage: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private settingsService: SettingsService
  ) {
    this.saleForm = fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      country: ['', Validators.required],
      group_ids: ['', Validators.required],
      sector: [null, Validators.required],
      manager: [''],
      Permissions: [''],
      is_gcp: [false],
      is_manager: [false],
      viewer: [false],
    });
  }
  permForm = this.fb.group({
    id: [null],
    name: [null],
    code: [null],
    checked: [false],
  });

  async ngOnInit(): Promise<void> {
    var that = this;
    const state = history.state;


    this.formdisable = state.formdisable === true;


    const resellerUser = state.model;
    if (resellerUser) {
      try {
        this.model = JSON.parse(resellerUser);

        this.model.existing_group_ids = this.model.group_ids;
        if (
          this.model.permission_codes &&
          this.model.permission_codes.includes('EXC')
        ) {
          this.permissions.push('EXC');
        }
        if (
          this.model.permission_codes &&
          this.model.permission_codes.includes('VIEW_ALL')
        ) {
          this.permissions.push('VIEW_ALL');
        }
        if (
          this.model.permission_codes &&
          this.model.permission_codes.includes('ASSIGN')
        ) {
          this.permissions.push('ASSIGN');
        }
        if (
          this.model.permission_codes
            ?.map((x) => (x = x.toUpperCase()))
            ?.includes(Permissions.VIEWER)
        ) {
          this.permissions.push(Permissions.VIEWER);
        }
        this.saleForm.patchValue({
          ...this.model,
          country: this.model.country_iso2,
          manager: this.model.manager_id,
          viewer: this.model.permission_codes
            .map((x) => (x = x.toUpperCase()))
            .includes(Permissions.VIEWER)
            ? true
            : false,
        });
        this.isEnginner =
          this.model.mainGroup?.name?.toLowerCase() ==
          Group.ENGINEER.toLowerCase();
        this.isSales =
          this.model.mainGroup?.name?.toLowerCase() == Group.Sale.toLowerCase();
      } catch (e) {
        console.error('Error parsing user model:', e);
      }
    }

    if (Utilities.isNullOrEmpty(this.localStorage.get(Unicode.MAIN_GROUPS))) {
      var resp: any = await this.settingsService
        .getAllGroups({ only_main: true })
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(
          Unicode.MAIN_GROUPS,
          JSON.stringify(resp.groups)
        );
      }
    }
    this.all_groups = JSON.parse(this.localStorage.get(Unicode.MAIN_GROUPS));

    if (Utilities.isNullOrEmpty(this.localStorage.get(Unicode.PERMISSIONS))) {
      var resp: any = await this.http
        .get(Endpoint.API_URL + Endpoint.PERMS_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(
          Unicode.PERMISSIONS,
          JSON.stringify(resp.permissions)
        );
      }
    }
    this.all_permissions = JSON.parse(
      this.localStorage.get(Unicode.PERMISSIONS)
    );

    var resp: any = await this.http
      .get(Endpoint.API_URL + Endpoint.READ_SALE_FILE_NAME)
      .toPromise();
    if (resp && !resp.msg) {
      this.localStorage.save(Unicode.SALES, JSON.stringify(resp.users));
    }

    this.managers = JSON.parse(this.localStorage.get(Unicode.SALES));
    const GlobalData = JSON.parse(localStorage.getItem('GlobalDataList'));

    this.countries =
      GlobalData.find(
        (x) =>
          x.key &&
          x.key.toLowerCase() == Unicode.GLOBAL_KEY_COUNTRY.toLowerCase()
      )?.values ?? [];
    this.companySectors =
      GlobalData.find(
        (x) =>
          x.key &&
          x.key.toLowerCase() ==
            Unicode.GLOBAL_KEY_COMPANY_SECTORS.toLowerCase()
      )?.values ?? [];

    // this.cdr.detectChanges();
  }

  onCheckboxChange(event: any, permission: string) {
    // this.isChecked = event.target.checked;

    if (event.target.checked) {
      // Add the permission to the array if it's not already there
      // if (!this.model.permissions.includes(permission)) {
      this.permissions.push(permission);

      // }
    } else {
      // Remove the permission from the array if it's there
      const index = this.permissions.indexOf(permission);
      if (index !== -1) {
        this.permissions.splice(index, 1);
      }
    }
  }

  onViewerChange(event) {
    var onlyViewer = event.target.checked;
    if (onlyViewer) {
      this.permissions.push(Permissions.VIEWER);
    } else {
      const index = this.permissions.indexOf(Permissions.VIEWER);
      if (-1 !== index) {
        this.permissions.splice(index, 1);
      }
    }
  }

  async onSubmit() {
    this.isFormSubmitted = true;
    var formModel = this.saleForm.value;
    if (this.saleForm.valid) {
      var mainGrps = this.all_groups
        .filter((x) => x.main == true)
        .map((x) => x._id);
      if (
        formModel.group_ids.filter((x) => mainGrps.indexOf(x) >= 0).length > 1
      ) {
        alert('Please select only one Main Group');
        return;
      }
      if (formModel.phone){
        formModel.phone=formModel.phone.internationalNumber ?? '';
      }
      var params = new HttpParams();
      if (!formModel.phone) formModel.phone = null;
      if (!formModel.manager) formModel.manager_id = null;

      this.permissions.forEach((permissionCode) => {
        const matchingPermission = this.all_permissions.find(
          (permission) => permission.code === permissionCode
        );
        if (matchingPermission) {
          this.permissions_ids.push(matchingPermission._id);
        }
      });

      if (Utilities.isNullOrEmpty(this.model._id)) {
        this.model.permission_ids = [];
        this.permissions.forEach((permission) => {
          const matchingPermission = this.all_permissions.find(
            (p) => p.code === permission
          );
          if (matchingPermission) {
            this.model.permission_ids.push(matchingPermission._id);
          }
        });
        formModel.permission_ids = this.model.permission_ids;
        formModel.country_object = this.countries.find(
          (country) => country.iso2 === formModel.country
        );
        try {
          var resp: any = await this.http
            .post(Endpoint.API_URL + Endpoint.ADD_SALE_FILE_NAME, formModel)
            .toPromise();
          if (resp && resp.success != false) {
            alert('successfully ' + 'Added');

            this.router.navigate(['/users/']);
          } else alert(resp.message);
        } catch (error) {}
      } else {
        formModel.groupsDeleted = [];
        formModel.groupsAdded = [];

        formModel.country_object = this.countries.find(
          (country) => country.iso2 === formModel.country
        );

        this.model.existing_group_ids.forEach((id) => {
          // Check if id exists in modelIds array
          if (!formModel.group_ids.includes(id)) {
            // If id doesn't exist, add it to Addedids array
            formModel.groupsDeleted.push(id);
          }
        });

        formModel.group_ids.forEach((id) => {
          // Check if id exists in modelIds array
          if (!this.model.existing_group_ids.includes(id)) {
            // If id doesn't exist, add it to Addedids array
            formModel.groupsAdded.push(id);
          }
        });

        this.model.permissionsAdded = [];
        this.model.permissionsDeleted = [];

        this.permissions_ids.forEach((id) => {
          // Check if id exists in modelIds array
          if (!this.model.permission_ids.includes(id)) {
            // If id doesn't exist, add it to Addedids array
            this.model.permissionsAdded.push(id);
          }
        });
        this.model.permission_ids.forEach((id) => {
          // Check if id exists in modelIds array
          if (!this.permissions_ids.includes(id)) {
            // If id doesn't exist, add it to Addedids array
            this.model.permissionsDeleted.push(id);
          }
        });
        formModel.permissionsAdded = this.model.permissionsAdded;
        formModel.permissionsDeleted = this.model.permissionsDeleted;
        var resp: any = await this.http
          .put(
            Endpoint.API_URL +
              Endpoint.UPDATE_SALE_FILE_NAME +
              '/' +
              this.model._id,
            formModel,
            { params }
          )
          .toPromise();
        console.log(resp);
        if (resp && resp.success != false) {
          alert('successfully Updated');

          this.router.navigate(['/users/']);
        } else alert(resp.message);
      }
    }
  }

  selectGrp(e) {
    if (e && e.length > 0) {
      //ONLY ONE MAIN GROUP
      var mainGrps = this.all_groups
        .filter((x) => x.main == true)
        .map((x) => x._id);
      if (e.filter((x) => mainGrps.indexOf(x) >= 0).length > 1) {
        e.pop();
        this.saleForm.controls?.group_ids?.setValue(e);
        alert('Please select only one Main Group');
        return;
      }
      //DISPLAY GWS/GCP OPTION IF ENGINEER
      var lastId = e[e.length - 1];
      if (lastId) {
        var lastGrp = this.all_groups.find((x) => x._id == lastId);
        if (lastGrp?.name?.toLowerCase() == Group.ENGINEER.toLowerCase()) {
          this.isEnginner = true;
        } else this.isEnginner = false;
        if (lastGrp?.name?.toLowerCase() == Group.Sale.toLowerCase()) {
          this.isSales = true;
        } else this.isSales = false;
      }
    }
  }
  cancel() {
    this.router.navigate(['/users/']);
  }
}
