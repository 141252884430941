import {
  AfterViewInit,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from 'src/models/page';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { UserModel } from 'src/models/mongo/user.model';
import { SettingsService } from 'src/servcies/backend-services/settings.service';

@Component({
  selector: 'sales-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.scss'],
})
export class SalesListComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class =
    'px-2 d-flex flex-column justify-content-between';

  sales: UserModel[] = [];
  @ViewChild(DatatableComponent) public table: DatatableComponent;
  page = new Page();
  static pageStatic;
  subdata: UserModel[] = [];
  static subdataStatic: UserModel[] = [];

  currentUser: UserModel;
  static pageSizeStatic: number = Unicode.ITEMS_PER_PAGE;
  pageTitle: string = '';

  currentPage = 1;
  pageSize = 20;
  userSearch: string;
  minUserIndex: number;
  maxUserIndex: number;
  totalUsers: number;
  totalItems: number;

  constructor(
    private router: Router,
    private http: HttpClient,
    private confirmDialogService: ConfirmDialogService,
    private localStorage: LocalStorageService,
    private location: Location,
    private settingsService: SettingsService
  ) {
    this.currentUser = JSON.parse(this.localStorage.get(Unicode.CURRENT_USR));
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.getSales({ offset: 0 }, '');
    this.pageTitle = 'Users';
  }
  get totalPages(): number {
    return Math.ceil(this.sales.length / this.pageSize);
  }
  onPageChanged(newPage: number) {
    if (newPage < 1 || newPage > this.totalPages) {
      return; // Prevent going out of bounds
    }
    this.currentPage = newPage;
    this.minUserIndex = (this.currentPage - 1) * this.pageSize + 1;
    this.maxUserIndex = Math.min(
      this.minUserIndex + this.pageSize - 1,
      this.totalItems
    );
  }
  get pagedUsers(): UserModel[] {
    if (this.sales.length === 0) {
      this.minUserIndex = 0;
      this.maxUserIndex = 0;
    }
    const startIndex = (this.currentPage - 1) * this.pageSize;
    return this.sales.slice(startIndex, startIndex + this.pageSize);
  }

  async getSales(pageInfo, search) {
    var prs = new HttpParams().set(
      Unicode.PAGE,
      pageInfo.offset * this.page.size + ''
    );
    if (!Utilities.isNullOrEmpty(search))
      prs = prs.set(Unicode.SALES_FILTER, search);

    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_SALE_FILE_NAME, {
        params: prs,
      })
      .toPromise();

    if (resp) {
      this.totalItems = resp['total_users'];
      this.totalUsers = resp['total_users'];
      if (resp.users) {
        // alert(resp['users']);
        //    this.setPage({ offset: pageInfo.offset, data: JSON.stringify(resp['users']) });
        this.sales = resp.users.map((user) => ({
          _id: user.id,
          user_name: user.first_name + ' ' + user.last_name,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          country: user.country_name,
          country_iso2: user.country_iso2,
          phone: user.phone,
          sector: user.sector,
          group_ids: user.group_ids,
          mainGroup: user.mainGroup,
          is_gcp: user.is_gcp,
          is_manager: user.is_manager,
          group_names: user.group_names?.join(','),
          permission_ids: user.permission_ids,
          permission_codes: user.permission_codes,
          manager_id: user.manager_id,
          image_url: user.image_url,
        }));

        this.minUserIndex = 1;
        this.maxUserIndex = this.pagedUsers.length;
      } else {
        console.log('failed');
        // this.setPage({ offset: pageInfo.offset, data: [] });
      }
    }
  }

  async ViewSale(id) {
    var user = this.sales.find((x) => x._id == id);
    if (!user) user = this.sales.find((x) => x._id == id);
    this.router.navigate(['/users/user-details'], {
      state: { model: JSON.stringify(user), formdisable: true },
    });
  }

  async editSale(id) {
    if (Utilities.isNullOrEmpty(this.localStorage.get(Unicode.MAIN_GROUPS))) {
      var resp: any = await this.settingsService
        .getAllGroups({ only_main: true })
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(
          Unicode.MAIN_GROUPS,
          JSON.stringify(resp.groups)
        );
      }
    }

    var user = this.sales.find((x) => x._id == id);
    if (!user) user = this.sales.find((x) => x._id == id);
    this.router.navigate(['/users/user-details'], {
      state: { model: JSON.stringify(user) },
    });
  }

  deleteSale(id) {
    var that = this;
    var sale = this.sales.find((x) => x._id == id);
    if (!sale) sale = that.sales.find((x) => x._id == id);
    this.confirmDialogService.confirmThis(
      'Are you sure to delete ' + sale.user_name + '?',
      null,
      'confirm',
      async function () {
        var params = new HttpParams().set(Unicode.USER_ID, id);
        var resp: any = await that.http
          .delete(Endpoint.GLOBAL_URL + Endpoint.DELETE_SALE_FILE_NAME, {
            params: params,
          })
          .toPromise();
        console.log(resp);
        if (resp && !resp.msg) {
          alert('User Deleted');
          var users = JSON.parse(that.localStorage.get(Unicode.USERS) ?? '[]');
          var index = users.findIndex((x) => x.id == id);
          users.splice(index, 1);
          that.localStorage.save(Unicode.USERS, JSON.stringify(users));
          const localIndex = that.sales.indexOf(sale);
          if (localIndex >= 0) that.sales.splice(localIndex, 1);
        } else alert(resp.msg);
      },
      function () {}
    );
  }
  async searchUser(event) {
    this.userSearch = event.target.value;

    await this.getSales({ offset: 0 }, this.userSearch);
  }

  get GetData() {
    return SalesListComponent.subdataStatic;
  }
  goBack(): void {
    this.location.back();
  }
}
