import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderModel } from 'src/models/mongo/order.model';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, OrderStatus, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'lib-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.scss'],
})
export class AllOrdersComponent implements OnInit {
  orders: OrderModel[] = [];
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  plans = [];
  static googleProducts = [];
  static currentUser: UserModel;
  static statusSearchValue: string;
  static _httpRequestService: HttpRequestService;
  static _http: HttpClient;
  static allOrders: boolean = true;
  static orders = [];
  static pagination: number = 1;
  static total: number = 1;
  static gcp: boolean;
  static order_type: string;
  static localStorage: LocalStorageService;
  static router: Router;
  selectedFilterOption: string = '';
  selectedColumn: string = '';

  filterOptions: any = {
    GWS: false,
    GCP: false,
  };
  public get DateService() {
    return '';
  }

  constructor(
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    AllOrdersComponent._httpRequestService = httpRequestService;
    AllOrdersComponent._http = http;
    AllOrdersComponent.order_type = 'all';
    AllOrdersComponent.router = router;
    AllOrdersComponent.localStorage = localStorage;

    this.selectedFilterOption = 'ALL';
    this.route.queryParams.subscribe((params) => {
      AllOrdersComponent.statusSearchValue = params['status'];
    });
  }

  ngAfterViewInit(): void {}
  async ngOnInit(): Promise<void> {
    AllOrdersComponent.currentUser = JSON.parse(
      this.localStorage.getLoggedinUser()
    );
    console.log;
    AllOrdersComponent.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    var params = this.route.snapshot.paramMap;
    AllOrdersComponent.allOrders = Utilities.isTrue(params.get('all'));
    AllOrdersComponent.statusSearchValue = AllOrdersComponent.allOrders
      ? ''
      : AllOrdersComponent.currentUser.isExecutor
      ? OrderStatus.Pending +
        '_' +
        OrderStatus.Approved +
        '_' +
        OrderStatus.TECHNICALLY_APPROVED +
        '_' +
        OrderStatus.Approved_by_GPM
      : OrderStatus.Pending;
    AllOrdersComponent.pagination = 1;
    AllOrdersComponent.getOrders(null);
  }

  static async getOrders(pagination) {
    var customerSearch = (<HTMLInputElement>(
      document.getElementById('txt-order-global-search')
    ))?.value;

    var fromDate = (<HTMLInputElement>(
      document.getElementById('order-date-from')
    ))?.value;

    var toDate = (<HTMLInputElement>document.getElementById('order-date-to'))
      ?.value;
    if (pagination == null) pagination = AllOrdersComponent.pagination;
    var prs = new HttpParams()
      .set(Unicode.PAGE, (pagination - 1) * AllOrdersComponent.pageSize + '')
      .set(Unicode.LIMIT, AllOrdersComponent.pageSize + '')
      .set(Unicode.ORDER_TYPE, AllOrdersComponent.order_type);

    prs = prs.set(Unicode.VIEW_ALL, AllOrdersComponent.currentUser.canViewAll);
    if (!Utilities.isNullOrEmpty(AllOrdersComponent.statusSearchValue))
      prs = prs.set(Unicode.ORDER_STATUS, AllOrdersComponent.statusSearchValue);
    if (!Utilities.isNullOrEmpty(customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearch);
    if (!Utilities.isNullOrEmpty(fromDate))
      prs = prs.set(Unicode.FROM_DATE, fromDate);
    if (!Utilities.isNullOrEmpty(toDate))
      prs = prs.set(Unicode.TO_DATE, toDate);
    await AllOrdersComponent._http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.ORDER_LIST, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp) {
          var orders = resp.orders ?? [];
          orders.forEach((element) => {
            if (element.sku && element.sku.length > 0)
              element.skuName = AllOrdersComponent.googleProducts.find(
                (x) => x.sku_id == element.sku[0].sku_id
              )?.sku_name;
          });
          AllOrdersComponent.orders = orders;
          AllOrdersComponent.total = <number>resp['total'];
        }
      });
  }

  renderPage(event: number) {
    AllOrdersComponent.pagination = event;
    AllOrdersComponent.getOrders(null);
  }

  viewOrder(edit: boolean, id) {
    var order = AllOrdersComponent.orders.find((x) => x._id == id);
    this.router.navigate(['orders/order-view'], {
      skipLocationChange: false,
      state: {
        edit: edit,
        order_model: JSON.stringify(order),
        date_from: (<HTMLInputElement>(
          document.getElementById('order-date-from')
        ))?.value,
        date_to: (<HTMLInputElement>document.getElementById('order-date-to'))
          ?.value,
      },
    });
  }

  get GetData() {
    return AllOrdersComponent.orders;
  }
  get GetTotalCount() {
    return AllOrdersComponent.total;
  }
  get GetPagination() {
    return AllOrdersComponent.pagination;
  }
  get GetPageSize() {
    return AllOrdersComponent.pageSize;
  }

  toggleFilterOptions(columnName: string): void {
    if (this.selectedColumn === columnName) {
      this.selectedColumn = '';
    } else {
      this.selectedColumn = columnName;
    }
  }

  isFilterOptionsOpen(columnName: string): boolean {
    return this.selectedColumn === columnName;
  }

  applyFilter(): void {
    AllOrdersComponent.pagination = 1;

    if (this.selectedFilterOption == 'GCP') {
      AllOrdersComponent.order_type = 'gcp';
      AllOrdersComponent.getOrders(null);
    } else if (this.selectedFilterOption == 'GWS') {
      AllOrdersComponent.order_type = 'gws';
      AllOrdersComponent.getOrders(null);
    } else {
      AllOrdersComponent.order_type = 'all';
      AllOrdersComponent.getOrders(null);
    }

    // You can perform any additional logic or updates here
  }
  deleteOrder(id: string) {
    if (confirm('Are you sure to delete this order?')) {
      this.http
        .put(Endpoint.GLOBAL_URL + '' + Endpoint.DELETE_ORDER + id, {})
        .subscribe((response: any) => {
          AllOrdersComponent.getOrders(null);
          alert('Order deleted successfully');
        });
    }
  }

  get getCurrentUser(): UserModel {
    return AllOrdersComponent.currentUser;
  }
}
