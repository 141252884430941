import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { SOWModel } from 'src/models/mongo/scope_of_work.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { CustomerInfoPopupComponent } from '../customer-info-popup/customer-info-popup.component';
import { RejectionPopupComponent } from '../rejection-popup/rejection-popup.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomainModel } from '../../models/mongo/domain.model';
import { OpportunityService } from 'projects/opportunity/src/public-api';

@Component({
  selector: 'sow-google-doc-card',
  templateUrl: './sow-google-doc-card.component.html',
  styleUrls: ['./sow-google-doc-card.component.scss'],
})
export class SOWGoogleDocCardComponent implements OnInit, AfterViewInit {
  currentUser: UserModel;
  domainSelected;
  customerSelected;
  @Input() cardData: any = {
    module: '',
    customer_domain: null,
    customer_org: null,
    googleDocUrl: '',
    createdUser: {},
    approval_phases: [],
  };
  @Input() sow: SOWModel = new SOWModel();
  @Input() opporunityID: string;
  searchResultCustomers = [];
  @Output() selectCustomerEmitter = new EventEmitter<CustomerModel>();
  @Output() selectDomainEmitter = new EventEmitter<DomainModel>();
  add: boolean = false;
  approve: boolean = false;
  send: boolean = false;
  withoutAction: boolean = false;
  showSendToApprove: boolean = true;
  loginUsersData: any;
  currentCustomer: CustomerModel;
  isGWS: boolean;
  @Output() createEmitter = new EventEmitter<any>();
  @Output() approveEmitter = new EventEmitter<any>();
  @Output() editEmitter = new EventEmitter<any>();
  @Output() draftEmitter = new EventEmitter<any>();
  @Output() custEmitter = new EventEmitter<any>();
  @ViewChild('rejectSOWPopupModal')
  rejectsowPopupModal: RejectionPopupComponent;
  canSendToCustomer: boolean = false;
  @ViewChild('customerInfoPopup')
  customerInfoPopup: CustomerInfoPopupComponent;
  rejectModel = {
    reason: null,
    comments: null,
  };
  customerInfoAdded: any;
  saveText = 'Save & Send for Approval';

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private oppService: OpportunityService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    if (this.currentUser.isAdmin) this.saveText = 'Save';
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
    var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (!Utilities.isNullOrEmpty(cstjson))
      this.currentCustomer = JSON.parse(cstjson);

    effect(() => {
      this.customerInfoAdded =
        this.oppService.opportunity().customer_info_added;
    });
  }

  ngAfterViewInit(): void {
    if (this.sow?._id) {
      this.add = false;
      this.showSendToApprove =
        this.sow.draft && this.currentUser._id == this.sow.created_by_rfid;
      if (!this.sow.draft) {
        if (!this.sow.managerApprovalPart) this.setSowPhasesData();
        this.approve = this.sow.canApprove(this.currentUser);
        this.canSendToCustomer = this.sow.canSendToCustomer(this.currentUser);
      }
      this.cardData = {
        module: 'sow',
        customer_domain: this.cardData.customer_domain,
        customer_org: this.cardData.customer_org,
        domain_rfid: this.cardData.domain_rfid,
        googleDocUrl: this.sow.googleDocUrl,
        createdUser: {
          name: this.sow.user_sow.created_user_name,
          role: this.sow.user_sow.created_user_role,
          date: this.sow.created_at,
          text: 'Created sow',
          img: this.sow.user_sow.created_user_img,
        },
        approval_phases: this.sow?.approval_phases,
      };
      this.setSowPhasesData();
    } else if (this.sow) {
      this.add = true;
      this.cardData = {
        module: 'sow',
        customer_domain: this.cardData.customer_domain,
        customer_org: this.cardData.customer_org,
        domain_rfid: this.cardData.domain_rfid,
        googleDocUrl: this.sow.googleDocUrl,
        start_date: this.cardData.startDate,
      };
    }
    this.cardData.googleDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://docs.google.com/document/d/' +
        this.sow.google_doc_id +
        '/edit?usp=sharing&embed=true'
    );
  }

  ngOnInit(): void {}

  async onSearchCustomerEnter(e) {
    var value = e.term ?? e.target.value;
    if (value && value.length > 2) {
      if (!Utilities.isNullOrEmpty(value)) this.searchResultCustomers = [];
      var params = new HttpParams()
        .set(Unicode.CUSTOMER_FILTER, value)
        .set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
      await this.http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.SEARCH_FOR_CUSTOMERS_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.domains && resp.domains.length > 0) {
            this.searchResultCustomers = resp.domains ?? [];
          }
        });
    }
  }

  async selectCustomer() {
    if (this.domainSelected) {
      var domain = this.domainSelected;
      this.cardData.customer_domain = domain.domain;
      this.cardData.customer_org = domain.organisation_name;
      this.cardData.domain_rfid = domain._id;
      this.selectCustomerEmitter.emit(domain);

      var new_doc_name =
        this.datePipe.transform(new Date(), 'YYYY-MM-dd') +
        ' SOW - ' +
        domain.organisation_name;

      var params = new HttpParams()
        .set(Unicode.CUSTOMER_NAME, domain.organisation_name)
        .set(Unicode.FILE_ID, this.sow.google_doc_id)
        .set(Unicode.NEW_FILE_NAME, new_doc_name);
      await this.http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.RENAME_DOCUMENT, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.success == true) {
            console.log('updated successfully');
          }
        });
    }
  }

  createSOW(draft: boolean = false) {
    if (Utilities.isNullOrEmpty(this.cardData.domain_rfid)) {
      alert('No Customer Selected');
      return;
    }
    var receivers = [];
    var params = new HttpParams();
    if (!draft) {
      if (!Utilities.isNullOrEmpty(this.currentUser.manager_id))
        receivers.push(this.currentUser.manager_email);
      params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    }
    var sow = new SOWModel();
    sow.created_by_rfid = this.currentUser._id;
    sow.domain_rfid = this.cardData.domain_rfid;
    sow.opportunity_rfid = this.opporunityID;
    sow.is_gcp = !this.isGWS;
    sow.draft = draft;
    sow.google_doc_id = this.sow.google_doc_id;
    sow.approval_phases = [];
    sow.start_date = this.cardData.start_date;
    var oppDependencyOn = null;
    if (!Utilities.isNullOrEmpty(this.currentUser.manager_id)) {
      sow.approval_phases = [
        {
          role: Group.Engineer_Manager,
          name: this.currentUser.manager_name,
          email: this.currentUser.manager_email,
          id: this.currentUser.manager_id,
          img: this.currentUser.imageUrl,
        },
      ];
      oppDependencyOn = Group.ENGINEER;
    }
    if (!Utilities.isNullOrEmpty(this.opporunityID))
      params = params.set(Unicode.DEPENDENCY_ON, oppDependencyOn);
    this.http
      .post(Endpoint.GLOBAL_URL + Endpoint.ADD_SOW, sow, { params: params })
      .toPromise()
      .then((resp: any) => {
        if (resp?.sow_rfid) {
          this.sow._id = resp.sow_rfid;
          this.sow.draft = draft;
          this.sow.consumed_time = resp.consumed_time;
          this.sow.user_sow = {
            created_user_name: this.currentUser.user_name,
          };
          this.sow.created_at = new Date();
          this.sow.created_date_string = Utilities.toStringDateFormat(
            this.datePipe,
            new Date(),
            'dd MMM,yyyy-hh:mma'
          );
          this.add = false;
          //
          this.sow.approval_phases = sow.approval_phases;
          this.sow.managerApprovalPart = this.sow.approval_phases
            .slice()
            .reverse()
            .find(
              (x) =>
                x.role &&
                x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
            );
          this.createEmitter.emit(this.sow);
          alert(resp.message);
        } else {
          alert(resp.message);
        }
      });
  }

  approveSOW(reason: string = null) {
    var oppDependencyOn = null;
    var mainGrpName =
      this.currentUser.groups.find((x) => x.main == true)?.name ?? '';
    var initialPhases = this.sow.approval_phases;
    var approvalPhases = this.sow.approval_phases ?? [];
    var role = approvalPhases.find(
      (x) =>
        x.role && x.role.toLowerCase().indexOf(mainGrpName.toLowerCase()) >= 0
    );
    if (role && Utilities.isNullOrEmpty(role.date)) {
      role.name = this.currentUser.user_name;
      role.email = this.currentUser.email;
      role.img = this.currentUser.imageUrl;
      role.id = this.currentUser._id;
      role.date = new Date();
      role.approved = Utilities.isNullOrEmpty(reason);
      if (!Utilities.isNullOrEmpty(reason)) role.rejection_reason = reason;
    } else {
      var row: any = {
        role: Group.Engineer_Manager,
        name: this.currentUser.getFullName(),
        email: this.currentUser.email,
        img: this.currentUser.imageUrl,
        id: this.currentUser._id,
        date: new Date(),
        approved: Utilities.isNullOrEmpty(reason),
      };
      if (!Utilities.isNullOrEmpty(reason)) row.rejection_reason = reason;
      approvalPhases.push(row);
    }
    if (Utilities.isNullOrEmpty(reason)) {
      oppDependencyOn = 'PreSales';
    }
    this.sow.approval_phases = approvalPhases;
    var receivers = [this.sow.user_sow?.created_user_email];
    receivers.push(
      ...this.sow?.approval_phases
        ?.filter(
          (x) =>
            x.email &&
            x.email.toLowerCase() !== this.currentUser.email.toLowerCase()
        )
        .map((x) => x.email)
    );
    this.sow.approval_phases.forEach((phase) => {
      if (phase?.date && phase.date.$date?.$numberLong)
        phase.date = new Date(Number.parseInt(phase.date.$date?.$numberLong));
    });
    var body = {
      receivers: JSON.stringify(receivers),
      approval_phases: this.sow.approval_phases,
      sow_action: !Utilities.isNullOrEmpty(reason) ? 'reject' : 'approve',
      opportunity_rfid: this.sow.opportunity_rfid,
    };
    var params = new HttpParams();
    if (!Utilities.isNullOrEmpty(this.sow.opportunity_rfid))
      params = params.set(Unicode.DEPENDENCY_ON, oppDependencyOn);
    this.http
      .put(Endpoint.GLOBAL_URL + Endpoint.UPDATE_SOW + this.sow._id, body, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp?.approval_phases) {
          this.sow.approval_phases = resp.approval_phases;
          //
          this.setSowPhasesData();
          this.approveEmitter.emit(this.sow);
        } else {
          alert(resp.message);
        }
      });
  }

  rejectSOWPopup() {
    this.rejectsowPopupModal.show();
  }

  rejectSOW(reason) {
    if (!Utilities.isNullOrEmpty(reason)) {
      this.approveSOW(reason);
      this.rejectsowPopupModal.hide();
    } else alert('Add Rejection Reason please');
  }

  sendDraftSOWForApproval() {
    var receivers = !Utilities.isNullOrEmpty(this.currentUser.manager_id)
      ? [this.currentUser.manager_email]
      : [];
    var body = {
      receivers: JSON.stringify(receivers),
      sow_rfid: this.sow._id,
    };
    this.http
      .put(Endpoint.GLOBAL_URL + Endpoint.SEND_SOW_FOR_APPROVAL, body)
      .toPromise()
      .then((resp: any) => {
        if (resp?.sow_rfid) {
          this.sow.draft = false;
          //
          this.sow.managerApprovalPart = this.sow.approval_phases
            ?.slice()
            .reverse()
            .find(
              (x) =>
                x.role &&
                x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
            );
          this.sow.sendingToCustomerPart = this.sow.approval_phases
            ?.slice()
            .reverse()
            .find(
              (x) =>
                x.role && x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
            );
          this.draftEmitter.emit(this.sow);
          alert(resp.message);
        } else {
          alert(resp.message);
        }
      });
  }

  sendSOWToCustPopup() {
    this.customerInfoPopup.show();
  }
  sendSOWToCust(custInfo) {
    this.custEmitter.emit(custInfo);
    this.customerInfoPopup.hide();
  }

  setSowPhasesData() {
    if (this.sow?.approval_phases) {
      this.sow.managerApprovalPart = this.sow
        .getSortedApprovalPhasesByDateDesc()
        .find(
          (x) =>
            x.role &&
            x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
        );
      if (this.sow.managerApprovalPart && !this.sow.managerApprovalPart.date)
        this.sow.managerApprovalPart.current = true;

      this.cardData.managerApprovalPart = this.sow.managerApprovalPart;
    }
  }
}
