import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomainModel } from 'src/models/mongo/domain.model';
import { OrderModel } from 'src/models/mongo/order.model';
import { UserModel } from 'src/models/mongo/user.model';
import { Seats } from 'src/models/mongo/subcription.model';
import { SubscriptionService } from 'src/servcies/api/subscription.service';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { LicenseUpdateModalComponent } from 'src/shared-components/license-update-modal/license-update-modal.component';
import {
  Unicode,
  Endpoint,
  PlanValues,
  Status,
  OrderAction,
  OrderStatus,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'lib-customer-subscriptions',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customer-subscriptions.component.html',
  styleUrls: ['./customer-subscriptions.component.scss'],
})
export class CustomerSubscriptionsComponent implements OnInit {
  currentUser: UserModel;
  currentCustomer: DomainModel = new DomainModel();
  allSubcs = [];
  plans = [];
  googleProducts = [];
  selectSubscriptionId: string;
  @ViewChild('startPaidServiceModal') startPaidServiceModal: ModalDirective;
  @ViewChild('statusModal') statusModal: ModalDirective;
  @ViewChild('renewalSettingsModal') renewalSettingsModal: ModalDirective;
  // @ViewChild('changeLicenseModal') changeLicenseModal: ModalDirective;
  @ViewChild('changeLicenseModal')
  changeLicenseModal: LicenseUpdateModalComponent;
  loginUsersData;
  statusTextAction: string;
  newRenewalSettingsType: string;
  prices = [];
  updateLicenseText: string = 'Additional Licenses Needed';
  globalData = [];
  opportunitySources = [];
  opportunityStatus = [];
  companySections = [];
  action: string = '';
  canCreateOrderAction: boolean;
  changeLicenseInfo: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private localStorage: LocalStorageService,
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.canCreateOrderAction =
      !this.currentUser.isViewerOnly &&
      (this.currentUser.isSales ||
        this.currentUser.isOperations ||
        this.currentUser.isFinanceTeam);
  }

  async ngOnInit(): Promise<void> {
    var paramsJson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (paramsJson) {
      this.currentCustomer = JSON.parse(paramsJson);
    }
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    var usersInfojson = this.localStorage.get(Unicode.USERS_INFO);
    this.loginUsersData = usersInfojson ? JSON.parse(usersInfojson) : {};
    var globalData = await Utilities.globalData(this.http, this.localStorage);
    var gData = Utilities.getGlobalDataValues(globalData);
    if (gData) {
      this.opportunitySources = gData.opportunitySources;
      this.opportunityStatus = gData.opportunityStatus;
      this.companySections = gData.companySections;
    }
    /** GET SUBSC. BY CUSTOMER ID **/
    if (!Utilities.isNullOrEmpty(this.currentCustomer.customer_api_id)) {
      await this.subscriptionService
        .requestSubscriptions(this.currentCustomer.customer_api_id)
        .then((resp: any) => {
          if (resp) this.allSubcs.push(...resp);
          this.allSubcs.forEach((subs) => {
            if (!subs.seats) {
              subs.seats = new Seats();
              subs.seats.numberOfSeats = 0;
            }
            if (
              subs.billingMethod
                .toLowerCase()
                .includes(Unicode.OFFLINE_BILLING_METHOD)
            ) {
              if (
                subs.plan.planName
                  .toLowerCase()
                  .includes(PlanValues.ANNUAL.toLowerCase())
              )
                subs.displayedPlanName = 'Commitment Offline Plan';
              else subs.displayedPlanName = subs.plan.planName + '/Offline';
            } else {
              if (subs.plan.planName.toUpperCase() == PlanValues.ANNUAL)
                subs.displayedPlanName = PlanValues.ANNUAL;
              else subs.displayedPlanName = subs.plan.planName;
            }
            //
            //fileds
            //if (subs.suspensionReasons && subs.suspensionReasons.find(x => x == Unicode.RESELLER_INITIATED))
            if (
              subs.plan.planName == Unicode.FLEXIBLE_PLAN ||
              subs.plan.planName == Unicode.TRIAL_PLAN
            ) {
              subs.seatsNbr = subs.seats?.maximumNumberOfSeats ?? 0;
              if (subs.plan.planName == Unicode.FLEXIBLE_PLAN) {
                subs.licensesSuffixText = ' /month';
              } else subs.licensesSuffixText = '';
            } else {
              subs.seatsNbr = subs.seats?.numberOfSeats ?? 0;
              if (
                subs.plan.planName.toUpperCase() == PlanValues.ANNUAL_YEARLY_PAY
              )
                subs.licensesSuffixText = ' /year';
              else subs.licensesSuffixText = ' /month';
            }
            if (subs.status == Status.Active) {
              subs.txtStatusEvent = 'Suspend';
              subs.canActivate = true;
            } else if (subs.status == Status.Suspended) {
              subs.txtStatusEvent = 'Activate';
              if (
                subs.suspensionReasons &&
                subs.suspensionReasons.find(
                  (x) => x == Unicode.RESELLER_INITIATED
                )
              )
                subs.canActivate = true;
              else subs.canActivate = false;
            }
          });
        });
    }
  }

  changePlan(subId) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subId);
    this.router.navigate(['/orders/add-order'], {
      queryParams: {
        isGWS: true,
        customer_api_id: this.currentCustomer.customer_api_id,
        domain_rfid: this.currentCustomer._id,
        domain: this.currentCustomer.domain,
        organisation: this.currentCustomer.organisation_name,
        subAction: true,
        updatePlan: true,
        skuId: subs.skuId,
        planName: subs.plan.planName,
        planId: this.plans.find((x) => x.code == subs.plan?.planName)._id,
        license: subs.seatsNbr,
        subscription_api_id: subs.subscriptionId,
      },
      skipLocationChange: true,
    });
  }

  downUpgrade(subId, isUpgrade: boolean) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subId);
    var availableSKUs = this.googleProducts.find(
      (x) => subs.skuId && x.sku_id == subs.skuId
    );
    var availableSKUIDs = isUpgrade
      ? availableSKUs?.upgrade_ids
      : availableSKUs.downgrade_ids;
    if (!availableSKUIDs) {
      alert('No Available SKUs');
      return;
    }
    var params: any = {
      isGWS: true,
      customer_api_id: this.currentCustomer.customer_api_id,
      domain_rfid: this.currentCustomer._id,
      domain: this.currentCustomer.domain,
      organisation: this.currentCustomer.organisation_name,
      email: this.currentCustomer.contact_person_email,
      subAction: true,
      availableSKUIDs: availableSKUIDs,
      skuName: subs.skuName,
      skuId: subs.skuId,
      planId: this.plans.find((x) => x.code == subs.plan?.planName)._id,
      license: subs.seatsNbr,
      subscription_api_id: subs.subscriptionId,
    };
    if (isUpgrade) params.upgrade = true;
    else params.downgrade = true;
    this.router.navigate(['/orders/add-order'], {
      queryParams: params,
      skipLocationChange: true,
    });
  }
  renew(subId) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subId);
    this.router.navigate(['/orders/add-order'], {
      queryParams: {
        isGWS: true,
        customer_api_id: this.currentCustomer.customer_api_id,
        domain_rfid: this.currentCustomer._id,
        domain: this.currentCustomer.domain,
        organisation: this.currentCustomer.organisation_name,
        subAction: true,
        renew: true,
        skuId: subs.skuId,
        planId: this.plans.find((x) => x.code == subs.plan?.planName)._id,
        planName: subs.plan.planName,
        skuName: subs.skuName,
        license: subs.seatsNbr,
        subscription_api_id: subs.subscriptionId,
        end_api_date: subs.plan?.commitmentInterval?.endTime,
      },
      skipLocationChange: true,
    });
  }

  startPaidServiceConfirmation(subId) {
    this.selectSubscriptionId = subId;
    this.startPaidServiceModal.show();
  }
  async startPaidService() {
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    if (sub.plan.planName.toUpperCase() !== PlanValues.TRIAL) {
      alert('Start paid service only from a free trial subscription');
      return;
    }
    var order = new OrderModel();
    order.action = OrderAction.STRAT_PAID_SERVICE;
    this.action = OrderAction.STRAT_PAID_SERVICE;
    order.created_by_rfid = this.currentUser._id;
    order.domain_rfid = this.currentCustomer._id;
    order.customer_api_id = this.currentCustomer.customer_api_id;
    order.customer_domain = this.currentCustomer.domain;
    order.is_gcp = false;
    order.order_status = this.currentUser.isExecutor
      ? OrderStatus.Executed
      : OrderStatus.Pending;
    order.sku = [
      {
        sku_id: sub.skuId,
        sku_name: sub.skuName,
        plan_id: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        license: sub.seatsNbr,
        subscription_api_id: sub.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
      },
    ];
    var toExecute = this.currentUser.isExecutor ? '1' : '0';
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    var receivers =
      Utilities.setReceivers(this.currentUser, this.loginUsersData) ?? [];
    params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    await this.http
      .post(Endpoint.API_URL + '' + Endpoint.ADD_ORDER, order, {
        params: params,
      })
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        if (resp && resp.success == true && !Utilities.isNullOrEmpty(resp.id)) {
          order._id = resp.id;
          this.startPaidServiceModal.hide();
        }
      });
  }

  updateStatusConfirmation(subId, active: boolean) {
    this.selectSubscriptionId = subId;
    if (active == true) this.statusTextAction = 'suspend';
    else this.statusTextAction = 'activate';
    this.statusModal.show();
  }
  async updateStatus1() {
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    var order = new OrderModel();
    var toSuspend = sub.status == Status.Active;
    order.action = toSuspend ? OrderAction.SUSPEND : OrderAction.ACTIVATE;
    this.action = toSuspend ? OrderAction.SUSPEND : OrderAction.ACTIVATE;
    order.created_by_rfid = this.currentUser._id;
    order.domain_rfid = this.currentCustomer._id;
    order.customer_domain = this.currentCustomer.domain;
    order.customer_api_id = this.currentCustomer.customer_api_id;
    order.is_gcp = false;
    order.order_status = this.currentUser.isExecutor
      ? OrderStatus.Executed
      : OrderStatus.Pending;
    order.sku = [
      {
        sku_id: sub.skuId,
        sku_name: sub.skuName,
        plan_id: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        license: sub.seatsNbr,
        subscription_api_id: sub.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
      },
    ];
    var toExecute = this.currentUser.isExecutor ? '1' : '0';
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    var receivers =
      Utilities.setReceivers(this.currentUser, this.loginUsersData) ?? [];
    params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    await this.http
      .post(Endpoint.API_URL + '' + Endpoint.ADD_ORDER, order, {
        params: params,
      })
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        if (resp && resp.success == true && !Utilities.isNullOrEmpty(resp.id)) {
          order._id = resp.id;
          this.statusModal.hide();
        }
      });
  }

  async updateStatus() {
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    var order = new OrderModel();
    var toSuspend = sub.status == Status.Active;
    order.action = toSuspend ? OrderAction.SUSPEND : OrderAction.ACTIVATE;
    this.action = toSuspend ? OrderAction.SUSPEND : OrderAction.ACTIVATE;
    order.created_by_rfid = this.currentUser._id;
    order.domain_rfid = this.currentCustomer._id;
    order.customer_domain = this.currentCustomer.domain;
    order.is_gcp = false;
    order.order_status = OrderStatus.Pending;
    order.sku = [
      {
        sku_id: sub.skuId,
        sku_name: sub.skuName,
        plan_id: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        license: sub.seatsNbr,
        subscription_api_id: sub.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
      },
    ];
    await this.http
      .post(Endpoint.API_URL + '' + Endpoint.SEND_APPROVAL_REQUEST, order)
      .toPromise()
      .then(async (resp: any) => {
        if (resp?.success == true) {
          this.statusModal.hide();
          var that = this;
          var action = !toSuspend ? 'Activation' : 'Suspension';
          var actionVerb = !toSuspend ? 'activate' : 'suspend';
          var actionAdj = !toSuspend ? 'active' : 'suspended';
          that.confirmDialogService.confirmThis(
            'your request to ' +
              actionVerb +
              ' ' +
              sub.skuName +
              ' for ' +
              that.currentCustomer.domain +
              ' has been sent to the CEO. They will not get ' +
              actionAdj +
              ' until it is approved by the CEQ himself.',
            action + ' request sent',
            'info',
            function () {},
            function () {}
          );
        }
      });
  }

  updateRenewalSettingsConfirmation(subId) {
    this.newRenewalSettingsType = '';
    this.selectSubscriptionId = subId;
    this.renewalSettingsModal.show();
  }
  async updateRenewalSettings() {
    if (Utilities.isNullOrEmpty(this.newRenewalSettingsType)) {
      alert('Select Renewal type');
      return;
    }
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    var order = new OrderModel();
    order.action = OrderAction.UPDATE_RNEWAL_SETTNGS;
    this.action = OrderAction.UPDATE_RNEWAL_SETTNGS;
    order.created_by_rfid = this.currentUser._id;
    order.domain_rfid = this.currentCustomer._id;
    order.customer_domain = this.currentCustomer.domain;
    order.customer_api_id = this.currentCustomer.customer_api_id;
    order.is_gcp = false;
    order.order_status = this.currentUser.isExecutor
      ? OrderStatus.Executed
      : OrderStatus.Pending;
    order.sku = [
      {
        sku_id: sub.skuId,
        sku_name: sub.skuName,
        plan_id: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        license: sub.seatsNbr,
        subscription_api_id: sub.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
        renewel_setting_type: this.newRenewalSettingsType,
      },
    ];
    var toExecute = this.currentUser.isExecutor ? '1' : '0';
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    var receivers =
      Utilities.setReceivers(this.currentUser, this.loginUsersData) ?? [];
    params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    await this.http
      .post(Endpoint.API_URL + Endpoint.ADD_ORDER, order, {
        params: params,
      })
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        if (resp && resp.success == true && !Utilities.isNullOrEmpty(resp.id)) {
          order._id = resp.id;
          this.renewalSettingsModal.hide();
          if (this.newRenewalSettingsType == 'RENEW_ON_PROPOSED_OFFER') {
            this.router.navigate(['deals/add-deal'], {
              queryParams: {},
              skipLocationChange: true,
            });
          }
        }
      });
  }

  async changeLicensePopup(subId) {
    this.selectSubscriptionId = subId;
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    this.prices = await Utilities.getPrices(this.http, this.localStorage);
    var isFlexible = sub.plan?.planName?.toLowerCase().indexOf('flexible') >= 0;
    if (isFlexible) this.updateLicenseText = 'New License Cap';
    else this.updateLicenseText = 'Additional Licenses Needed';

    // this.LicenseForm = this.fb.group({
    //   current_licenses: [
    //     { value: sub.seatsNbr ?? 0, disabled: true },
    //     ,
    //     Validators.required,
    //   ],
    //   sku_name: [{ value: sub.skuName, disabled: true }, Validators.required],
    //   plan_id: [
    //     this.plans.find((x) => x.code == sub.plan?.planName)._id,
    //     Validators.required,
    //   ],
    //   plan_name: [sub.plan?.planName, Validators.required],
    //   sku_id: [sub.skuId, Validators.required],
    //   additional_licenses: [0, Validators.required],
    //   po_id: [null, Validators.required],
    //   order_sale_invoice_nbr: [null, Validators.required],
    //   opportunity_status: [null, Validators.required],
    //   opportunity_source: [null, Validators.required],
    //   customer_email_notification: [''],
    //   start_date: [
    //     this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    //     Validators.required,
    //   ],
    //   end_date: [
    //     sub.plan?.commitmentInterval?.endTime
    //       ? this.datePipe.transform(
    //           new Date(
    //             Number.parseFloat(sub.plan?.commitmentInterval?.endTime)
    //           ),
    //           'yyyy-MM-dd'
    //         )
    //       : null,
    //     Validators.required,
    //   ],
    //   is_flexible: [isFlexible],
    //   annual_months: [null],
    //   with_customer_offer: [false, Validators.required],
    //   customer_offer: [0],
    //   with_google_offer: [false, Validators.required],
    //   google_offer: [0],
    //   cost_price: [0],
    //   selling_price: [0],
    //   company_section: [null, Validators.required],
    // });
    //
    this.changeLicenseInfo = sub;
    this.changeLicenseInfo.seatsNbr = Utilities.getLicensesByPlan(sub);
    this.changeLicenseInfo.customerRfid = this.currentCustomer._id;
    this.changeLicenseInfo.customer_domain = this.currentCustomer.domain;
    this.changeLicenseModal.show(this.changeLicenseInfo);
  }

  createOrder() {
    this.router.navigate(['/orders/add-order'], {
      queryParams: {
        isGWS: true,
        customer_api_id: this.currentCustomer.id,
        domain_rfid: this.currentCustomer._id,
        domain: this.currentCustomer.domain,
        organisation: this.currentCustomer.organisation_name,
      },
      skipLocationChange: true,
    });
  }
}
