import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'add-budget-form',
  templateUrl: './add-budget-form.component.html',
  styleUrls: ['./add-budget-form.component.scss'],
})
export class AddBudgetFormComponent implements OnInit {
  currentUser: UserModel;
  budgetForm: FormGroup;
  periods = Utilities.BUDGET_PERIOD;
  @Output() createBudgetEmitter = new EventEmitter<any>();
  @Input() billingRfId: string;
  budget: any;

  constructor(
    private localStorage: LocalStorageService,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    var budgetJson = localStorage.get(Unicode.CURRENT_BUDGET);
    if (!Utilities.isNullOrEmpty(budgetJson))
      this.budget = JSON.parse(budgetJson);
    this.budgetForm = fb.group({
      name: [this.budget?.name || null, Validators.required],
      amount: [this.budget?.amount || null, Validators.required],
      period: [this.budget?.period?.type || null],
      alerts: this.fb.array([]),
    });
    if (this.budget?.alerts?.length > 0) {
      this.budget.alerts.forEach((element) => {
        this.alerts.push(
          this.fb.group({
            percentage: [element * 100],
            amount: [((this.budget.amount * element * 100) / 100).toFixed(2)],
          })
        );
      });
    }
  }

  ngOnInit(): void {
    this.alerts.push(
      this.fb.group({
        amount: [null],
        percentage: [null],
      })
    );
  }
  addAlertRow() {
    this.alerts.push(
      this.fb.group({
        amount: [null],
        percentage: [null],
      })
    );
  }

  saveBudget() {
    if (this.budgetForm.valid) {
      var budget = this.budgetForm.value;
      var body: any = {
        id: this.billingRfId,
        name: budget.name,
        amount: budget.amount,
        bId: this.budget?.id,
      };
      if (budget.period) {
        body.period = {
          type: budget.period,
        };
      }
      if (
        budget.alerts &&
        budget.alerts.length > 0 &&
        budget.alerts[0].percentage > 0
      ) {
        body.alerts = budget.alerts.map((item) => {
          return item.percentage / 100;
        });
      }
      this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.CREATE_BUDGET, body)
        .toPromise()
        .then((resp: any) => {
          if (resp?.success == true) {
            alert(resp?.message);
            this.createBudgetEmitter.emit({
              name: budget.name,
              amount: budget.amount,
              alerts: budget.alerts,
              period: body.period,
              id: this.budget?.id ?? resp.id,
            });
          } else {
            alert(resp?.message);
          }
        });
    }
  }
  updateAlertPercentage(alert, event) {
    var value = event.target.value;
    if (value && value > 0 && this.budgetForm.value.amount) {
      var amount = ((this.budgetForm.value.amount * value) / 100).toFixed(2);
      alert.controls['amount'].setValue(amount);
    }
  }

  updateAlertAmount(alert, event) {
    var value = event.target.value;
    if (value && value > 0 && this.budgetForm.value.amount) {
      var perc = ((value * 100) / this.budgetForm.value.amount).toFixed(2);
      alert.controls['percentage'].setValue(perc);
    }
  }

  get name() {
    return this.budgetForm.get('name');
  }
  get amount() {
    return this.budgetForm.get('amount');
  }
  get period() {
    return this.budgetForm.get('period');
  }
  get alerts(): FormArray {
    return this.budgetForm.get('alerts') as FormArray;
  }
}
