import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { OpportunityModel } from 'src/models/mongo/opportunity.model';

@Component({
  selector: 'lib-opportunity-list',
  templateUrl: './opportunity-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./opportunity-list.component.scss'],
})
export class OpportunityListComponent implements OnInit {
  @HostBinding('class') class = ' ';
  opportunities: OpportunityModel[] = [];
  plans = [];
  static googleProducts = [];
  currentUser: UserModel;
  static _httpRequestService: HttpRequestService;
  static _http: HttpClient;
  customerSearch: string;
  fromSelectedDate;
  toSelectedDate;
  salesManager: string;
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  static opportunities = [];
  static pagination: number = 1;
  static total: number = 1;
  static gcp: boolean;
  selectedFilterType: string = 'ALL';
  productTypes = ['ALL', 'GWS', 'GCP'];
  customerForm: UntypedFormGroup;
  opportunityForm: UntypedFormGroup;
  customerRfId;
  opportunityRfId;
  customerId;
  initial_userIds = [];
  add: boolean = true;
  users = [];
  disableSave: boolean = false;
  selectedUserId;
  test;
  statusOptions = ['In Progress', 'Won', 'Lost'];
  DependencyOptions = [
    'Finance',
    'Engineer',
    'VRM',
    'Sales',
    'Customer',
    'Google',
  ];
  selectedStatus: string;
  selectedDependency: string;
  selectedSales: string;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private location: Location
  ) {
    //
    OpportunityListComponent._http = http;
    OpportunityListComponent.gcp = false;
    var current = new Date();
    var beforeOneMonth = current.setMonth(current.getMonth() - 1);
    this.fromSelectedDate = new Date(beforeOneMonth).toISOString().slice(0, 10);
    this.toSelectedDate = new Date().toISOString().slice(0, 10);
    localStorage.remove(Unicode.CURRENT_CUSTOMER);
  }

  async ngOnInit(): Promise<void> {
    this.users = await Utilities.usersByGroup(
      Group.Sale,
      this.http,
      this.localStorage
    );
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    OpportunityListComponent.pagination = 1;
    this.getopportunities(null);
  }

  async getopportunities(pagination) {
    if (pagination == null) pagination = OpportunityListComponent.pagination;
    var prs = new HttpParams()
      .set(
        Unicode.PAGE,
        (pagination - 1) * OpportunityListComponent.pageSize + ''
      )
      .set(Unicode.LIMIT, OpportunityListComponent.pageSize + '')
      .set(Unicode.OPPORTUNITY_TYPE, this.selectedFilterType);
    prs = prs.set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
    if (!Utilities.isNullOrEmpty(this.customerSearch))
      prs = prs.set(Unicode.DOMAIN_FILTER_VALUE, this.customerSearch);
    if (!Utilities.isNullOrEmpty(this.fromSelectedDate))
      prs = prs.set(Unicode.FROM_DATE, this.fromSelectedDate);
    if (!Utilities.isNullOrEmpty(this.toSelectedDate))
      prs = prs.set(Unicode.TO_DATE, this.toSelectedDate);
    if (!Utilities.isNullOrEmpty(this.selectedStatus))
      prs = prs.set(Unicode.OPPORTUNITY_STATUS, this.selectedStatus);
    if (!Utilities.isNullOrEmpty(this.selectedSales))
      prs = prs.set(Unicode.SALES_MANAGER, this.selectedSales);
    if (!Utilities.isNullOrEmpty(this.selectedDependency))
      prs = prs.set(Unicode.OPPORTUNITY_DEPENDENCY, this.selectedDependency);
    await OpportunityListComponent._http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.GET_ALL_OPPORTUNITIES, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp) {
          OpportunityListComponent.total = <number>resp['total'];
          OpportunityListComponent.opportunities = resp.opportunities ?? [];
        }
      });
  }

  renderPage(event: number) {
    OpportunityListComponent.pagination = event;
    this.getopportunities(null);
  }

  search() {
    OpportunityListComponent.pagination = 1;
    this.getopportunities(null);
  }
  selectDate() {
    OpportunityListComponent.pagination = 1;
    this.getopportunities(null);
  }
  selectSale() {
    OpportunityListComponent.pagination = 1;
    this.getopportunities(null);
  }
  selectStatus() {
    OpportunityListComponent.pagination = 1;
    this.getopportunities(null);
  }
  selectDependency() {
    OpportunityListComponent.pagination = 1;
    // if (this.selectedDependency.includes('/')) {
    //   this.selectedDependency = ['engineer', 'presales'].join(',');
    // }
    // // If selectedDependency is "VRM/GPM", assign "VRM,GPM" to a separate variable
    // const vrmGpmOptions = ['vrm', 'gpm'].join(',');
    //
    // if (this.selectedDependency === 'vrm/gpm') {
    //   this.selectedDependency = vrmGpmOptions;
    // }
    this.getopportunities(null);
  }

  viewopportunity(id) {
    var opportunity: OpportunityModel =
      OpportunityListComponent.opportunities.find((x) => x._id == id);
    var domain = opportunity.domain;
    this.localStorage.save(Unicode.CURRENT_CUSTOMER, JSON.stringify(domain));
    this.router.navigate(['opportunities/opportunity-details'], {
      state: { id: id },
    });
  }

  switchType(e) {
    e.target.classList.toggle('toggled');
    OpportunityListComponent.pagination = 1;
    OpportunityListComponent.gcp = !OpportunityListComponent.gcp;
    this.getopportunities(null);
  }

  applyFilter(type: string): void {
    if (type != this.selectedFilterType) {
      OpportunityListComponent.pagination = 1;
      this.selectedFilterType = type;
      this.getopportunities(null);
    }
  }

  get GetData() {
    return OpportunityListComponent.opportunities;
  }
  get GetTotalCount() {
    return OpportunityListComponent.total;
  }
  get GetPagination() {
    return OpportunityListComponent.pagination;
  }
  get GetPageSize() {
    return OpportunityListComponent.pageSize;
  }
  async assignSales(selectedUser: any) {
    if (
      !this.add &&
      !Utilities.isNullOrEmpty(this.customerRfId) &&
      !Utilities.isNullOrEmpty(this.opportunityForm.value.user_ids)
    ) {
      var add_ids = this.opportunityForm.value.user_ids.filter(
        (x) => this.initial_userIds.indexOf(x) < 0
      );
      var delete_ids = this.initial_userIds.filter(
        (x) => this.opportunityForm.value.user_ids.indexOf(x) < 0
      );
      await this.http
        .put(Endpoint.GLOBAL_URL + '' + Endpoint.ASSIGN_USER_CUSTOMER, {
          user_ids_attach: add_ids ?? [],
          user_ids_detach: delete_ids ?? [],
          customer_id: this.customerRfId,
        })
        .toPromise()
        .then(async (resp: any) => {
          this.initial_userIds = this.opportunityForm.value.user_ids;
          alert(resp?.msg);
          // Fetch opportunities based on the selected user
          const opportunities = await this.http
            .get(
              Endpoint.GLOBAL_URL +
                Endpoint.ASSIGN_USER_CUSTOMER +
                '/' +
                this.selectedUserId
            )
            .toPromise();
        })
        .catch((err) => {
          alert(err.statusText ?? err.msg);
        });
    }
  }
  refreshPage() {
    location.reload();
  }
  goBack(): void {
    this.location.back();
  }
  deleteOpportunity(id: string) {
    if (
      confirm(
        'If you delete this opportunity, all related docs will be deleted. Are you sure to delete it?'
      )
    ) {
      this.http
        .put(Endpoint.GLOBAL_URL + '' + Endpoint.DELETE_OPPORTUNITY + id, {})
        .subscribe((response: any) => {
          this.getopportunities(null);
          alert(response.msg);
        });
    }
  }
}
