import { SkuStatusModalComponent } from 'src/shared-components/sku-status-modal/sku-status-modal.component';
import { Utilities } from 'src/shared/utilities';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserModel } from 'src/models/mongo/user.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PaidServiceModalComponent } from 'src/shared-components/paid-service-modal/paid-service-modal.component';
import { RenewalSettingsModalComponent } from 'src/shared-components/renewal-settings-modal/renewal-settings-modal.component';
import { LicenseUpdateModalComponent } from 'src/shared-components/license-update-modal/license-update-modal.component';
import { SubscriptionService } from 'src/servcies/api/subscription.service';
import { Location } from '@angular/common';
import { DomainModel } from '../../../../src/models/mongo/domain.model';
import { GwsGcpPopupComponent } from 'src/shared-components/gws-gcp-popup/gws-gcp-popup.component';

@Component({
  selector: 'lib-customers',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = ' ';
  customers: DomainModel[] = [];
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  static pagination: number = 1;
  static total: number = 1;
  static currentUser: UserModel;
  canCreateCustomer: boolean;
  pageTitle = '';
  static canViewAllCustomers: boolean;
  static httpRequestService;
  customerSearch: string = '';
  selectedDomain: DomainModel;
  rejectionReasons: any[] = [];
  CutomerDeleteReason: string;
  static router: Router;
  @ViewChild('confirmDeletionPopup') confirmDeletionPopup: ModalDirective;
  selectedId;
  @ViewChild('statusModal') statusModal: SkuStatusModalComponent;
  @ViewChild('renewalSettingsModal')
  renewalSettingsModal: RenewalSettingsModalComponent;
  @ViewChild('startPaidServiceModal')
  startPaidServiceModal: PaidServiceModalComponent;
  @ViewChild('changeLicenseModal')
  changeLicenseModal: LicenseUpdateModalComponent;
  loginUsersData;
  plans = [];
  googleProducts = [];
  @ViewChild('gwsGcpOppModal')
  gwsGcpOppModal: GwsGcpPopupComponent;
  currentUser: UserModel;
  @ViewChild('orderDealModl') orderDealModl: ModalDirective;
  canCreateOrderAction: boolean;

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private subscriptionService: SubscriptionService,
    private location: Location
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.canCreateOrderAction =
      !this.currentUser.isViewerOnly &&
      (this.currentUser.isSales ||
        this.currentUser.isOperations ||
        this.currentUser.isAdmin ||
        this.currentUser.isFinanceTeam);
    CustomersComponent.router = router;
    this.localStorage.remove(Unicode.CURRENT_CUSTOMER);
  }

  ngAfterViewInit(): void {}

  async ngOnInit(): Promise<void> {
    CustomersComponent.currentUser = this.localStorage.getLoggedinUserModel();
    CustomersComponent.pagination = 1;
    var usersInfojson = this.localStorage.get(Unicode.USERS_INFO);
    this.loginUsersData = usersInfojson ? JSON.parse(usersInfojson) : {};
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    var globalData = await Utilities.globalData(this.http, this.localStorage);
    var gData = Utilities.getGlobalDataValues(globalData);
    this.rejectionReasons = gData?.custRejectionReasons;
    this.getCustomers();
  }

  async getCustomers() {
    var prs = new HttpParams()
      .set(
        Unicode.PAGE,
        (CustomersComponent.pagination - 1) * CustomersComponent.pageSize + ''
      )
      .set(Unicode.LIMIT, CustomersComponent.pageSize + '');
    prs = prs.set(Unicode.VIEW_ALL, CustomersComponent.currentUser.canViewAll);
    if (!Utilities.isNullOrEmpty(this.customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, this.customerSearch);
    await this.http
      .get<any>(Endpoint.API_URL + '' + Endpoint.CUSTOMER_LIST, {
        params: prs,
      })
      .toPromise()
      .then(async (resp) => {
        if (resp) {
          CustomersComponent.total = resp.total;
          this.customers = resp.customers ?? [];
        }
      });
  }

  renderPage(event: number) {
    CustomersComponent.pagination = event;
    this.getCustomers();
  }

  search() {
    CustomersComponent.pagination = 1;
    this.getCustomers();
  }
  async searchCustomer(event) {
    this.customerSearch = event.target.value;

    await this.getCustomers();
  }

  createNewOpportunityClick(domain) {
    this.selectedDomain = new DomainModel(domain);
    this.gwsGcpOppModal.showPopup();
  }
  addOpportunity(isGWS: boolean) {
    if (!Utilities.isNullOrEmpty(this.selectedDomain)) {
      this.localStorage.save(
        Unicode.CURRENT_CUSTOMER,
        JSON.stringify(this.selectedDomain)
      );
      if (isGWS) this.orderDealModl.show();
      else
        this.router.navigate(['/opportunities/opportunity-details'], {
          state: {
            isGWS: isGWS,
          },
        });
    }
  }

  viewDetails(domain: DomainModel) {
    this.localStorage.save(Unicode.CURRENT_CUSTOMER, JSON.stringify(domain));
    this.router.navigate(['customers/customer-services/subscriptions'], {
      skipLocationChange: false,
    });
  }

  editCustomer(id: any, auth_token: string) {
    var customer = this.customers.find((x) => x.customer_rfid == id);
    this.localStorage.save(Unicode.CURRENT_CUSTOMER, JSON.stringify(customer));
    this.router.navigate(['customers/customer-create'], {
      state: { customerRfid: id },
      queryParams: {
        isCreationOrTransfer: Utilities.isNullOrEmpty(auth_token),
      },
    });
  }

  deleteCustomerConfirmation(id) {
    this.selectedId = id;
  }

  deleteCustomer() {
    if (!this.CutomerDeleteReason) {
      this.CutomerDeleteReason = this.rejectionReasons[0];
    }

    var prs = new HttpParams().set('reason', this.CutomerDeleteReason);
    this.http
      .put(
        Endpoint.API_URL +
          '' +
          Endpoint.DELETE_CUSTOMER +
          '/' +
          this.selectedId,
        prs
      )
      .toPromise()
      .then((resp: any) => {
        // this.confirmDeletionPopup.hide();
        var index: number = this.customers.findIndex(
          (x) => x.customer_rfid == this.selectedId
        );
        this.customers.splice(index, 1);
        alert(resp?.message);
      });
  }

  async expandCustomer(index, customerId: string) {
    var id = 'customer-collapse-' + index;
    var elt = document.getElementById(id);
    if (elt.classList.contains('show')) {
      elt.classList.remove('show');
    } else {
      if (!Utilities.isNullOrEmpty(customerId)) {
        var customer = this.customers.find(
          (x) => x.customer_api_id == customerId
        );
        if (customer && (!customer.skus || customer.skus.length == 0)) {
          //GET CUSTOMERS SKUS/SUBSCRIPTIONS
          await this.subscriptionService
            .requestSubscriptions(customerId)
            .then((resp: any) => {
              if (resp) {
                customer.skus = resp;
                customer.skus.forEach((x) => {
                  x.seatsDetails =
                    (x.plan?.planName ?? '') == Unicode.FLEXIBLE_PLAN ||
                    (x.plan?.planName ?? '') == Unicode.TRIAL_PLAN
                      ? Utilities.displayCheck(x.seats?.licensedNumberOfSeats) +
                        '/' +
                        Utilities.displayCheck(x.seats?.maximumNumberOfSeats)
                      : Utilities.displayCheck(x.seats?.licensedNumberOfSeats) +
                        '/' +
                        Utilities.displayCheck(x.seats?.numberOfSeats);
                });
              }
            });
        }
        //
        // customer.skus = [
        //   {
        //     skuName: 'starter',
        //     plan: { planName: 'flexible' },
        //     status: 'active',
        //   },
        // ];
        if (customer?.skus && customer.skus.length > 0) {
          const pattern = /Google Workspace/g;
          customer.skus.forEach((sku) => {
            if (sku.skuName && sku.skuName.match(pattern)) {
              sku.skuName = sku.skuName.replace(pattern, 'GWS');
            }
          });
          elt.classList.add('show');
        } else alert('No GWS SKUs');
      } else alert('Customer not created yet on GWS console');
    }
  }

  get GetTotalCount() {
    return CustomersComponent.total;
  }
  get GetPagination() {
    return CustomersComponent.pagination;
  }
  get GetPageSize() {
    return CustomersComponent.pageSize;
  }

  /*** SKU ACTIONS ***/
  @Input() changeLicenseInfo: any = {};
  changeLicensePopup(sub, customerRfid: string, domain: string) {
    this.changeLicenseInfo = sub;
    var seatsNbr = 0;
    if (
      this.changeLicenseInfo.plan.planName == Unicode.FLEXIBLE_PLAN ||
      this.changeLicenseInfo.plan.planName == Unicode.TRIAL_PLAN
    ) {
      seatsNbr = this.changeLicenseInfo.seats?.maximumNumberOfSeats ?? 0;
    } else {
      seatsNbr = this.changeLicenseInfo.seats?.numberOfSeats ?? 0;
    }
    this.changeLicenseInfo.seatsNbr = seatsNbr;
    this.changeLicenseInfo.customerRfid = customerRfid;
    this.changeLicenseInfo.customer_domain = domain;
    this.changeLicenseInfo.receivers =
      Utilities.setReceivers(
        CustomersComponent.currentUser,
        this.loginUsersData
      ) ?? [];
    this.changeLicenseModal.show(this.changeLicenseInfo);
  }

  downUpgrade(
    sub,
    isUpgrade: boolean,
    customerRfid,
    domain,
    organisation_name,
    contact_person_email
  ) {
    var availableSKUs = this.googleProducts.find(
      (x) => sub.skuId && x.sku_id == sub.skuId
    );
    var availableSKUIDs = isUpgrade
      ? availableSKUs?.upgrade_ids
      : availableSKUs.downgrade_ids;
    if (!availableSKUIDs) {
      alert('No Available SKUs');
      return;
    }
    var seatsNbr = 0;
    if (
      sub.plan.planName == Unicode.FLEXIBLE_PLAN ||
      sub.plan.planName == Unicode.TRIAL_PLAN
    ) {
      seatsNbr = sub.seats?.maximumNumberOfSeats ?? 0;
    } else {
      seatsNbr = sub.seats?.numberOfSeats ?? 0;
    }
    var params: any = {
      isGWS: true,
      customer_id: sub.customerId,
      id: customerRfid,
      // domain: domain, //todo check if i still use them from params
      // organisation: organisation_name,
      // email: contact_person_email,
      subAction: true,
      availableSKUIDs: availableSKUIDs,
      skuName: sub.skuName,
      skuId: sub.skuId,
      planId: this.plans.find((x) => x.code == sub.plan?.planName)._id,
      license: seatsNbr,
      subscription_api_id: sub.subscriptionId,
    };
    if (isUpgrade) params.upgrade = true;
    else params.downgrade = true;
    var domainModel = this.customers.find((x) => x._id == customerRfid);
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(domainModel)
    );
    this.router.navigate(['/orders/add-order'], {
      state: params,
      skipLocationChange: false,
    });
  }
  //Renewal Settings
  @Input() renewalSettingsInfo: any = {};
  updateRenewalSettingsConfirmation(sub, customerRfid) {
    this.renewalSettingsInfo = sub;
    this.renewalSettingsInfo.customerRfid = customerRfid;
    this.renewalSettingsInfo.receivers =
      Utilities.setReceivers(
        CustomersComponent.currentUser,
        this.loginUsersData
      ) ?? [];
    this.renewalSettingsModal.show();
  }

  //STATUS
  @Input() statusInfo: any = {};
  updateStatusConfirmation(sub, active: boolean, customerRfid) {
    this.statusInfo = sub;
    var seatsNbr = 0;
    if (
      sub.plan.planName == Unicode.FLEXIBLE_PLAN ||
      sub.plan.planName == Unicode.TRIAL_PLAN
    ) {
      seatsNbr = sub.seats?.maximumNumberOfSeats ?? 0;
    } else {
      seatsNbr = sub.seats?.numberOfSeats ?? 0;
    }
    this.statusInfo.seatsNbr = seatsNbr;
    this.statusInfo.customerRfid = customerRfid;
    this.statusInfo.customerId = sub.customerId;
    this.statusInfo.customer_domain = sub.customerDomain;
    this.statusInfo.receivers =
      Utilities.setReceivers(
        CustomersComponent.currentUser,
        this.loginUsersData
      ) ?? [];
    if (active == true) this.statusInfo.title = 'suspend';
    else this.statusInfo.title = 'activate';
    this.statusModal.show();
  }
  //Paid Service
  @Input() paidServiceInfo: any = {};
  startPaidServiceConfirmation(sub, customerRfid) {
    this.paidServiceInfo = sub;
    this.paidServiceInfo.customerRfid = customerRfid;
    this.paidServiceInfo.receivers =
      Utilities.setReceivers(
        CustomersComponent.currentUser,
        this.loginUsersData
      ) ?? [];
    this.startPaidServiceModal.show();
  }

  renew(sub, customerRfid, domain, organisation_name) {
    var seatsNbr = 0;
    if (
      sub.plan.planName == Unicode.FLEXIBLE_PLAN ||
      sub.plan.planName == Unicode.TRIAL_PLAN
    ) {
      seatsNbr = sub.seats?.maximumNumberOfSeats ?? 0;
    } else {
      seatsNbr = sub.seats?.numberOfSeats ?? 0;
    }
    this.router.navigate(['/orders/add-order'], {
      state: {
        isGWS: true,
        customer_api_id: sub.customerId,
        domain_rfid: customerRfid,
        domain: domain,
        organisation: organisation_name,
        subAction: true,
        renew: true,
        skuId: sub.skuId,
        planId: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        planName: sub.plan.planName,
        skuName: sub.skuName,
        license: seatsNbr,
        subscription_api_id: sub.subscriptionId,
        end_api_date: sub.plan?.commitmentInterval?.endTime,
      },
      skipLocationChange: false,
    });
  }

  changePlan(sub, customerRfid, domain, org) {
    var seatsNbr = 0;
    if (
      sub.plan.planName == Unicode.FLEXIBLE_PLAN ||
      sub.plan.planName == Unicode.TRIAL_PLAN
    ) {
      seatsNbr = sub.seats?.maximumNumberOfSeats ?? 0;
    } else {
      seatsNbr = sub.seats?.numberOfSeats ?? 0;
    }
    this.router.navigate(['/orders/add-order'], {
      state: {
        isGWS: true,
        customer_api_id: sub.customerId,
        domain_rfid: customerRfid,
        domain: domain,
        organisation: org,
        subAction: true,
        updatePlan: true,
        skuId: sub.skuId,
        planName: sub.plan.planName,
        planId: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        license: seatsNbr,
        subscription_api_id: sub.subscriptionId,
      },
      skipLocationChange: false,
    });
  }
  goBack(): void {
    this.location.back();
  }

  GWSTakeAction(order: boolean) {
    this.orderDealModl.hide();
    //GWS ORDER OR DEAL
    if (order) {
      if (
        Utilities.isNullOrEmpty(this.selectedDomain.customer_api_id) &&
        !this.selectedDomain.isGWSDomainValid()
      ) {
        alert('Check customer required fields for GWS order');
        this.router.navigate(['customers/customer-create'], {
          state: { customerRfid: this.selectedDomain.customer_rfid },
          queryParams: {
            isCreationOrTransfer: true,
          },
        });
        return;
      }
      this.router.navigate(['orders/add-order'], {
        queryParams: { isGWS: true },
      });
    } else {
      this.router.navigate(['deals/add-deal'], {
        queryParams: { isGWS: true },
      });
    }
  }
}
