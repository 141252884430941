import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Endpoint } from 'src/shared/Endpoint';
import { LocalStorageService } from './../../servcies/localstorage-service';
import { Group, Permissions, Unicode } from './../../shared/Endpoint';
import { Utilities } from './../../shared/utilities';
import { UserModel } from 'src/models/mongo/user.model';
declare var google: any;

@Component({
  selector: 'app-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  auth2: any;
  fromEmail = '0';
  orderId;
  returnUrl = '';
  show = false;
  errorLoginMsg: string = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      var from_email = params['fromEmail'];
      this.returnUrl = params['returnUrl'];
      if (from_email) {
        this.fromEmail = JSON.parse(from_email);
        this.orderId = JSON.parse(params['orderId']);
      } else this.fromEmail = '0';
    });
  }

  ngAfterViewInit(): void {
    try {
      let handleCredentialResponse = async (response: any) => {
        const data = { idToken: response.credential, oauth: 'v3' };
        var responsePayload: any = atob(response.credential.split('.')[1]);
        responsePayload = JSON.parse(responsePayload);
        this.zone.run(async () => {
          await this.afterLoginProcess(
            responsePayload.email,
            responsePayload.picture
          );
        });
      };

      let id = 'google-client-script';
      let script = document.getElementById(id);
      if (script === null) {
        let crscript = document.createElement('script');
        crscript.setAttribute('src', 'https://accounts.google.com/gsi/client');
        crscript.setAttribute('id', id);
        crscript.setAttribute('async', '');
        document.body.appendChild(crscript);
        crscript.onload = () => {
          google.accounts.id.initialize({
            client_id: Endpoint.CLIENT_ID,
            callback: handleCredentialResponse,
          });
          google.accounts.id.renderButton(
            document.getElementById('customBtn'),
            {
              theme: 'filled_blue',
              size: 'large',
              type: 'button',
              text: 'Sign in with Google',
              locale: 'english',
            }
          );
          google.accounts.id.prompt();
        };
      } else {
        google.accounts.id.initialize({
          client_id: Endpoint.CLIENT_ID,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(document.getElementById('customBtn'), {
          theme: 'filled_blue',
          size: 'large',
          type: 'button',
          text: 'Sign in with Google',
          locale: 'english',
        });
        google.accounts.id.prompt();
      }
    } catch (e) {
      alert('User Cannot Login');
    }
  }

  async afterLoginProcess(email: string, imageUrl: string) {
    let that = this;
    var prs = new HttpParams()
      .set(Unicode.EMAIL, email)
      .set('imageUrl', imageUrl);
    await that.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.LOGIN, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp && !resp.success) {
          alert(resp.message);
          this.localStorage.logout();
        } else if (resp && resp.success) {
          var user: UserModel = resp.user as UserModel;
          user.user_name = user.first_name + ' ' + user.last_name;
          user.img = imageUrl;
          user.isExecutor =
            user.permissions.find(
              (x) =>
                x.code &&
                x.code.toLowerCase() ==
                  Permissions.FINAL_EXECUTION.toLowerCase()
            ) != undefined;
          user.isGPM =
            user.groups?.find(
              (x) => x.name && x.name.toLowerCase() == Group.GPM
            ) != undefined;
          user.isFinanceTeam =
            user.groups?.find(
              (x) => x.name && x.name.toLowerCase() == Group.Finance
            ) != undefined;
          user.isAdmin =
            user.groups?.find(
              (x) => x.name && x.name.toLowerCase() == Group.Admin
            ) != undefined;
          user.isEngineer =
            user.groups?.find(
              (x) => x.name && x.name.toLowerCase().indexOf('engineer') >= 0
            ) != undefined;
          user.isSales =
            user.groups?.find(
              (x) =>
                x.name &&
                (x.name.toLowerCase() == Group.Sale ||
                  x.name.toLowerCase() == Group.Sale_Manager)
            ) != undefined;
          user.isOperations =
            user.groups?.find(
              (x) => x.name && x.name.toLowerCase() == Group.OPERATIONS
            ) != undefined;
          user.canViewAll =
            user.permissions.find(
              (x) =>
                x.code &&
                x.code.toLowerCase() == Permissions.can_view_all.toLowerCase()
            ) != undefined ||
            user.groups.find(
              (x) => x.name && x.name.toLowerCase() == Group.Admin
            ) != undefined ||
            user.groups.find(
              (x) => x.name && x.name.toLowerCase() == Group.GPM
            ) != undefined ||
            user.groups.find(
              (x) => x.name && x.name.toLowerCase() == Group.Finance
            ) != undefined;
          user.canAssign =
            user.permissions.find(
              (x) =>
                x.code &&
                x.code.toLowerCase() == Permissions.can_assign.toLowerCase()
            ) != undefined;
          this.localStorage.registerUser(JSON.stringify(user));
          this.localStorage.remove(Unicode.GOOGLE_PRODUCTS); //TO RE_RETREIVE IT
          this.localStorage.save(
            Unicode.GLOBAL_DATA_LIST,
            JSON.stringify(resp.global ?? [])
          );
          this.localStorage.save(
            Unicode.USERS_INFO,
            JSON.stringify(resp.users_info ?? {})
          );
          this.localStorage.save(
            Unicode.MENUS,
            JSON.stringify(resp.users_info?.menus ?? [])
          );
          var menus = resp.users_info?.menus ?? [];
          menus = menus.sort((n1, n2) => n1.order - n2.order);
          this.zone.run(() => {
            if (!Utilities.isNullOrEmpty(this.returnUrl)) {
              var url = decodeURIComponent(this.returnUrl);
              this.router.navigateByUrl(url);
            } else if (menus.length > 0) this.router.navigate([menus[0].link]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.show = true;
        setTimeout(() => (this.show = false), 3000);
        this.errorLoginMsg = err.statusText;
      });
  }
}
