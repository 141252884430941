import { BaseModel } from '../base.model';
import { DealModel } from './deal.model';
import { DomainModel } from './domain.model';
import { UserModel } from './user.model';

export class SOWModel extends BaseModel {
  _id: string;
  opportunity_rfid: string;
  is_gcp: boolean;
  created_by_rfid: string;
  customer_rfid: string;
  domain_rfid: string;
  status: string;
  draft: boolean;
  approval_phases: any;
  google_doc_id: string;
  daf_required: boolean;
  psf_required: boolean;
  sale_amount: any;
  sale_amount_approved: boolean;
  created_at;
  start_date: Date;
  //CUSTOM
  user_sow: any;
  domain: DomainModel;
  created_date_string: string;
  customer_domain: string;
  customer_organisation: string;
  customer_contact_name: string;
  managerApprovalPart: any;
  gpmApprovalPart: any;
  sendingToCustomerPart: any;
  saleAmountPart: any;
  googleApprovalPart: any;
  googleDocUrl: any;
  created_user_name: string;
  created_user_img: string;
  manager_user_id: string;
  success: boolean;
  consumed_time: string;

  constructor() {
    super();
  }

  getSortedApprovalPhasesByDateDesc(): any[] {
    return this.approval_phases
      ? this.approval_phases.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        })
      : [];
  }

  canApprove(user: UserModel): boolean {
    return (
      this.managerApprovalPart &&
      user._id == this.managerApprovalPart.id &&
      (!this.managerApprovalPart.date || !this.managerApprovalPart.approved)
    );
  }
  canSendToCustomer(user: UserModel): boolean {
    return (
      user.isEngineer &&
      (!this.user_sow.manager_user_id || this.managerApprovalPart?.date) &&
      this.sendingToCustomerPart &&
      this.sendingToCustomerPart.date == null
    );
  }
}
