import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { OpportunityModel } from '../../../../../src/models/mongo/opportunity.model';
import { UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sow-list',
  templateUrl: './sow-list.component.html',
  styleUrls: ['./sow-list.component.scss'],
})
export class SowListComponent implements OnInit {
  @HostBinding('class') class = ' ';
  opportunities: OpportunityModel[] = [];
  plans = [];
  static googleProducts = [];
  currentUser: UserModel;
  static _httpRequestService: HttpRequestService;
  static _http: HttpClient;
  customerSearch: string;
  fromSelectedDate;
  toSelectedDate;
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  static opportunities = [];
  static sows = [];
  static pagination: number = 1;
  static total: number = 1;
  static gcp: boolean;
  selectedFilterType: string = 'ALL';
  types = ['ALL', 'GWS', 'GCP'];
  customerRfId;
  customerId;
  domainRfId;
  initial_userIds = [];
  add: boolean = true;
  assignsales = [];
  selectedUserId;
  selectedSales: string;
  sowForm: UntypedFormGroup;

  constructor(
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    //
    SowListComponent._httpRequestService = httpRequestService;
    SowListComponent._http = http;
    SowListComponent.gcp = false;
    var current = new Date();
    var beforeOneMonth = current.setMonth(current.getMonth() - 1);
    this.fromSelectedDate = new Date(beforeOneMonth).toISOString().slice(0, 10);
    this.toSelectedDate = new Date().toISOString().slice(0, 10);
  }

  async ngOnInit(): Promise<void> {
    this.assignsales = await Utilities.usersByGroup(
      Group.ENGINEER,
      this.http,
      this.localStorage
    );
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    SowListComponent.pagination = 1;
    this.getsows(null);
  }

  async getsows(pagination) {
    if (pagination == null) pagination = SowListComponent.pagination;
    var prs = new HttpParams()
      .set(Unicode.PAGE, (pagination - 1) * SowListComponent.pageSize + '')
      .set(Unicode.LIMIT, SowListComponent.pageSize + '')
      .set(Unicode.SOW_TYPE, this.selectedFilterType);
    if (!this.currentUser.canViewAll) prs = prs.set(Unicode.VIEW_ALL, '0');
    if (!Utilities.isNullOrEmpty(this.customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, this.customerSearch);
    if (!Utilities.isNullOrEmpty(this.fromSelectedDate))
      prs = prs.set(Unicode.FROM_DATE, this.fromSelectedDate);
    if (!Utilities.isNullOrEmpty(this.toSelectedDate))
      prs = prs.set(Unicode.TO_DATE, this.toSelectedDate);
    if (!Utilities.isNullOrEmpty(this.selectedSales))
      prs = prs.set(Unicode.SALES_MANAGER, this.selectedSales);
    await SowListComponent._http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.GET_ALL_SOWS, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp) {
          SowListComponent.total = <number>resp['total'];
          SowListComponent.sows = resp.sows ?? [];
        }
      });
  }

  renderPage(event: number) {
    SowListComponent.pagination = event;
    this.getsows(null);
  }

  search() {
    SowListComponent.pagination = 1;
    this.getsows(null);
  }
  selectDate() {
    SowListComponent.pagination = 1;
    this.getsows(null);
  }
  selectSale() {
    SowListComponent.pagination = 1;
    this.getsows(null);
  }
  selectStatus() {
    SowListComponent.pagination = 1;
    this.getsows(null);
  }

  switchType(e) {
    e.target.classList.toggle('toggled');
    SowListComponent.pagination = 1;
    SowListComponent.gcp = !SowListComponent.gcp;
    this.getsows(null);
  }

  applyFilter(type: string): void {
    if (type != this.selectedFilterType) {
      SowListComponent.pagination = 1;
      this.selectedFilterType = type;
      this.getsows(null);
    }
  }

  get GetData() {
    return SowListComponent.sows;
  }
  get GetTotalCount() {
    return SowListComponent.total;
  }
  get GetPagination() {
    return SowListComponent.pagination;
  }
  get GetPageSize() {
    return SowListComponent.pageSize;
  }
  async assignSales(selectedUser: any) {
    if (
      !this.add &&
      !Utilities.isNullOrEmpty(this.domainRfId) &&
      !Utilities.isNullOrEmpty(this.sowForm.value.user_ids)
    ) {
      var add_ids = this.sowForm.value.user_ids.filter(
        (x) => this.initial_userIds.indexOf(x) < 0
      );
      var delete_ids = this.initial_userIds.filter(
        (x) => this.sowForm.value.user_ids.indexOf(x) < 0
      );
      await this.http
        .put(Endpoint.GLOBAL_URL + '' + Endpoint.ASSIGN_USER_CUSTOMER, {
          user_ids_attach: add_ids ?? [],
          user_ids_detach: delete_ids ?? [],
          domain_rfid: this.domainRfId,
        })
        .toPromise()
        .then(async (resp: any) => {
          this.initial_userIds = this.sowForm.value.user_ids;
          alert(resp?.msg);
          // Fetch opportunities based on the selected user
          const opportunities = await this.http
            .get(
              Endpoint.GLOBAL_URL +
                Endpoint.ASSIGN_USER_CUSTOMER +
                '/' +
                this.selectedUserId
            )
            .toPromise();
        })
        .catch((err) => {
          alert(err.statusText ?? err.msg);
        });
    }
  }
  refreshPage() {
    this.getsows(null);
  }
  goBack(): void {
    this.location.back();
  }

  viewSOW(sow) {
    this.router.navigate(['sow/sow-details'], {
      state: { sow: sow },
    });
  }
  deleteSow(id: string) {
    if (confirm('Are you sure to delete this SOW doc?')) {
      this.http
        .put(Endpoint.GLOBAL_URL + '' + Endpoint.DELETE_SOW + id, {})
        .subscribe((response: any) => {
          this.getsows(null);
          alert(response.msg);
        });
    }
  }
}
