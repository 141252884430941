<div id="opp_order_parent_step" class="container d-flex flex-column">
  <div
    class="opp_order_step_new h-100"
    *ngIf="orderPhaseData?.gcp_project == null; else opp_order_step"
  >
    <div class="step_count">
      <span class="notification-icon--fixed">
        <small class="notification-badge f-20">5</small>
      </span>
    </div>
    <div class="text-center mt-2">
      <img
        src="./assets/img/ic_step_order.svg"
        class="back-icon icon_step"
        alt="order building icon"
      />
    </div>
    <div class="text-center txt_deal_phase mt-2">
      <span class="col-1">Order Phase</span>
    </div>
    <div class="row">
      <button
        (click)="createProOptions()"
        [ngClass]="{
          phases_disabled_btn: !currentUser.isEngineer || disabled == true
        }"
        class="col-10 mt-2 offset-1 btn d-none d-md-inline rounded-pill btn-bg-white smaller-text"
      >
        Add a Project
      </button>
    </div>
    <div class="new-div mt-auto mb-3 offset-3 div_skip">
      <!-- <div
        class="product-sans-light f-12"
        [ngClass]="{
          f_black: skip == true,
          'product-sans-medium': skip == true
        }"
      >
        <label class="switch mb-1">
          <input
            type="checkbox"
            [(ngModel)]="skip"
            (change)="skipStep($event)"
          />
          <span class="slider"></span>
        </label>
        Skip this step
      </div> -->
    </div>
  </div>

  <ng-template #opp_order_step>
    <div class="opp_order_step h-100">
      <div class="order_step_header d-flex flex-row">
        <span class="flex-column col-1 f-20">5</span>
        <label class="text-nowrap mt-1 f-15 flex_auto">Order</label>
        <div
          class="flex-column text-end col-4"
          *ngIf="orderPhaseData?.order_time"
        >
          <span class="f-12 d-grid"
            >time <span>{{ orderPhaseData?.order_time }}</span></span
          >
        </div>
        <img
          src="./assets/img/ic_time_expand.svg"
          class="ic_time col-2 text-end"
        />
      </div>
      <div class="order_step_content product-sans">
        <!--PROJECT CREATION-->
        <div class="btn_collapse_parent mt-2" id="project_creation">
          <div
            class="row opp_col_content align-items-center"
            style="padding: 5px 13px"
          >
            <div class="col-9 p-0 phase-content">
              <button
                type="button"
                class="btn_collapse_header w-100 d-inline-flex"
                (click)="isGcpPrjCreCollapsed = !isGcpPrjCreCollapsed"
                [attr.aria-expanded]="!isGcpPrjCreCollapsed"
                aria-controls="collapseCustReq"
                [ngClass]="{
                  'col-10': orderPhaseData,
                  'col-12': !orderPhaseData
                }"
              >
                <span
                  class="fa p-1"
                  [ngClass]="{
                    'fa-angle-down': isGcpPrjCreCollapsed,
                    'fa-angle-up': !isGcpPrjCreCollapsed,
                  }"
                ></span>
                <label
                  class="label-ellipsis"
                  *ngIf="orderPhaseData.gcp_project"
                  >{{
                    orderPhaseData.existing_project
                      ? "Engineer Selected Project"
                      : "Engineer Created Project"
                  }}</label
                >
                <label class="label-ellipsis" *ngIf="!orderPhaseData"
                  >Pending Project Selection by Engineer</label
                >
              </button>
            </div>

            <div class="col-3 time-content d-inline-flex align-items-center">
              <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
              </span>
              <span
                class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                *ngIf="
                  !orderPhaseData.existing_project &&
                  orderPhaseData.gcp_project.consumed_time
                "
                >{{
                  orderPhaseData.gcp_project?.consumed_time
                    ? (orderPhaseData.gcp_project?.consumed_time
                      | opportunityConsumedTime)
                    : ""
                }}</span
              >
              <span
                *ngIf="orderPhaseData"
                class="check_green col-2 fa fa-check fa-thin float-end mt-1"
              ></span>
            </div>
          </div>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="isGcpPrjCreCollapsed">
            <div class="btn_collapse_content">
              <div class="d-grid p-1">
                <label class="phase_details_title">Engineer Name</label>
                <label class="phase_details_data_txt">{{
                  orderPhaseData.gcp_project.created_user_name
                }}</label>
                <label class="phase_details_title mt-2"
                  >Date & Time of
                  {{
                    orderPhaseData.existing_project ? "Selection" : "Creation"
                  }}</label
                >
                <label class="phase_details_data_txt">{{
                  orderPhaseData.gcp_project.created_at
                    | date : "dd MMM,yyyy - hh:mma"
                }}</label>
                <label class="phase_details_title mt-2"
                  >Project Name<span>{{
                    orderPhaseData.existing_project ? "(Existing)" : "(New)"
                  }}</span></label
                >
                <label class="phase_details_data_txt">{{
                  orderPhaseData.gcp_project.name
                }}</label>
                <ng-template
                  [ngIf]="
                    !orderPhaseData.existing_project &&
                    orderPhaseData.gcp_project.consumed_time
                  "
                >
                  <label class="phase_details_title mt-2">Consumed Time</label>
                  <label class="phase_details_data_txt">{{
                    orderPhaseData.gcp_project.consumed_time
                  }}</label>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <!--LINK PROJECT-->
        <div
          class="btn_collapse_parent mt-2"
          id="order_link_project"
          *ngIf="orderPhaseData.gcp_project?.created_at"
        >
          <div
            class="row opp_col_content align-items-center"
            style="padding: 5px 13px"
          >
            <div class="col-9 p-0 phase-content">
              <button
                type="button"
                class="btn_collapse_header col-10 w-100 d-inline-flex"
                [ngClass]="{
                  'col-10': orderPhaseData.link_project_billing?.linking_date,
                  'col-12': !orderPhaseData.link_project_billing?.linking_date
                }"
                (click)="isGcpPrjLinkedCollapsed = !isGcpPrjLinkedCollapsed"
                [attr.aria-expanded]="!isGcpPrjLinkedCollapsed"
                aria-controls="collapseManager"
              >
                <span
                  class="fa p-1"
                  [ngClass]="{
                    'fa-angle-down': isGcpPrjLinkedCollapsed,
                    'fa-angle-up': !isGcpPrjLinkedCollapsed
                  }"
                ></span>
                <label
                  *ngIf="orderPhaseData.link_project_billing?.linking_date"
                  title="Finance linked Project to Billing"
                  class="label-ellipsis"
                  >Finance linked Project to Billing</label
                >
                <label
                  title="Pending Finance Project Linking"
                  class="label-ellipsis"
                  *ngIf="!orderPhaseData.link_project_billing?.linking_date"
                  >Pending Finance Project Linking</label
                >
              </button>
            </div>

            <div class="col-3 d-inline-flex time-content align-items-center">
              <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">{{
                orderPhaseData.link_project_billing?.consumed_time
                  ? (orderPhaseData.link_project_billing?.consumed_time
                    | opportunityConsumedTime)
                  : ""
              }}</span>
              <span
                *ngIf="orderPhaseData.link_project_billing?.linking_date"
                class="check_green fa fa-check fa-thin float-end mt-1 col-2"
              ></span>
            </div>
          </div>
          <div
            #collapse="ngbCollapse"
            [(ngbCollapse)]="isGcpPrjLinkedCollapsed"
          >
            <div class="btn_collapse_content">
              <div class="d-grid p-1">
                <ng-template
                  [ngIf]="
                    orderPhaseData.link_project_billing?.linking_date &&
                    orderPhaseData.link_project_billing?.link_by
                  "
                >
                  <label class="phase_details_title">Finance Name</label>
                  <label class="phase_details_data_txt">{{
                    orderPhaseData.link_project_billing.link_by.name
                  }}</label>
                  <label class="phase_details_title mt-2"
                    >Date & Time of Linking</label
                  >
                  <label class="phase_details_data_txt">{{
                    orderPhaseData.link_project_billing.linking_date
                      | date : "dd MMM,yyyy - hh:mma"
                  }}</label>
                  <label class="phase_details_title mt-2">Consumed Time</label>
                  <label class="phase_details_data_txt">{{
                    orderPhaseData.link_project_billing.consumed_time
                  }}</label>
                  <label class="phase_details_title mt-2">Link</label>
                  <label class="phase_details_data_txt"
                    >{{ orderPhaseData.link_project_billing.project_name }} to
                    {{
                      orderPhaseData.link_project_billing.billing_account_name
                    }}</label
                  >
                </ng-template>
                <input
                  *ngIf="!orderPhaseData.link_project_billing?.linking_date"
                  [ngClass]="{
                    phases_disabled_btn: !currentUser.isFinanceTeam
                  }"
                  class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                  (click)="linkOrderProjectPopup()"
                  value="Link Project to Billing"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- BUDGET -->
        <div
          class="btn_collapse_parent mt-2"
          id="order_budget"
          *ngIf="orderPhaseData.link_project_billing?.linking_date"
        >
          <div
            class="row opp_col_content align-items-center"
            style="padding: 5px 13px"
          >
            <div class="col-9 p-0 phase-content">
              <button
                type="button"
                class="btn_collapse_header col-10 w-100 d-inline-flex"
                [ngClass]="{
                  'col-10': orderPhaseData.billing_budget?.created_at,
                  'col-12': !orderPhaseData.billing_budget?.created_at
                }"
                (click)="isBudgetCreCollapsed = !isBudgetCreCollapsed"
                [attr.aria-expanded]="!isBudgetCreCollapsed"
                aria-controls="collapseManager"
              >
                <span
                  class="fa p-1"
                  [ngClass]="{
                    'fa-angle-down': isBudgetCreCollapsed,
                    'fa-angle-up': !isBudgetCreCollapsed
                  }"
                ></span>
                <label
                  class="label-ellipsis"
                  *ngIf="orderPhaseData.billing_budget?.created_at"
                  >Finance Created Budget</label
                >
                <label
                  title="Pending Finance Budget Creation"
                  class="label-ellipsis"
                  *ngIf="!orderPhaseData.billing_budget?.created_at"
                  >Pending Finance Budget Creation</label
                >
              </button>
            </div>

            <div class="col-3 d-inline-flex time-content align-items-center">
              <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">{{
                orderPhaseData.billing_budget?.consumed_time
                  ? (orderPhaseData.billing_budget?.consumed_time
                    | opportunityConsumedTime)
                  : ""
              }}</span>

              <span
                *ngIf="orderPhaseData.billing_budget?.created_at"
                class="check_green fa fa-check fa-thin float-end mt-1 col-2"
              ></span>
            </div>
          </div>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="isBudgetCreCollapsed">
            <div class="btn_collapse_content">
              <div class="d-grid p-1">
                <ng-template [ngIf]="orderPhaseData.billing_budget?.created_at">
                  <label class="phase_details_title">Finance Name</label>
                  <label class="phase_details_data_txt">{{
                    orderPhaseData.billing_budget.created_user_name
                  }}</label>
                  <label class="phase_details_title mt-2"
                    >Date & Time of Creation</label
                  >
                  <label class="phase_details_data_txt">{{
                    orderPhaseData.billing_budget.created_at
                      | date : "dd MMM,yyyy - hh:mma"
                  }}</label>
                  <input
                    [ngClass]="{
                      phases_disabled_btn: !currentUser.isFinanceTeam
                    }"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    (click)="createBudgetPopup()"
                    value="Update Budget"
                  />
                </ng-template>
                <input
                  *ngIf="!orderPhaseData.billing_budget?.created_at"
                  [ngClass]="{
                    phases_disabled_btn: !currentUser.isFinanceTeam
                  }"
                  class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                  (click)="createBudgetPopup()"
                  value="Create Budget"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<!-- CREATION PROJECT OPTION POPUP -->
<div
  class="modal fade"
  bsModal
  #projCreationOptionsModal="bs-modal"
  tabindex="-1"
  id="projCreationOptionsModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 col-11 product-sans-medium">Project</h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="projCreationOptionsModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mt-2">
          You can choose wether to create a new project or use an existing
          project.
        </div>
      </div>
      <div class="modal-footer">
        <div
          class="d-flex align-items-start justify-content-center justify-content-xl-end col-12 product-sans-light"
        >
          <input
            class="btn btn-primary small-text btn-color rounded-pill col-lg-3 col-md-3 col-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
            value="Use existing project"
            (click)="useExistingPro()"
          />
          <input
            class="btn btn-primary small-text btn-color rounded-pill col-lg-3 col-md-3 col-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
            value="Create new project"
            (click)="createNewProj()"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- EXISTING PROJECTS POPUP-->
<div
  class="modal fade"
  bsModal
  #existingProjModal="bs-modal"
  tabindex="-1"
  id="existingProjModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 col-11 product-sans-medium">Project</h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="existingProjModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mt-2">Select Project</div>
        <div class="form-floating">
          <ng-select
            bindLabel="project.name"
            appendTo="body"
            [searchable]="true"
            [clearable]="false"
            class="col-12 p-0"
            [(ngModel)]="existingProjectSelected"
            placeholder="Project"
          >
            <ng-option [value]="project" *ngFor="let project of allProjects">
              {{ project.name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="modal-footer">
        <div
          class="d-flex align-items-start justify-content-center justify-content-xl-end col-12 product-sans-light"
        >
          <input
            class="btn btn-primary small-text btn-color rounded-pill col-lg-3 col-md-3 col-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
            value="Save"
            (click)="selectProj()"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<new-project-modal
  #orderNewProjectModal
  [oppID]="opporunityID"
  [startDate]="projectStartDate"
  (createProjectEmitter)="createProject($event)"
></new-project-modal>

<link-project-popup
  *ngIf="orderPhaseData?.gcp_project"
  [oppID]="opporunityID"
  [projectRfId]="orderPhaseData.gcp_project._id"
  [projectName]="orderPhaseData.gcp_project.name"
  (linkProj)="linkingDone($event)"
  #linkProjectModal
></link-project-popup>

<!-- CREATION BUDGET POPUP -->
<div
  class="modal fade"
  bsModal
  #budgetCreModal="bs-modal"
  tabindex="-1"
  id="budgetCreModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 col-11 product-sans-medium">
          Create Budget
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="budgetCreModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <add-budget-form
          *ngIf="billingRfId != null"
          [billingRfId]="billingRfId"
          (createBudgetEmitter)="createBudget($event)"
        ></add-budget-form>
      </div>
    </div>
  </div>
</div>
