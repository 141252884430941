import {
  Endpoint,
  Group,
  OrderAction,
  OrderStatus,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  OnInit,
  AfterViewInit,
  HostBinding,
  ViewEncapsulation,
  NgZone,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { OrderModel } from 'src/models/mongo/order.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserModel } from 'src/models/mongo/user.model';
import { RequestResultModel } from 'src/models/request_result.model';
import { DatePipe } from '@angular/common';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { Location } from '@angular/common';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { CustomersService } from 'src/servcies/backend-services/customers.service';
import { SettingsService } from 'src/servcies/backend-services/settings.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomainModel } from 'src/models/mongo/domain.model';
import { OrdersService } from 'projects/orders/src/public-api';

@Component({
  selector: 'view-order-form',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './order-view-form.component.html',
  styleUrls: ['./order-view-form.component.scss'],
})
export class OrderViewFormComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = '';
  orderForm: UntypedFormGroup;
  @Input() model: OrderModel = new OrderModel();
  opportunitySources = [];
  opportunityStatus = [];
  globalData = [];
  googleProducts = [];
  plans = [];
  afterCreationUserEmail;
  afterExecutionUserEmail;
  currentUser: UserModel;
  orderPhasesInfo;
  gpmGroupEmail = [];
  executors = [];
  canApprove: boolean;
  approvalText: string;
  canReject: boolean;
  rejectText: string;
  canPlaceIt: boolean;
  prices = [];
  orderStatus: string;
  orderAction: string;
  isExecuted: boolean = false;
  editPage: boolean;
  engagements = [];
  rejectionReasons = [];
  rejectReason: string;
  selectOthers: boolean = false;
  isNewCustomer: boolean = false;
  date_from: string;
  date_to: string;
  companySections = [];
  canEdit: boolean = false;
  odooIntegrationEnabled: boolean = false;
  @ViewChild('odooComparisonModal') odooComparisonModal: ModalDirective;
  @Output() viewOrderEmitter = new EventEmitter<any>();
  @Input() isGWS: boolean;
  @Input() opporunityID: string;
  @ViewChild('rejectionConfirmationModal')
  rejectionConfirmationModal: ModalDirective;
  @ViewChild('rejectionReasonModal') rejectionReasonModal: ModalDirective;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private fb: UntypedFormBuilder,
    private ngzone: NgZone,
    private datePipe: DatePipe,
    private location: Location,
    private confirmDialogService: ConfirmDialogService,
    private orderService: OrdersService,
    private settingsService: SettingsService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.orderForm = fb.group({
      customer_domain: [{ value: '', disabled: true }],
      customer_email: [''],
      id: [{ value: '', disabled: true }],
      auth_token: [{ value: '', disabled: true }],
      description: [null],
      po_id: [null],
      order_sale_invoice_nbr: [null, Validators.required],
      opportunity_source: [null, Validators.required],
      opportunity_nbr: [null],
      opportunity_status: [null, Validators.required],
      google_rep: [''],
      customer_email_notification: [''],
      action: [''],
      company_section: ['', Validators.required],
      sku: this.fb.array([]),
      support: new FormGroup({
        available: new FormControl(false, [Validators.required]),
        price: new FormControl('', []),
        start_date: new FormControl('', []),
        end_date: new FormControl('', []),
      }),
    });
  }
  ngAfterViewInit(): void {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async (routeParams) => {
      var that = this;
      const params = history.state;
      this.editPage = Utilities.isTrue(params['edit']);
      this.date_from = params['date_from'];
      this.date_to = params['date_to'];

      //ORDER DETAILS/VIEW PAGE
      if (params && params['order_model'])
        this.model = JSON.parse(params['order_model']);
      else if (
        routeParams &&
        routeParams['fromEmail'] &&
        !Utilities.isNullOrEmpty(routeParams['Id'])
      ) {
        // FROM EMAIL URL
        this.editPage = true;
        var _id = routeParams['Id'];
        await this.http
          .get<any>(Endpoint.GLOBAL_URL + Endpoint.GET_ORDER_BY_ID + _id)
          .toPromise()
          .then((resp) => {
            if (resp?.order) {
              this.model = resp.order;
            }
          });
      } else if (this.model._id) {
        // REVIEW BUTTON FROM OPPORTUNITY
        this.editPage = true;
      }
      if (this.model) {
        this.isNewCustomer =
          this.model.action.toLowerCase() ==
          OrderAction.NEW_CUSTOMER.toLowerCase();
        this.ngzone.run(() => {
          this.formAddAndValidation();
        });
        if (
          this.model.action.toLowerCase() !==
            OrderAction.NEW_CUSTOMER.toLowerCase() &&
          !Utilities.isOrderToUpdateStatus(this.model.action) &&
          this.model.action.toLowerCase() !==
            OrderAction.UPDATE_RNEWAL_SETTNGS.toLowerCase()
        ) {
          this.orderForm.controls['po_id'].setValidators(Validators.required);
          this.orderForm.controls['order_sale_invoice_nbr'].setValidators(
            Validators.required
          );
          this.orderForm.controls['opportunity_source'].setValidators(
            Validators.required
          );
          this.orderForm.controls['opportunity_status'].setValidators(
            Validators.required
          );
        }
        // if (this.currentUser.isGPM) {
        //   this.orderForm.controls['opportunity_nbr'].setValidators(
        //     Validators.required
        //   );
        // }
        this.orderStatus = this.model.order_status;
        this.orderAction = Utilities.setOrderAction(this.model);
        this.isExecuted =
          this.orderStatus.toLowerCase() == OrderStatus.Executed.toLowerCase();
        this.orderForm.patchValue({
          ...this.model,
          customer_domain: this.model.domain.domain,
          customer_email:
            this.model.domain.contact_person_email ??
            this.model.domain.alternate_email,
        });
        if (this.model.domain?.auth_token)
          this.orderForm.patchValue({
            auth_token: this.model.domain.auth_token,
          });
        if (this.model.sku) {
          for (var i = 0; i < this.model.sku.length; i++) {
            var sku = this.model.sku[i];
            if (!this.model.is_gcp) {
              if (sku.start_date?.$date?.$numberLong) {
                try {
                  sku.start_date = new Date(
                    Number.parseInt(sku.start_date?.$date?.$numberLong)
                  )
                    .toISOString()
                    .slice(0, 10);
                } catch (e) {}
              }
              if (sku.end_date?.$date?.$numberLong) {
                try {
                  sku.end_date = new Date(
                    Number.parseInt(sku.end_date?.$date?.$numberLong)
                  )
                    .toISOString()
                    .slice(0, 10);
                } catch (e) {}
              }
              this.sku.push(
                this.fb.group({
                  sku_id: [sku.sku_id, [Validators.required]],
                  plan_id: [
                    sku.plan_id?.$oid ?? sku.plan_id,
                    Validators.required,
                  ],
                  subscription_api_id: [sku.subscription_api_id],
                  end_api_date: [sku.end_api_date],
                  license: [sku.license, Validators.required],
                  old_license: [sku.old_license],
                  old_plan_name: [sku.old_plan_name],
                  old_sku_name: [sku.old_sku_name],
                  old_sku_id: [sku.old_sku_id],
                  start_date: [
                    sku.start_date,
                    !Utilities.isOrderToUpdateStatus(this.model.action) &&
                    this.model.action.toLowerCase() !==
                      OrderAction.UPDATE_RNEWAL_SETTNGS.toLowerCase()
                      ? Validators.required
                      : '',
                  ],
                  end_date: [
                    sku.end_date,
                    !Utilities.isOrderToUpdateStatus(this.model.action) &&
                    this.model.action.toLowerCase() !==
                      OrderAction.UPDATE_RNEWAL_SETTNGS.toLowerCase()
                      ? Validators.required
                      : '',
                  ],
                  with_google_offer: [sku.with_google_offer],
                  google_offer: [sku.google_offer],
                  with_customer_offer: [sku.with_customer_offer],
                  customer_offer: [sku.customer_offer],
                  cost_price: [sku.cost_price],
                  selling_price: [sku.selling_price],
                  renewel_setting_type: [sku.renewel_setting_type],
                  expanded: [i == 0],
                })
              );
            } else {
              this.sku.push(
                this.fb.group({
                  sku_id: [sku.sku_id, [Validators.required]],
                  amount: [sku.amount, [Validators.required]],
                  expanded: [i == 0],
                })
              );
            }
          }
        }
      }
      console.log(this.model);
      var usersInfo = this.localStorage.getUserInfo();
      this.orderPhasesInfo = await Utilities.orderApprovalPhases(
        this.localStorage,
        this.model,
        usersInfo
      );
      this.canApprove = this.orderPhasesInfo.canApprove && this.editPage;
      this.approvalText = this.orderPhasesInfo.approveText;
      this.canReject = this.orderPhasesInfo.canReject && this.editPage;
      this.rejectText =
        this.currentUser.email == this.model.user.created_user_email
          ? 'Delete'
          : 'Reject';
      this.canPlaceIt =
        this.currentUser.isExecutor &&
        this.canApprove &&
        this.model.order_status.toLowerCase() !==
          OrderStatus.Executed.toLowerCase() &&
        this.editPage;
      this.canEdit =
        !this.canApprove &&
        this.model?.order_status?.toUpperCase() !== OrderStatus.Executed &&
        (this.currentUser.email == this.model?.user?.created_user_email ||
          this.currentUser.email == this.model?.user?.manager_user_email ||
          this.currentUser.email ==
            this.model?.user?.manager_of_manager_user_email ||
          this.currentUser.isGPM ||
          this.currentUser.isFinanceTeam ||
          this.currentUser.isAdmin);
      this.prices = await Utilities.getPrices(this.http, this.localStorage);

      this.globalData = await Utilities.globalData(
        this.http,
        this.localStorage
      );
      var gData = Utilities.getGlobalDataValues(this.globalData);
      this.opportunitySources = gData.opportunitySources;
      this.opportunityStatus = gData.opportunityStatus;
      this.engagements = gData.engagements;
      this.rejectionReasons = gData.rejectionReasons;
      this.companySections = gData.companySections;

      this.plans = await Utilities.planList(this.http, this.localStorage);
      this.googleProducts = await Utilities.googleProductList(
        this.http,
        this.localStorage
      );
      if (!this.model.is_gcp)
        this.googleProducts = this.googleProducts.filter(
          (x) => x.gcp == undefined || x.gcp == false
        );
      else
        this.googleProducts = this.googleProducts.filter((x) => x.gcp == true);
    });
    if (
      this.model &&
      this.model.approval_phases &&
      this.model.approval_phases.length > 0
    ) {
      this.model.approval_phases.forEach((element) => {
        if (typeof element.approved_date == 'object') {
          try {
            element.approved_date = Utilities.fromMongoDateToStringDateInput(
              this.datePipe,
              element.approved_date
            );
          } catch (e) {}
        }
      });
    }
    //CHECK IF ODOO INTEGRATION IS ENABLED
    // this.settingsService
    //   .getIntegrationByCode(IntegrationSystemCode.ODOO)
    //   .toPromise()
    //   .then((resp: any) => {
    //     if (resp && resp.success && !Utilities.isNullOrEmpty(resp.model)) {
    //       this.odooIntegrationEnabled = resp.model.enabled ?? false;
    //     }
    //   });
  }

  formAddAndValidation() {
    if (this.model.is_gcp) {
      this.orderForm.addControl('gcp_credits_coupon', this.fb.control(''));
      this.orderForm.addControl('used', new UntypedFormControl(false));
      if (
        this.currentUser.isGPM &&
        !Utilities.isOrderToUpdateStatus(this.model.action)
      )
        this.orderForm.controls['gcp_credits_coupon'].setValidators(
          Validators.required
        );
      if (
        this.currentUser.isFinanceTeam &&
        !Utilities.isOrderToUpdateStatus(this.model.action)
      )
        this.orderForm.controls['used'].setValidators(Validators.required);

      this.orderForm.addControl(
        'engagement',
        !Utilities.isOrderToUpdateStatus(this.model.action)
          ? new UntypedFormControl('', Validators.required)
          : new UntypedFormControl('')
      );
      this.orderForm.addControl(
        'project_number',
        !Utilities.isOrderToUpdateStatus(this.model.action)
          ? new UntypedFormControl('', Validators.required)
          : new UntypedFormControl('')
      );
      this.orderForm.addControl(
        'project_name',
        !Utilities.isOrderToUpdateStatus(this.model.action)
          ? new UntypedFormControl('', Validators.required)
          : new UntypedFormControl('')
      );
      this.orderForm.addControl(
        'billing_name',
        !Utilities.isOrderToUpdateStatus(this.model.action)
          ? new UntypedFormControl('', Validators.required)
          : new UntypedFormControl('')
      );
      this.orderForm.updateValueAndValidity();
    }
  }

  async saveOrder(sendDraft: boolean = false) {
    // APPROVE CASE
    var that = this;
    if (
      this.orderForm.valid &&
      this.orderService.isOrderSupportValid(this.orderForm.value.support)
    ) {
      var skus = this.orderForm.value.sku;
      if (!this.isNewCustomer && (!skus || this.sku.length == 0)) {
        alert('You must add at least one SKU');
        return;
      }
      if (
        !this.model.is_gcp &&
        skus.find(
          (x) => !x.plan_id || !x.sku_id || !x.license || x.license == 0
        ) != null
      ) {
        alert('You must select SKU,plan and add license for each service');
        return;
      }
      if (
        skus.find(
          (x) =>
            (x.with_google_offer == true &&
              (!x.google_offer || x.google_offer == 0)) ||
            (x.with_customer_offer == true &&
              (!x.customer_offer || x.customer_offer == 0))
        )
      ) {
        alert('you must add Offer percentag');
        return;
      }
      if (this.currentUser.isExecutor) {
        var cost =
          this.model?.sku && this.model.sku.length > 0
            ? this.model.sku[0]?.cost_price
            : null;
        if (
          !Utilities.isOrderToUpdateLicensesOrStatus(this.model.action, cost) &&
          !this.model?.approval_phases?.find(
            (x) => x.role?.toLowerCase() == Group.GPM
          )
        ) {
          alert('GPM approval is required!!');
          return;
        }
        //RENEW DATE CHECK
        if (
          this.model.action &&
          this.model.action.toLowerCase() == OrderAction.RENEW &&
          this.model.sku &&
          this.model.sku.length > 0
        ) {
          var endAPIDate = this.model.sku[0].end_api_date; // = start order date
          if (
            endAPIDate &&
            Number.parseFloat(endAPIDate) > new Date().getTime()
          ) {
            this.model.order_status = OrderStatus.CONFIRMED;
            this.orderPhasesInfo.status = OrderStatus.CONFIRMED;
            await this.saveDB(false, sendDraft)
              .then(async (addedToDB) => {
                if (addedToDB == true) {
                }
              })
              .catch((e) => {});
            return;
          }
        }
        await this.saveDB(true).then(async (resp) => {
          if (resp == true) {
          }
        });
      } else {
        await this.saveDB(false, sendDraft).then(async (addedToDB) => {
          if (addedToDB == true) {
          }
        });
      }
    }
  }

  async saveDB(toExecute: boolean, sendDraft: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      var oppDependencyOn = null;
      var order: OrderModel = this.orderForm.value;
      order.customer_api_id = this.model.domain?.customer_api_id;
      order.opportunity_rfid = this.model.opportunity_rfid;
      order.action = this.model.action;
      order._id = this.model._id;
      order.created_date_string = this.model.created_date_string;
      order.created_at = this.model.created_at;
      order.approval_phases = this.model.approval_phases ?? [];
      order.reject_reason = null;
      order.rejecter_email = null;
      order.reject_date = null;
      if (order.sku)
        order.sku.forEach((element) => {
          Utilities.setSKUFields(element, this.plans, this.googleProducts);
        });
      //
      order.approval_phases.forEach((phase) => {
        if (phase?.date && phase.date.$date?.$numberLong)
          phase.date = new Date(Number.parseInt(phase.date.$date?.$numberLong));
      });
      if (!sendDraft) {
        var mainGrpName =
          this.currentUser.groups.find((x) => x.main == true)?.name ?? '';
        var approvalPhases = order.approval_phases ?? [];
        var role = approvalPhases.find(
          (x) =>
            x.role &&
            x.role.toLowerCase().indexOf(mainGrpName.toLowerCase()) >= 0
        );
        if (role && Utilities.isNullOrEmpty(role.date)) {
          role.name = this.currentUser.user_name;
          role.email = this.currentUser.email;
          role.id = this.currentUser._id;
          role.date = new Date();
          role.approved = true;
        } else {
          var row: any = {
            role: this.currentUser.isGPM
              ? Group.GPM
              : this.currentUser.isFinanceTeam
              ? Group.Finance
              : Group.Sale_Manager,
            name: this.currentUser.getFullName(),
            email: this.currentUser.email,
            id: this.currentUser._id,
            date: new Date(),
            approved: true,
          };
          approvalPhases.push(row);
        }
        //
        // var cost =
        //   order?.sku && order.sku.length > 0 ? order.sku[0]?.cost_price : null;
        // var isUpdatingLicenses = Utilities.isOrderToUpdateLicensesOrStatus(
        //   this.model.action,
        //   cost
        // );
        // if (this.currentUser.isGPM) {
        //   oppDependencyOn = 'Finance';
        //  approvalPhases.push({ role: Group.Finance });
        // } else if (!this.currentUser.isFinanceTeam) {
        //   var nextTeam = isUpdatingLicenses ? Group.Finance : Group.GPM;
        //   oppDependencyOn = nextTeam;
        //   approvalPhases.push({
        //     role: Group.GPM,
        //   });
        // }
        order.approval_phases = approvalPhases;
        order.order_status = this.orderPhasesInfo.status ?? OrderStatus.Pending;
      } else {
        order.draft = false;
        order.order_status = this.model.order_status;
      }
      //
      var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
      if (
        this.currentUser.isExecutor &&
        order.action.toLowerCase() == OrderAction.NEW_CUSTOMER.toLowerCase() &&
        order.domain?.customer_api_id
      ) {
        params = params.set(Unicode.CUSTOMER_ID, order.domain?.customer_api_id);
      }
      if (
        this.currentUser.isExecutor &&
        order.action.toLowerCase() == OrderAction.TRANSFER.toLowerCase()
      ) {
        params = params.set('to_transfer', '1');
      }
      var receivers = this.orderPhasesInfo.receiverEmails ?? [];
      params = params
        .set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers))
        .set('order_action', sendDraft ? 'sendDraft' : 'approve')
        .set('opportunity_rfid', this.opporunityID);
      if (!Utilities.isNullOrEmpty(this.opporunityID))
        params = params.set(Unicode.DEPENDENCY_ON, oppDependencyOn);
      await this.http
        .put(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER, order, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp?.success) {
            if (this.model.domain)
              this.model.domain.customer_api_id = resp.customer_api_id;
            if (
              this.viewOrderEmitter &&
              this.viewOrderEmitter.observers.length > 0
            ) {
              var ord = JSON.parse(JSON.stringify(order));
              ord.approval_phases = resp.approval_phases;
              ord.user_order = {
                id: this.model.created_by_rfid,
                created_user_email: this.model.user.created_user_email,
                created_user_name: this.model.user.created_user_name,
                manager_user_id: this.model.user.manager_user_id,
              };
              this.viewOrderEmitter.emit(ord);
            } else {
              this.router.navigate(['/orders']);
            }
          }
        });
    });
  }

  async editOrder(): Promise<any> {
    if (
      this.orderForm.valid &&
      this.orderService.isOrderSupportValid(this.orderForm.value.support)
    ) {
      return new Promise(async (resolve, reject) => {
        var order: OrderModel = this.orderForm.value;
        order.created_at = this.model.created_at;
        order._id = this.model._id;
        if (order.sku)
          order.sku.forEach((element) => {
            Utilities.setSKUFields(element, this.plans, this.googleProducts);
          });
        order.approval_phases = this.model.approval_phases ?? [];
        order.approval_phases.forEach((phase) => {
          if (phase?.date && phase.date.$date?.$numberLong)
            phase.date = new Date(
              Number.parseInt(phase.date.$date?.$numberLong)
            );
        });
        order.order_status = this.model.order_status;
        var params = new HttpParams()
          .set(Unicode.TO_EXECUTE, false)
          .set(Unicode.EDIT, '1')
          .set('order_action', 'edit');
        var receivers = [];
        // params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
        await this.http
          .put(Endpoint.GLOBAL_URL + '' + Endpoint.UPDATE_ORDER, order, {
            params: params,
          })
          .toPromise()
          .then((resp: any) => {
            if (resp && resp.success) {
              if (
                this.viewOrderEmitter &&
                this.viewOrderEmitter.observers.length > 0
              ) {
                var ord = JSON.parse(JSON.stringify(order));
                ord.approval_phases = resp.approval_phases;
                ord.user_order = {
                  id: this.model.created_by_rfid,
                  // created_user_email: this.model.created_user_email,
                  // created_user_name: this.model.created_user_name,
                  // manager_user_id: this.model.manager_user_id,
                };
                this.viewOrderEmitter.emit(ord);
              } else {
                this.router.navigate(['/orders']);
              }
            }
          })
          .catch((err) => {
            resolve(false);
            throw err;
          });
      });
    }
  }

  addAnotherSKU() {
    this.sku.controls.forEach((cont) => {
      (<UntypedFormGroup>cont).controls['expanded'].setValue(false);
    });
    this.sku.push(
      this.fb.group({
        sku_id: [null, Validators.required],
        plan_id: [null, Validators.required],
        license: [0, Validators.required],
        start_date: [null, Validators.required],
        end_date: [null, Validators.required],
        with_google_offer: [false],
        google_offer: [0],
        with_customer_offer: [false],
        customer_offer: [0],
        cost_price: [0],
        selling_price: [0],
        expanded: [true],
      })
    );
  }

  deleteSKU(skuID) {
    var index = this.sku.controls.findIndex(
      (x: any) => x.value.sku_id == skuID
    );
    if (index >= 0) this.sku.removeAt(index);
  }
  expandSku(skuID) {
    var sku = this.sku.controls.find((x: any) => x.value.sku_id == skuID);
    if (sku) {
      var expanded = (<UntypedFormGroup>sku).controls['expanded'].value;
      (<UntypedFormGroup>sku).controls['expanded'].setValue(!expanded);
    }
  }

  async placeOrder(text: string) {
    // isOfflineOrOffer = true =>offline . isOfflineOrOffer=false => offer
    var that = this;
    this.confirmDialogService.confirmThis(
      //todo backend
      'Did you execute it? Please Execute it first,from the console,then place the order here',
      null,
      'confirm',
      async function () {
        var order: OrderModel = that.orderForm.value;
        if (Utilities.isNullOrEmpty(order.description)) order.description = '';
        order.description += '. (placed as ' + text + ').';
        order.place_as = true;
        order.created_at = that.model.created_at;
        if (order.sku)
          order.sku.forEach((element) => {
            Utilities.setSKUFields(element, that.plans, that.googleProducts);
          });
        order.approval_phases = that.model.approval_phases ?? [];
        order.approval_phases.forEach((phase) => {
          if (phase?.date && phase.date.$date?.$numberLong)
            phase.date = new Date(
              Number.parseInt(phase.date.$date?.$numberLong)
            );
        });
        var grps = that.currentUser.groups.map(
          (x) => (x.name = x.name?.toLowerCase())
        );
        var approvalPhases = order.approval_phases ?? [];
        var role = approvalPhases.find(
          (x) => x.role && grps.indexOf(x.role.toLowerCase()) >= 0
        );
        if (role && Utilities.isNullOrEmpty(role.date)) {
          role.name = that.currentUser.user_name;
          role.email = that.currentUser.email;
          role.id = that.currentUser._id;
          role.date = new Date();
          role.approved = true;
        } else {
          var row: any = {
            role: Group.Finance,
            name: that.currentUser.getFullName(),
            email: that.currentUser.email,
            id: that.currentUser._id,
            date: new Date(),
            approved: true,
          };
          approvalPhases.push(row);
        }
        order.approval_phases = approvalPhases;
        order.order_status = OrderStatus.Executed;
        order._id = that.model._id;
        var receivers = that.orderPhasesInfo.receiverEmails ?? [];
        var params = new HttpParams()
          .set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers))
          .set(Unicode.TO_EXECUTE, false);
        await that.http
          .put(Endpoint.GLOBAL_URL + '' + Endpoint.UPDATE_ORDER, order, {
            params: params,
          })
          .toPromise()
          .then(async (resp: any) => {
            if (
              that.viewOrderEmitter &&
              that.viewOrderEmitter.observers.length > 0
            ) {
              var ord = JSON.parse(JSON.stringify(order));
              ord.approval_phases = resp.approval_phases;
              ord.user_order = {
                id: this.model.created_by_rfid,
                created_user_email: this.model.created_user_email,
                created_user_name: this.model.created_user_name,
                manager_user_id: this.model.manager_user_id,
              };
              that.viewOrderEmitter.emit(ord);
            } else that.router.navigate(['/orders']);
          });
      },
      function () {}
    );
  }

  selectRejectionReason() {
    if (this.rejectReason?.toLowerCase() == 'others') {
      this.selectOthers = true;
      this.rejectReason = null;
    } else this.selectOthers = false;
  }

  showRejectConfirmationPopup() {
    this.rejectionConfirmationModal.show();
  }
  showRejectReasonPopup() {
    this.rejectionReasonModal.show();
  }

  async rejectOrder() {
    if (Utilities.isNullOrEmpty(this.rejectReason)) {
      alert('Please select Rejection Reason');
      return;
    }
    var that = this;
    var order: OrderModel = this.orderForm.value;
    order.reject_reason = this.rejectReason;
    order.rejecter_email = this.currentUser.email;
    order.created_at = this.model.created_at;
    order.order_status = OrderStatus.Rejected;
    var mainGrpName =
      this.currentUser.groups.find((x) => x.main == true)?.name ?? '';
    order.approval_phases = this.model.approval_phases ?? [];
    order.approval_phases.forEach((phase) => {
      if (phase?.date && phase.date.$date?.$numberLong)
        phase.date = new Date(Number.parseInt(phase.date.$date?.$numberLong));
    });
    var approvalPhases = order.approval_phases ?? [];
    var role = this.model.approval_phases.find(
      (x) =>
        x.role && x.role.toLowerCase().indexOf(mainGrpName.toLowerCase()) >= 0
    );
    if (role && Utilities.isNullOrEmpty(role.date)) {
      role.name = this.currentUser.user_name;
      role.email = this.currentUser.email;
      role.id = this.currentUser._id;
      role.date = new Date();
      role.rejection_reason = this.rejectReason;
      role.approved = false;
    } else {
      var row: any = {
        role: this.currentUser.isGPM
          ? Group.GPM
          : this.currentUser.isFinanceTeam
          ? Group.Finance
          : Group.Sale_Manager,
        name: this.currentUser.getFullName(),
        email: this.currentUser.email,
        id: this.currentUser._id,
        date: new Date(),
        rejection_reason: this.rejectReason,
        approved: false,
      };
      approvalPhases.push(row);
    }
    order.approval_phases = approvalPhases;
    var receivers = this.orderPhasesInfo.receiverEmails ?? [];
    var params = new HttpParams()
      .set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers))
      .set('order_action', 'reject');
    await this.http
      .put(
        Endpoint.GLOBAL_URL + '' + Endpoint.REJECT_ORDER,
        {
          _id: this.model._id,
          reject_reason: order.reject_reason,
          rejecter_email: order.rejecter_email,
          approval_phases: approvalPhases,
        },
        { params: params }
      )
      .toPromise()
      .then(async (resp: any) => {
        if (
          this.viewOrderEmitter &&
          this.viewOrderEmitter.observers.length > 0
        ) {
          var ord = JSON.parse(JSON.stringify(order));
          ord.approval_phases = resp.approval_phases;
          this.rejectionConfirmationModal.hide();
          this.rejectionReasonModal.hide();
          var ord = JSON.parse(JSON.stringify(order));
          ord.approval_phases = resp.approval_phases;
          ord.user_order = {
            id: this.model.created_by_rfid,
            created_user_email: this.model.user.created_user_email,
            created_user_name: this.model.user.created_user_name,
            manager_user_id: this.model.user.manager_user_id,
          };
          this.viewOrderEmitter.emit(ord);
        } else {
          alert(resp.message ?? '');
          this.rejectionConfirmationModal.hide();
          this.router.navigate(['/orders']);
        }
      });
  }

  calculatePrices(sku) {
    if (this.model.is_gcp) return;
    if (
      Utilities.isNullOrEmpty(sku.value.start_date) ||
      Utilities.isNullOrEmpty(sku.value.end_date)
    ) {
      return;
    }
    //EXPANED
    if (sku) sku.controls['expanded'].setValue(true);
    //
    var from_ = new Date(sku.value.start_date);
    var to_ = new Date(sku.value.end_date);
    var months = Utilities.calculateDiffMonths(from_, to_);
    var days = months * 30;

    var skuObjectId = this.googleProducts.find(
      (x) =>
        sku.value.sku_id &&
        x.sku_id.toLowerCase() == sku.value.sku_id.toLowerCase()
    )?._id;
    var priceObj = this.prices.find(
      (x) =>
        skuObjectId &&
        sku.value.plan_id &&
        x.sku_rfid == skuObjectId &&
        x.plan_rfid == sku.value.plan_id
    );
    if (priceObj) {
      var price = priceObj.price / 30;
      var costMargin = Utilities.setOrderCostMargin(this.model.action);
      var costPrice: number = price * costMargin * days;
      var sellingPrice: number = price * days;

      //OFFERS
      if (
        sku.value.with_google_offer &&
        sku.value.google_offer &&
        sku.value.google_offer != 0
      ) {
        var offer = (100 - sku.value.google_offer) / 100;
        costPrice *= offer;
      }
      if (
        sku.value.with_customer_offer &&
        sku.value.customer_offer &&
        sku.value.customer_offer != 0
      ) {
        var offer = (100 - sku.value.customer_offer) / 100;
        sellingPrice *= offer;
      }
      //LICENSES
      var licencesNbr = sku.value.license;
      if (this.model.action == OrderAction.UPDATE_LICENSE) {
        var planName = this.plans.find((x) => x._id == sku.value.plan_id)?.name;
        var isFlexible = planName?.toLowerCase()?.indexOf('flexible') >= 0;
        if (
          (isFlexible && sku.value.license > sku.value.old_license) ||
          !isFlexible
        )
          licencesNbr = sku.value.license - sku.value.old_license;
      }

      if (licencesNbr > 0) {
        sellingPrice = sellingPrice * licencesNbr;
        costPrice = costPrice * licencesNbr;
        sku.controls['selling_price'].setValue(
          Number.parseFloat(sellingPrice.toFixed(2))
        );
        sku.controls['cost_price'].setValue(
          Number.parseFloat(costPrice.toFixed(2))
        );
      }
    }
  }

  cancelOrder() {
    this.localStorage.save('cancelled_order_date_from', this.date_from);
    this.localStorage.save('cancelled_order_date_to', this.date_to);

    this.location.back();
  }

  viewCustomerServices() {
    // var customer = new DomainModel();
    // customer.id = this.model.customer_id;
    // customer._id = this.model.customer_rfid;
    // customer.domain = this.model.customer_domain;
    // customer.organisation_name = this.model.customer_organisation;
    // customer.contact_person_email = this.model.customer_email;
    // customer.contact_person_name = this.model.customer_contact_name;
    // customer.country_name = this.model.customer_country_name;
    var domain = this.model.domain;
    this.localStorage.save(Unicode.CURRENT_CUSTOMER, JSON.stringify(domain));
    this.router.navigate(['customers/customer-services/subscriptions'], {
      skipLocationChange: false,
    });
  }

  checkOdooInvoice() {
    if (Utilities.isNullOrEmpty(this.orderForm.value?.order_sale_invoice_nbr)) {
      alert('Add Invoice Number');
      return;
    }
    if (Utilities.isNullOrEmpty(this.orderForm.value?.company_section)) {
      alert('Select Company Section');
      return;
    }
    this.odooComparisonModal.show();
  }

  async sendDraftOrderForApproval() {
    await this.saveOrder(true);
  }

  get customer_domain() {
    return this.orderForm.get('customer_domain');
  }
  get customer_email() {
    return this.orderForm.get('customer_email');
  }
  get description() {
    return this.orderForm.get('description');
  }
  get po_id() {
    return this.orderForm.get('po_id');
  }
  get order_sale_invoice_nbr() {
    return this.orderForm.get('order_sale_invoice_nbr');
  }
  get opportunity_source() {
    return this.orderForm.get('opportunity_source');
  }
  get opportunity_nbr() {
    return this.orderForm.get('opportunity_nbr');
  }
  get opportunity_status() {
    return this.orderForm.get('opportunity_status');
  }
  get google_rep() {
    return this.orderForm.get('google_rep');
  }
  get customer_email_notification() {
    return this.orderForm.get('customer_email_notification');
  }
  get action() {
    return this.orderForm.get('action');
  }
  get gcp_credits_coupon() {
    return this.orderForm.get('gcp_credits_coupon');
  }
  get used() {
    return this.orderForm.get('used');
  }
  get project_number() {
    return this.orderForm.get('project_number');
  }
  get project_name() {
    return this.orderForm.get('project_name');
  }
  get billing_name() {
    return this.orderForm.get('billing_name');
  }
  get engagement() {
    return this.orderForm.get('engagement');
  }
  get sku(): UntypedFormArray {
    return this.orderForm.get('sku') as UntypedFormArray;
  }
  get support(): FormGroup {
    return this.orderForm.get('support') as FormGroup;
  }
  get id(): UntypedFormArray {
    return this.orderForm.get('id') as UntypedFormArray;
  }
  get company_section() {
    return this.orderForm.get('company_section');
  }
}
