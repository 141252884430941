import { BaseModel } from '../base.model';
import { DomainModel } from './domain.model';
import { GCPProjectModel } from './gcp_project.model';
import { UserModel } from './user.model';

export class POCModel extends BaseModel {
  _id: string;
  opportunity_rfid: string;
  is_gcp: boolean;
  created_by_rfid: string;
  domain_rfid: string;
  status: string;
  draft: boolean;
  approval_phases: any;
  google_doc_id: string;
  poc_time: string;
  created_at: Date;
  start_date: Date;
  project_rfid:string;
  //CUSTOM
  project:GCPProjectModel;
  user_poc: any;
  domain: DomainModel;
  customer_contact_name: string;
  customer_domain: string;
  customer_organisation: string;
  customer_alternate_email: string;
  managerApprovalPart: any;
  gpmApprovalPart: any;
  sendingToCustomerPart: any;
  googleDocUrl: any;
  created_user_name: string;
  created_date_string: string;
  created_user_img: string;
  consumed_time: string;

  constructor() {
    super();
  }

  getSortedApprovalPhasesByDateDesc(): any[] {
    return this.approval_phases
      ? this.approval_phases.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        })
      : [];
  }

  canApprove(user: UserModel): boolean {
    return (
      this.managerApprovalPart &&
      user._id == this.managerApprovalPart.id &&
      (!this.managerApprovalPart.date || !this.managerApprovalPart.approved)
    );
  }
  canSendToCustomer(user: UserModel): boolean {
    return (
      user.isEngineer &&
      this.managerApprovalPart?.date &&
      this.sendingToCustomerPart &&
      this.sendingToCustomerPart.date == null
    );
  }
}
