import { Component, ViewEncapsulation } from '@angular/core';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { FormGroup } from '@angular/forms';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Utilities } from 'src/shared/utilities';
import { CustomerRequirementsModel } from 'src/models/mongo/customer_requirements.model';

@Component({
  selector: 'lib-customer-requirements-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customer-requirements-list.component.html',
  styleUrls: ['./customer-requirements-list.component.scss'],
})
export class CustomerRequirementsListComponent {
  plans = [];
  currentUser: UserModel;
  customerRequirements: CustomerRequirementsModel[] = [];
  static _http: HttpClient;
  customerSearch: string;
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  static opportunities = [];
  static customerRequirements = [];
  static pagination: number = 1;
  static total: number = 1;
  static gcp: boolean;
  selectedFilterType: string = 'ALL';
  types = ['ALL', 'GWS', 'GCP'];
  customerRfId;
  initial_userIds = [];
  add: boolean = true;
  users = [];
  selectedUserId;
  selectedSales: string;
  CustomerRequirementsForm: FormGroup;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    CustomerRequirementsListComponent._http = http;
    CustomerRequirementsListComponent.gcp = false;
  }

  async ngOnInit(): Promise<void> {
    this.users = await Utilities.usersByGroup(
      Group.ENGINEER,
      this.http,
      this.localStorage
    );
    this.currentUser = this.localStorage.getLoggedinUserModel();
    CustomerRequirementsListComponent.pagination = 1;
    this.getcustomerRequirements(null);
  }

  async getcustomerRequirements(pagination) {
    if (pagination == null)
      pagination = CustomerRequirementsListComponent.pagination;
    var prs = new HttpParams()
      .set(
        Unicode.PAGE,
        (pagination - 1) * CustomerRequirementsListComponent.pageSize + ''
      )
      .set(Unicode.LIMIT, CustomerRequirementsListComponent.pageSize + '')
      .set(Unicode.OPPORTUNITY_TYPE, this.selectedFilterType)
      .set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
    if (!Utilities.isNullOrEmpty(this.customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, this.customerSearch);
    if (!Utilities.isNullOrEmpty(this.selectedSales))
      prs = prs.set(Unicode.SALES_MANAGER, this.selectedSales);
    await CustomerRequirementsListComponent._http
      .get<any>(
        Endpoint.GLOBAL_URL + '' + Endpoint.GET_ALL_CUSTOMER_REQUIREMENTS,
        {
          params: prs,
        }
      )
      .toPromise()
      .then((resp) => {
        if (resp) {
          CustomerRequirementsListComponent.total = <number>resp['total'];
          CustomerRequirementsListComponent.customerRequirements =
            resp.customer_requirements ?? [];
        }
      });
  }

  renderPage(event: number) {
    CustomerRequirementsListComponent.pagination = event;
    this.getcustomerRequirements(null);
  }

  search() {
    CustomerRequirementsListComponent.pagination = 1;
    this.getcustomerRequirements(null);
  }
  selectDate() {
    CustomerRequirementsListComponent.pagination = 1;
    this.getcustomerRequirements(null);
  }
  selectSale() {
    CustomerRequirementsListComponent.pagination = 1;
    this.getcustomerRequirements(null);
  }
  selectStatus() {
    CustomerRequirementsListComponent.pagination = 1;
    this.getcustomerRequirements(null);
  }

  switchType(e) {
    e.target.classList.toggle('toggled');
    CustomerRequirementsListComponent.pagination = 1;
    CustomerRequirementsListComponent.gcp =
      !CustomerRequirementsListComponent.gcp;
    this.getcustomerRequirements(null);
  }

  applyFilter(type: string): void {
    if (type != this.selectedFilterType) {
      CustomerRequirementsListComponent.pagination = 1;
      this.selectedFilterType = type;
      this.getcustomerRequirements(null);
    }
  }

  get GetData() {
    return CustomerRequirementsListComponent.customerRequirements;
  }
  get GetTotalCount() {
    return CustomerRequirementsListComponent.total;
  }
  get GetPagination() {
    return CustomerRequirementsListComponent.pagination;
  }
  get GetPageSize() {
    return CustomerRequirementsListComponent.pageSize;
  }

  refreshPage() {
    this.getcustomerRequirements(null);
  }
  goBack(): void {
    this.location.back();
  }
  viewCustomerRequirement(customerRequirement) {
    this.router.navigate(['customer-requirements/details'], {
      state: { crModel: customerRequirement },
    });
  }

  deleteCustomerRequirement(id: string) {
    if (confirm('Are you sure to delete this customer requirements doc?')) {
      this.http
        .put(
          Endpoint.GLOBAL_URL + '' + Endpoint.DELETE_CUSTOMER_REQUIREMENTS + id,
          {}
        )
        .subscribe((response: any) => {
          this.getcustomerRequirements(null);
          alert(response.msg);
        });
    }
  }
}
